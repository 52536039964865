<template>
  <section class="header w-full safe-area-x">
    <menu-component
      menu-type="tournaments"
      :title="title"
      :menu-items="menu"
      :sub-menu="subMenu"
      :sub-menu-active="true"
    >
      <template v-if="isTournamentDetail">
        <div class="flex gap-4">
          <tournament-box
            class="tournament-box"
            :resources="[{ value: mulligans.value, icon: MULLIGANS }]"
          />
          <app-button
            v-if="isTournamentDiscipline2"
            class="rules-button"
            :btn-text="$t('tournaments.rules')"
            btn-type="primary"
            btn-size="sm"
            @click="showRulesPopup = true"
          />
        </div>
      </template>
    </menu-component>
    <popup-window
      v-if="showRulesPopup"
      popup-type="info"
      :popup-title="$t('tournaments.ibuECup')"
      @close="showRulesPopup = false"
    >
      <div class="special-tournament-rules">
        <ul class="special-tournament-rules-list">
          <li
            v-for="rule in specialTournamentRules"
            :key="rule"
            class="text-30 text-texts-standard-default text-left"
          >
            {{
              $replacePlaceholder(
                $replacePlaceholder(
                  $replacePlaceholder($t(`tournaments.${rule}`), '{seriesname}', seriesName),
                  '{number}',
                  24,
                ),
                '{partner}',
                'IBU', // TODO lokalizacny,
              )
            }}
          </li>
        </ul>
      </div>
    </popup-window>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import {
  MULLIGANS,
  MECHANIC_TOURNAMENTS_DISCIPLINE_2,
  MECHANIC_TOURNAMENTS,
} from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import TournamentBox from '@/components/Tournaments/TournamentBox.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'

import type { MenuObject } from '@/interfaces/MenuObject'

const CAREER_TASK_UNLOCK = 25

interface ComponentData {
  showRulesPopup: boolean
  MULLIGANS: typeof MULLIGANS
  specialTournamentRules: string[]
}

export default defineComponent({
  name: 'TournamentsHeader',
  components: {
    TournamentBox,
    PopupWindow,
  },
  data(): ComponentData {
    return {
      showRulesPopup: false,
      MULLIGANS,
      specialTournamentRules: [
        'specialRule1',
        'specialRule2',
        'specialRule3',
        'specialRule4',
        'attributesInfo1',
        'specialRule5',
        'specialRule6',
        'specialRule7',
      ],
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
      hasMechanic: 'hasMechanic',
      mulligans: 'getMulligans',
    }),
    title(): string {
      if (this.$route.name.toString().replace(/Web$/, '') === 'TournamentsDetail')
        return this.$t('tournaments.tournament')

      return this.$t('tournaments.tournaments')
    },
    currentMenuKey(): number {
      switch (this.$route.name.toString().replace(/Web$/, '')) {
        case 'TournamentsCalendar':
        case 'TournamentsHistory':
          return 1
        case 'TournamentsMilestones':
          return 2
        case 'TournamentsRankingsPoints':
        case 'TournamentsRankingsTitles':
        case 'TournamentsRankingsDiscipline2':
          return 3
        default:
          return 0
      }
    },
    isTournamentDetail(): boolean {
      return this.$route.name.toString().replace(/Web$/, '').startsWith('TournamentsDetail')
    },
    seriesName(): string {
      return this.$t('tournaments.ibuECup')
    },
    menu(): Record<number, MenuObject> {
      if (this.isTournamentDetail) return null

      return {
        1: {
          text: this.$t('tournaments.tournaments'),
          route: this.$getWebView('TournamentsCalendar'),
          current: this.currentMenuKey === 1 && this.$route.name.toString(),
          notification:
            (this.notifications?.tournaments?.daily_join_count ?? 0) +
            (this.notifications?.tournaments?.ibu_tab_notification ?? 0),
        },
        2: {
          text: this.$t('tournaments.milestones'),
          route: this.$getWebView('TournamentsMilestones'),
          notification: this.notifications?.tournaments?.unclaimed_milestones ?? 0,
          isLocked: this.isTournamentDisciplineWithoutTournament,
          isDisabled: this.isTournamentDisciplineWithoutTournament,
          disabledText: this.isTournamentDisciplineWithoutTournament
            ? this.$replacePlaceholder(
                this.$t('tournaments.fullAcccessTooltip'),
                '%s',
                CAREER_TASK_UNLOCK.toString(),
              )
            : undefined,
        },
        3: {
          text: this.$t('tournaments.rankings'),
          route: this.isTournamentDisciplineWithoutTournament
            ? this.$getWebView('TournamentsRankingsDiscipline2')
            : this.$getWebView('TournamentsRankingsPoints'),
          current: this.currentMenuKey === 3 && this.$route.name.toString(),
        },
      }
    },
    subMenu(): Record<number, MenuObject> {
      switch (this.currentMenuKey) {
        case 1:
          return {
            1: {
              text: this.$t('tournaments.calendar'),
              route: this.$getWebView('TournamentsCalendar'),
              notification: this.notifications?.tournaments?.daily_join_count ?? 0,
            },
            2: {
              text: this.$t('tournaments.myTournaments'),
              route: this.$getWebView('TournamentsHistory'),
            },
            ...(this.isTournamentDiscipline2
              ? {
                  3: {
                    text: this.$t('tournaments.ibuECup'),
                    route: this.$getWebView('TournamentsDetailDiscipline2'),
                    notification: this.notifications?.tournaments?.ibu_tab_notification ?? 0,
                  },
                }
              : null),
          }
        case 3:
          return {
            1: {
              text: this.$t('tournaments.points'),
              route: this.$getWebView('TournamentsRankingsPoints'),
              isLocked: this.isTournamentDisciplineWithoutTournament,
              isDisabled: this.isTournamentDisciplineWithoutTournament,
              disabledText: this.isTournamentDisciplineWithoutTournament
                ? this.$replacePlaceholder(
                    this.$t('tournaments.fullAcccessTooltip'),
                    '%s',
                    CAREER_TASK_UNLOCK.toString(),
                  )
                : undefined,
            },
            2: {
              text: this.$t('tournaments.titles'),
              route: this.$getWebView('TournamentsRankingsTitles'),
              isLocked: this.isTournamentDisciplineWithoutTournament,
              isDisabled: this.isTournamentDisciplineWithoutTournament,
              disabledText: this.isTournamentDisciplineWithoutTournament
                ? this.$replacePlaceholder(
                    this.$t('tournaments.fullAcccessTooltip'),
                    '%s',
                    CAREER_TASK_UNLOCK.toString(),
                  )
                : undefined,
            },
            ...(this.isTournamentDiscipline2
              ? {
                  3: {
                    text: this.$t('tournaments.ibuECup'),
                    route: this.$getWebView('TournamentsRankingsDiscipline2'),
                  },
                }
              : null),
          }
        default:
          return null
      }
    },
    isTournamentDiscipline2(): boolean {
      return this.$isWsm && this.hasMechanic(MECHANIC_TOURNAMENTS_DISCIPLINE_2)
    },
    isTournamentDisciplineWithoutTournament(): boolean {
      return this.isTournamentDiscipline2 && !this.hasMechanic(MECHANIC_TOURNAMENTS)
    },
  },
})
</script>

<style lang="scss" scoped>
.header {
  @include background(var(--event-header-background), cover);

  @if $isWsm {
    background-color: #143157;
  }

  @if $isSsm {
    background-color: rgba(45, 56, 83, 0.6);
  }

  :deep() {
    .menu-wrapper {
      @apply mt-0;
    }

    .item-menu .icon-lock-sm.revert-skew {
      transform: none;
    }

    .rules-button {
      width: 18.75rem;
      height: 4.125rem;
    }
  }
}

.special-tournament-rules {
  width: 76.125rem;
  height: 30.5rem;
  padding: 2.25rem 2.125rem;
  overflow-y: auto;

  &-list {
    padding-left: 3rem;
    padding-right: 2rem;
    list-style: square;
  }
}
</style>
