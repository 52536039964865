import { isSsm } from '@/plugins'
import { GameVersion } from '@/enums/GameVersion'

// Attributes constants
export const PROJECT_NAME = isSsm ? 'Summer Sports Mania' : 'Winter Sports Mania'

export const YOUTUBE_ID = isSsm ? 'qyPXtPipG68' : '5UbiV33wTr0'

export const RESEARCH = 'RESEARCH'
export const CATEGORY_BASE = 'BASE'
export const POWER = 'POWER'
export const CATEGORY_BENEFIT = 'benefit'
export const CATEGORY_EQUIPMENT = 'EQUIPMENT'
export const CATEGORY_EQUIPMENT_UPGRADE = 'EQUIPMENT_UPGRADE'
export const CATEGORY_EQUIPMENT_PREMIUM_UPGRADE = 'EQUIPMENT_PREMIUM_UPGRADE'
export const CATEGORY_RESEARCH_BASE = 'RESEARCH_BASE'
export const CATEGORY_RESEARCH_PREMIUM = 'RESEARCH_PREMIUM'
export const CATEGORY_RESEARCH_REPEATABLE = 'RESEARCH_REPEATABLE'
export const CATEGORY_SEASONAL = 'SEASONAL'
export const MAX_ATTRIBUTE_VALUE = 800

// Game constants
export const RANKING_POINTS = 'RANKING_POINTS'
export const GEMS = 'gems'
export const MONEY = 'money'
export const ENERGY = 'energy'
export const STARTS = 'starts'
export const TRAINING_POINTS = 'training_points'
export const UNIVERSAL_TRAINING_POINTS = 'UNIVERSAL_TRAINING_POINTS'
export const PRESTIGE = 'prestige'
export const RESEARCH_POINTS = 'research_points'
export const GALLERY_PIECES = 'gallery_pieces'
export const BENEFITS = 'benefits'
export const EXPERIENCE = 'EXPERIENCE'
export const REAL_CURRENCY = 'realCurrency'
export const UPDATE_PARAMETER_GEMS = 'updateParameterGems'
export const UPDATE_PARAMETER_MONEY = 'updateParameterMoney'
export const UPDATE_PARAMETER_ENERGY = 'updateParameterEnergy'
export const UPDATE_PARAMETER_STARTS = 'updateParameterStarts'
export const UPDATE_PARAMETER_TRAINING_POINTS = 'updateParameterTrainingPoints'
export const UPDATE_PARAMETER_UNIVERSAL_TRAINING_POINTS = 'updateParameterUNIVERSALTRAININGPOINTS'
export const BEST_SCORE = 'BEST_SCORE'
export const WILD_CARD = 'wild_card'
export const ENERGY_DRINK = 'energy_drink'
export const TROPHY_BRONZE = 'trophyBronze'
export const TROPHY_SILVER = 'trophySilver'
export const TROPHY_GOLD = 'trophyGold'
export const CREDIT_OPERATION = 'credit'
export const DEBIT_OPERATION = 'debit'
export const ROLE = 'role'
export const ARENA_DISCIPLINE_ID = 'ARENA_DISCIPLINE_ID'
export const QUEST_COMPLETED = 'questCompleted'
export const ADMIN_FORWARD = 'FORWARD'
export const CHAMPIONSHIP_POINTS = 'championship_points'
export const TOURNAMENT_TITLES = 'tournament-titles'
export const ARENA = 'arena'
export const LEGENDS_BUILDING = 'legends'
export const CLUB_BUILDING = 'club'
export const TRAINING_HALL = 'training_hall'
export const PREMIUM = 'premium'
export const CLUBHOUSE = 'clubHouse'
export const CLUBARENA = 'clubArena'
export const TRAINING = 'training'
export const DIRECTORATE = 'directorate'
export const FITNESS = 'fitness'
export const HOTEL = 'hotel'
export const MEDIA = 'media'
export const MEDICAL = 'medical'
export const PARKING = 'parking'
export const RELAX = 'relax'
export const RESTAURANT = 'restaurant'
export const SWIMMING = 'swimming'
export const WELLNESS = 'wellness'
export const CLUB_CHAMPIONSHIP = 'club_championship'
export const WON_MATCHES = 'won-matches'
export const EVENT_POINTS = 'event_points'
export const EVENT_REPUTATION = 'reputation'
export const EVENT_EARLY_ACCESS = 'early_access'
export const EVENT_CLAIMABLE_MILESTONES = 'claimable_milestones'
export const UNLOCKED = 'unlocked'
export const LOCKED = 'locked'
export const SEASONAL_ATTRIBUTES = 'seasonal_attributes'
export const WORLD_RANKING = 'world_ranking'
export const MULTIPLICATOR_OFF_STATE = 'multiplicator_off'
export const STARS = 'stars'
export const DISCIPLINE = 'discipline'
export const TOURNAMENT_POINTS = 'tournament_points'
export const MULLIGANS = 'mulligans'
export const TOURNAMENT_POINTS_DISCIPLINE_2 = 'ibu_points'

// Tournaments
export const TOURNAMENTS_HISTORY_LIMIT = 7 // days
export const TOURNAMENT_SIGNAL_EVENT = 'tournaments-signal'
export const TOURNAMENT_START = 'tournament_start'
export const TOURNAMENT_END = 'tournament_end'

// Benefit types & base constants
export const CASH = 'cash'
export const ATTRIBUTE = 'attribute'

export const STARTS_NUMBER_BASE = 1
export const STARTS_NUMBER_SUPERMATCH = 5
export const TRAINING_BENEFIT_USAGE = 1

export const STARTS_LIVE_INCREASE_LEVELS = [15, 50]
export const STARTS_LIVE_COST_LEVELS = [
  {
    level: '1-14',
    cost: '2',
  },
  {
    level: '15-49',
    cost: '3',
  },
  {
    level: '50+',
    cost: '4',
  },
]

// Event
export const EVENT_STARTS_NUMBER_BOSSFIGHT = 3
export const EVENT_BENEFIT_USAGE = 3
export const COMMODITY_POINTS = 'commodity_points'

// Stats
export const LEVEL = 'level'
export const DURABILITY = 'durability'
export const ADMIN_ADD_XP = '+XP'
export const ADMIN_ADD_LVL = '+LVL'

// Grand Prize constants
export const ALL_GRAND_PRIZES = 'all'
export const GRAND_PRIZE_OPENED = 'opened'
export const GRAND_PRIZE_UNOPENED = 'unopened'
export const GRAND_PRIZE_UNCLAIMED = 'unclaimed'
export const GRAND_PRIZE_CLAIMED = 'claimed'
export const GRAND_PRIZE_REWARD_OPENED = 'opened'
export const GRAND_PRIZE_REWARD_UNOPENED = 'unopened'
export const GRAND_PRIZE_REWARD_CLAIMED = 'claimed'
export const GRAND_PRIZE = 'Grand Prize'
export const GRAND_PRIZE_BASIC = 'basic'
export const GRAND_PRIZE_BRONZE = 'bronze'
export const GRAND_PRIZE_SILVER = 'silver'
export const GRAND_PRIZE_GOLD = 'gold'
export const GRAND_PRIZE_DIAMOND = 'diamond'
export const GRAND_PRIZE_RARITY = {
  1: GRAND_PRIZE_BASIC,
  2: GRAND_PRIZE_BRONZE,
  3: GRAND_PRIZE_SILVER,
  4: GRAND_PRIZE_GOLD,
  5: GRAND_PRIZE_DIAMOND,
}
export const GRAND_PRIZE_NAME = 'grand_prize_name'

// TODO: check if this is needed
export const REWARD_RARITY = {
  basic: GRAND_PRIZE_BASIC,
  bronze: GRAND_PRIZE_BRONZE,
  silver: GRAND_PRIZE_SILVER,
  gold: GRAND_PRIZE_GOLD,
  diamond: GRAND_PRIZE_DIAMOND,
}
// disciplines names
// TODO BE:EP prerobit na enum: name<>id
// game-module/src/Database/seeders/SummerSportsMania/DisciplinesSeederSSM.php
export const DISCIPLINE_ID = {
  downhill: 1,
  biathlon: 2,
  skiJump: 3,
  bobsleigh: 4,
  speedSkating: 5,
  giantSlalom: 6,
  luge: 7,
  xCountrySkiing: 8,
  sbx: 9,
  shortTrack: 10,
  sprint100Meters: 11,
  archery: 12,
  trap: 13,
  keirin: 14,
  kayak: 15,
  javelinThrow: 16,
}

// Shop constants
export const EQUIPMENT_DELIVERY_TIME = 'equipment_delivery_time'
export const EQUIPMENT_PRICE_CASH = 'equipment_price_cash'
export const EQUIPMENT_PRICE_RP = 'equipment_price_rp'
export const EQUIPMENT_DURABILITY = 'equipment_durability'
export const EQUIPMENT_ATTRIBUTE_BONUS = 'equipment_attribute_bonus'
export const EQUIPMENT_TP_BONUS = 'equipment_tp_bonus'
export const EQUIPMENT_CASH_BONUS = 'equipment_cash_bonus'
export const EQUIPMENT_REPAIR_CASH = 'equipment_repair_cash'
export const EQUIPMENT_REPAIR_TIME = 'equipment_repair_time'
export const EQUIPMENT_LEVEL_NAME = 'equipment_level_name'
export const EQUIPPED = 'equipped'
export const NOT_EQUIPPED = 'not_equipped'
export const OPERATION_UPGRADE = 'upgrade'
export const OPERATION_REPAIR = 'repair'
export const OPERATION_END = 'operation_end'
export const OPERATION_DELIVERY = 'equipment_delivery'
export const OPERATION_TYPE = 'operation_type'
export const UPGRADE_PRICE_CASH = 'upgrade_price_cash'
export const UPGRADE_PRICE_GEMS = 'upgrade_price_gems'
export const UPGRADE_PRICE_RP = 'upgrade_price_rp'
export const UPGRADE_TIME = 'upgrade_time'
export const UPGRADE_BONUS = 'upgrade_bonus'
export const SPEEDUP_PRICE = 'speedup_price'
export const EQUIPMENT = 'equipment'
export const EQUIPMENT_EQUIPPED = 'equipped'
export const EQUIPMENT_LOCKED = 'locked'
export const EQUIPMENT_UNLOCKED = 'unlocked'
export const EQUIPMENT_PURCHASED = 'purchased'
export const EQUIPMENT_BROKEN = 'broken'
export const EQUIPMENT_FUNCTIONAL = 'functional'
export const ITEM_OPPONENTS_GENERATION = 'opponents_generation'
export const EQUIPMENT_DELIVERED = 'equipmentDelivered'
export const EQUIPMENT_REPAIRED = 'equipmentRepaired'
export const EQUIPMENT_UPGRADED = 'equipmentUpgraded'
export const EQUIPMENT_DAMAGED = 'equipmentDamaged'
export const EVENT_PROGRESS_DATA = 'eventTaskchain'
export const EVENT_MILESTONES = 'eventMilestone'
export const EVENT_BOUNTY_BOARD_PROGRESS = 'eventTaskBountyBoard'

export const GAME_PASS_MILESTONES = 'game_pass_milestone'
export const PIGGY_BANK_MILESTONES = 'piggy_bank_milestone'
export const TOURNAMENTS_MILESTONES = 'tournaments_milestone'

export const INDIVIDUAL_MILESTONE_REACHED = 'eventIndividualMilestoneReached'
export const WILD_CARD_SHOP_ITEM_NAME = 'wild_card'
export const ENERGY_DRINK_SHOP_ITEM_NAME = 'energy_drink'
export const GDD_ID = 'gdd_id'
export const EQUIPMENT_LEVELS_COUNT = 5
export const MINIMAL_PREMIUM_EQUIPMENT_LEVEL = 6

// Mechanic constants
export const MECHANICS = 'mechanics'
export const MECHANIC_MAP_ITEMS = 'map_items'
export const MECHANIC_EQUIPMENT = 'equipment_shop'
export const MECHANIC_CLUB = 'club'
export const MECHANIC_PERSONAL_TRAINER = 'personal_trainer'
export const MECHANIC_DAILY_QUESTS = 'daily_quests'
export const MECHANIC_SUPERMATCH = 'supermatch'
export const MECHANIC_SUPERTRAINING = 'supertraining'
export const MECHANIC_RESEARCH = 'research'
export const MECHANIC_BENEFIT = 'benefit'
export const MECHANIC_CLUB_CHAMPIONSHIP = 'club_championship'
export const MECHANIC_STAR_OF_THE_DAY = 'star_of_the_day'
export const MECHANIC_TOURNAMENTS = 'tournaments'
export const MECHANIC_GALLERY = 'gallery'
export const MECHANIC_REFRESH_OPPONENTS = 'refresh_opponents'
export const MECHANIC_CAREER = 'career'
export const MECHANIC_EVENT = 'event'
export const MECHANIC_DISCIPLINE_EVENT = 'discipline_event'
export const MECHANIC_BOARD_GAMES_EVENT = 'board_games_event'
export const MECHANIC_PIGGY_BANK = 'piggy_bank'
export const MECHANIC_BAGS = 'bags'
export const MECHANIC_GAME_PASS = 'game_pass'
export const MECHANIC_TOURNAMENTS_DISCIPLINE_2 = 'tournament_biathlon'

// Map
export const FOCUS = 'focus'
export const NARRATIVE = 'narrative'
export const CLIENT_INPUT = 'clientInput'
export const MAP_TRAINING_HALL = 'training_hall'
export const MAP_ARENA = 'arena'
export const SHOP = 'shopName'
export const CAREER = 'career'
export const LAB = 'laboratory'
export const CLUB = 'club'
export const TOURNAMENTS = 'tournaments'
export const STAR_OF_THE_DAY = 'star_of_the_day'
export const STADIUM = 'stadiumName'
export const PHASER_SCENE_ROUTES = ['/', '/career', '/clubs'] // add all routes that have a phaser map scene

// Untranslatable words
export const UNTRANSLATABLE_WORDS = {
  '{grandprize}': 'Grand Prize',
  '{eventpass}': 'Event Pass',
  '{championpass}': 'Champion Pass',
}

// Environment constants
export const DEV = 'dev'
export const QA = 'qa'
export const UAT = 'uat'
export const PROD = 'prod'

// Benefits
export const BENEFIT_ATTRIBUTE_BRONZE = 'benefitAttributeBronze'
export const BENEFIT_ATTRIBUTE_SILVER = 'benefitAttributeSilver'
export const BENEFIT_ATTRIBUTE_GOLD = 'benefitAttributeGold'
export const BENEFIT_CASH_BRONZE = 'benefitCashBronze'
export const BENEFIT_CASH_SILVER = 'benefitCashSilver'
export const BENEFIT_CASH_GOLD = 'benefitCashGold'
export const BENEFIT_TRAINING_POINTS_BRONZE = 'benefitTrainingPointsBronze'
export const BENEFIT_TRAINING_POINTS_SILVER = 'benefitTrainingPointsSilver'
export const BENEFIT_TRAINING_POINTS_GOLD = 'benefitTrainingPointsGold'
export const BENEFIT_POWERBOOST_BRONZE = 'powerboost-attribute_bronze'
export const BENEFIT_POWERBOOST_SILVER = 'powerboost-attribute_silver'
export const BENEFIT_POWERBOOST_GOLD = 'powerboost-attribute_gold'
export const BENEFIT_PERSONAL_BRONZE = 'personal-cash_bronze'
export const BENEFIT_PERSONAL_SILVER = 'personal-cash_silver'
export const BENEFIT_PERSONAL_GOLD = 'personal-cash_gold'
export const BENEFIT_PROTRAINING_BRONZE = 'protraining-training_points_bronze'
export const BENEFIT_PROTRAINING_SILVER = 'protraining-training_points_silver'
export const BENEFIT_PROTRAINING_GOLD = 'protraining-training_points_gold'

// Benefits slots
export const BENEFITS_CLUB_CHAMPIONSHIP = 'benefit_club_championship'
export const BENEFITS_ARENA = 'benefit_arena'
export const BENEFITS_TOURNAMENTS = 'benefit_tournaments'
export const BENEFITS_STAR_OF_THE_DAY = 'benefit_star_of_the_day'
export const BENEFITS_TRAINING_HALL = 'benefit_training_hall'
export const BENEFIT_CAREER = 'benefit_career'
export const BENEFITS_EVENT = 'benefit_event'

// STAR OF THE DAY
export const IN_PROGRESS = 'in_progress'
export const DAILY_LEAGUE_REMATCH = 'dailyLeagueRematch'
export const STAR_OF_THE_DAY_STANDINGS = 'standings'
export const STAR_OF_THE_DAY_ATHLETE = 'athlete'

// CONFIGS
export const DAILY_LEAGUE_CONFIG = 'daily_league_config'
export const DUEL_CONFIG = 'duel_config'
export const TRAINING_CONFIG = 'task_training_config'
export const TRAINER_CONFIG = 'task_training_trainer_config'
export const BOSS_FIGHT_CONFIG = 'boss_fight_config'
export const CLUB_DUEL_CONFIG = 'club_duel_config'
export const CLUB_DUEL = 'club_duel'
export const TOURNAMENTS_CONFIG = 'tournament_config'

// MAIL
export const FORWARD = 'forward'
export const SEND = 'send'
export const REPLY = 'reply'

// PROFILE - USER NAME
export const MIN_USERNAME_LENGTH = 3
export const MAX_USERNAME_LENGTH = 25

// TRAINING
export const DISCIPLINE_TRAINED = 'trained'

// USER
export const ADMIN = 'Admin'
export const SUPER_ADMIN = 'Super Admin'

// CLUBS
export const CLUB_TYPES = {
  open: 'open',
  request: 'request',
  closed: 'closed',
}
export const MINIMUM_CLUB_MEMBERS_TO_UNLOCK = 5
export const CLUB_EMPLOYEE = 'club'
export const PERSONAL_EMPLOYEE = 'personal'
export const CLUB_MANAGER = 'manager'
export const CLUB_ASSISTANT = 'assistant'
export const CLUB_MEMBER = 'member'
export const CLUB_MEMBER_NOTIFICATION_UPDATED_ROLE = 'updated_role'
export const CLUB_ACCEPTED_INVITATION = 'accepted_invitation'
export const CLUB_LEVELS = {
  _5: 5,
  _10: 10,
  _15: 15,
  _20: 20,
  _25: 25,
  _30: 30,
  _35: 35,
  _40: 40,
  _45: 45,
  _50: 50,
  _55: 55,
  _60: 60,
  _65: 65,
  _70: 70,
  _75: 75,
  _80: 80,
  _85: 85,
  _90: 90,
  _95: 95,
  _100: 100,
}
export const CLUB_LEVELS_PLAYERS = {
  _10: '1 - 10',
  _20: '11 - 20',
  _30: '21 - 30',
  _40: '31 - 40',
  _50: '41 - 50',
  _60: '51 - 60',
  _70: '61 - 70',
  _80: '71 - 80',
  _90: '81 - 90',
  _100: '91 - 100',
}
export const CLUB_MEMBERS_UPDATE_TIME = 60
export const REJON_CLUB_HOURS = 24

// ORDERBY CONSTANTS
export const ORDER_TYPES = {
  asc: 'asc',
  desc: 'desc',
  default: 'default',
}
export const ORDER_NAMES = {
  name: 'name',
  username: 'username',
  onlineStatus: 'online_status',
  membersCount: 'members_count',
  prestige: 'prestige',
  freeSpaces: 'free_spaces',
  country: 'country',
  clubType: 'club_type',
  level: 'level',
  rankingPoints: 'ranking_points',
  championshipPoints: 'championship_points',
  tournamentTitles: 'tournament_titles',
  createdAt: 'created_at',
  type: 'type',
}

// POPUP WINDOW TYPES
export const INFO_TYPE = 'info'
export const CONFIRM_TYPE = 'confirm'
export const SUBMIT_TYPE = 'submit'
export const ERROR_TYPE = 'error'

export const CLUB_BUILDINGS = {
  all: 0,
}

export const CLUB_CHAMPIONSHIP_LEAGUES = {
  legendary: 'legendaryLeague',
  master: 'masterLeague',
  elite: 'eliteLeague',
  professional: 'professionalLeague',
  amateur: 'amateurLeague',
  rookie: 'rookieLeague',
}

export const CLUB_CHAMPIONSHIP_DIVISIONS = {
  _1: 1,
  _2: 2,
  _4: 4,
  _8: 8,
  _16: 16,
  _32: 32,
}

export const CLUB_CHAMPIONSHIP_STATES = {
  draw: 'draw',
  running: 'running',
  evaluating: 'evaluating',
}
export const ALL = 'all'

// WEEKLY DEALS URLS FOR RIGHTBOX
export const WEEKLY_URLS = {
  free_rewards: 'PremiumAds',
  weekly_deals_tab: 'PremiumWeeklyDeals',
}

// REPORT TABLE COLUMNS
export const REPORT_TABLE_COLUMNS = {
  POSITION: 'position',
  NAME: 'name',
  RANKING_POINTS: 'ranking_points',
  POWER: 'power',
  RESULT: 'result',
}
export const LIVE = 'live'
export const MINIGAME = 'minigame'
export const MINIGAME_ID = 'minigameId'

export const MULTIPLIER = 'multiplier'
export enum Multiplier {
  Training = 'task_training',
  Arena = 'duel',
  ClubChampionship = 'club_duel',
  AutumnFair = 'autumn',
}

export const GAME_VERSION_PREFIX = isSsm ? GameVersion.SSM : GameVersion.WSM

export const REFRESHING_BUNDLE_PACKS = {
  refreshingBundle3: `${GAME_VERSION_PREFIX}_refreshing_bundle_3`,
  refreshingBundle6: `${GAME_VERSION_PREFIX}_refreshing_bundle_6`,
  refreshingBundle9: `${GAME_VERSION_PREFIX}_refreshing_bundle_9`,
}

// CLUB CHAMPIONSHIPS
export const ADVANCES = 'advances'
export const DESCENTS = 'descents'
export const STAYS = 'stays'

// DAILY TASKS
export const ASSIGNED = 'assigned'
export const UNASSIGNED = 'unassigned'

export const CLAIMED = 'claimed'

// SEASONS
export const TOP_PLAYERS = 1000
export const ARENA_LEVEL = 2
export const MIN_REDUCED_POINTS = 100
export const PREVIOUS_SEASON = 'previous'
export const CURRENT_SEASON = 'current'

// LOCAL STORAGE
export const TASK_TRACKER_LOCAL_STORAGE = 'taskTracker'
export const REPORT_MATCH_ID = 'reportMatchId'
export const REPORT_MATCH_TIME = 'reportMatchTime'
export const CONVERTOR_CONFIRMATION_BLOCK = 'convertorConfirmationBlock'

// COOKIES
export const COOKIE_USER_STATE = 'userState'
export const COOKIE_USER_STATE_VISITED = 'visited'

// GAME STATE
export const GAME_LIVE = 'live'
export const GAME_MAINTENANCE = 'maintenance'
export const GAME_UPDATE = 'updating'

// RESOLUTION
export const IS_MOBILE_BREAKPOINT = window.innerWidth <= 1000
export const IS_TABLET_BREAKPOINT = window.innerWidth > 1000 && window.innerWidth < 1370
export const IS_2K_BREAKPOINT = window.innerWidth > 2400 && window.innerWidth < 3200
export const IS_4K_BREAKPOINT = window.innerWidth >= 3200

// ZOOM
export const DESKTOP_INITIAL_ZOOM_LEVEL = IS_2K_BREAKPOINT ? 1 : IS_4K_BREAKPOINT ? 1.5 : 0.8
export const MOBILE_INITIAL_ZOOM_LEVEL = IS_MOBILE_BREAKPOINT
  ? 0.4
  : IS_TABLET_BREAKPOINT
    ? 0.7
    : 0.6

// AVATARS
export const animatedAvatars = ['104']

// OFFERS
export const OFFER_STATES = {
  available: 'available',
  active: 'active',
}
export const REFRESHING_BUNDLE = 'refreshing_bundle'
export const ROOKIE_PASS = 'wsm_starter_pass'
export const SEASON_PASS = 'wsm_season_pass_1'
export const EVENT_PASS = 'cc_event_pass'
export const OFFER_BONUSES = ['money_bonus', 'training_points_bonus']
export const LEGENDARY_CHAIN = 'legendary_chain_offer'
export const LEVEL_BAGS = 'levels_bags_offer'
export const GAME_PASS = 'game_pass_offer'
export const PIGGY_BANK = 'piggy_bank_offer'
export const TOURNAMENT_PASS = 'tournaments_pass'
export const SPECIAL_TOURNAMENTS = 'special_tournaments'

// PROGRESS MESSAGES
export const PROGRESS_MESSAGE_DURATION = 3000

// ADS
export const VIDEO_ADS_FREE_GEMS = 'video_ads_free_gems'
export const VIDEO_ADS_FREE_ENERGY = 'video_ads_free_energy'
export const VIDEO_ADS_FREE_STARTS = 'video_ads_free_starts'
export const VIDEO_ADS_FREE_MONEY = 'video_ads_free_money'
export const VIDEO_AD_DURATION = 6000

// SPECIAL REWARDS
export const PREMIUM_EQUIPMENT_ITEM = 'premium_equipment_item'
export const AVATAR_ITEM = 'avatar_static_background'
export const AVATAR = 'avatar'
export const AVATARS = 'avatars'
export const AVATAR_BG = 'avatar_background'
export const AVATAR_BGS = 'avatar_bgs'
export const REWARD_AVATAR_TYPES = [AVATAR_ITEM, AVATAR, AVATARS, AVATAR_BG, AVATAR_BGS]

// SHRINK/RESIZE TEXT
export const FONT_SIZES = [24, 26, 28, 30, 32, 34, 36, 38, 40, 44, 48, 50, 60, 72, 90, 110]
export const ICON_SIZES = [32, 48, 56, 72, 80, 120, 184]

// TIME CONTANTS
export const ONE_HOUR_IN_SECONDS = 3600

// DOCUMENT EVENT NAMES
export const EVENT_CREATE_EVENT_BUILDING = 'createEventBuilding'

// GAME PASS EFFECTS

export const GAME_PASS_STARTS_CAP = 'gp_start_cap'
export const GAME_PASS_ENERGY_CAP = 'gp_energy_cap'
export const GAME_PASS_DOUBLE_START_REGEN = 'gp_double_start_regen'
export const GAME_PASS_ARENA_MULTIPLIER = 'gp_arena_multiplier'
export const GAME_PASS_TRAINING_MULTIPLIER = 'gp_training_multiplier'
export const GAME_PASS_DOUBLE_ENERGY_REGEN = 'gp_double_energy_regen'
export const GAME_PASS_RESEARCH_SPEED_UP = 'gp_research_speed_up'
export const GAME_PASS_INSTANT_EQUIPMENT_REPAIR = 'gp_instant_equipment_repair'
export const GAME_PASS_SKIP_REWARDED_ADS = 'gp_skip_rewarded_ads'
export const GAME_PASS_DAILY_TASK_SLOT = 'gp_daily_task_slot'
