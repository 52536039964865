<template>
  <div class="popup-template bordered-1">
    <popup-header :title-text="$t('tournaments.ibuECup')" @close="$emit('close')" />
    <div class="popup-template-box-content">
      <div class="early-access-popup">
        <div class="early-access-popup-content flex items-end">
          <div class="early-access-popup-info bordered-sm">
            <app-icon class="ml-1 info-icon icon-in-table" icon-name="info-rounded" />
            <section class="text-texts-standard-default text-24 text-left pl-8">
              <p>
                {{ $t('tournaments.ibuInfo1').replace('{seriesname}', $t('tournaments.ibuECup')) }}
              </p>
              <p>
                {{
                  $t('tournaments.ibuInfo2').replace(
                    '{time}',
                    formatResult(tournamentsConfig?.target_result, DISCIPLINE_ID.biathlon),
                  )
                }}
              </p>
              <p>{{ $t('tournaments.ibuInfo3') }}</p>
            </section>
          </div>
        </div>
        <div class="early-access-popup-footer pt-4 flex justify-end" :class="{ 'pr-3': $isWsm }">
          <app-button
            btn-id="early-access-popup"
            btn-type="secondary"
            :btn-text="$t('arena.header')"
            btn-size="md"
            @click="redirectToArena"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PopupHeader from '@/components/Popup/PopupHeader.vue'
import { formatResult } from '@/helpers'
import { DISCIPLINE_ID, gamesConfigEndpoint } from '@/globalVariables'

interface ComponentData {
  DISCIPLINE_ID: typeof DISCIPLINE_ID
  tournamentsConfig: SpecialTournamentsConfigResponse
}

interface SpecialTournamentsConfigResponse {
  name: string
  target_result: number
  mechanic: string
  is_active: boolean
}

export default defineComponent({
  name: 'EarlyAccessPopup',
  components: {
    PopupHeader,
  },
  emits: ['close'],
  data(): ComponentData {
    return {
      DISCIPLINE_ID,
      tournamentsConfig: null,
    }
  },
  async created(): Promise<void> {
    await this.loadConfig()
  },
  methods: {
    formatResult,
    redirectToArena(): void {
      this.$emit('close')
      this.$router.push({ name: this.$getWebView('ArenaView') })
    },
    async loadConfig(): Promise<void> {
      const tournamentsConfig = await this.$axios.post<
        {},
        SpecialTournamentsConfigResponse,
        { keys: string }
      >(gamesConfigEndpoint, {
        keys: 'special_tournaments_biathlon',
      })

      this.tournamentsConfig = tournamentsConfig
    },
  },
})
</script>
<style lang="scss" scoped>
@import '@/assets/styles/components/popups.scss';

.early-access-popup {
  width: 76.125rem;
  padding: 1rem;
  &-content {
    width: 73.625rem;
    height: 32.125rem;
    @include background(
      url('#{$path-images}/tournaments/early-access-popup/sponsor-img_IBU_v1.avif'),
      contain,
      left
    );
    margin: 0 auto;
  }
  &-info {
    background-color: rgba($color: #031726, $alpha: 0.7);
    width: 72.375rem;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    .info-icon {
      width: 4.688rem;
      height: 4.688rem;
    }
  }
}
</style>
