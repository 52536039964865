import {
  LIVE,
  MECHANIC_TOURNAMENTS_DISCIPLINE_2,
  MINIGAME_ID,
  minigameRevertDelete,
} from '@/globalVariables'
import { capitalize, musicTheme, sendToFlutter } from '@/helpers/index.js'
import { internalAxios } from '@/plugins/vueAxios'
import router from '@/router'
import { tutorialClasses } from '@/data/tutorial'
import { appStartErrorHandler } from '@/globals/handlers/appStartErrorHandler'
import { getRouteWebName, isMobile } from '@/plugins'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useIframeStore } from '@/store/pinia/iframeStore'
import { usePhaserStore } from '@/store/pinia/map/phaser'
import { useModeStore } from '@/store/pinia/modeStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { allowedDisciplineModes, routeObject } from './routeListeners'
import type { TutorialStage } from '@/interfaces/Tutorial'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import type { Nullable } from '@/interfaces/utils'
// import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface MessageData {
  match_id: string
  mode: string
  discipline_id: number
  report_id: string
  redirect: string
  closeGame: boolean
  tournament_id?: number
  tournament_type?: string
}
interface Message {
  data: MessageData
}

export const routeListeners = function (context: any): void {
  window.addEventListener('message', async function (message: Message): Promise<void> {
    if (message?.data?.match_id) {
      sessionStorage.setItem(MINIGAME_ID, message.data.match_id)
    }

    // Early exit.
    if (
      !message?.data?.closeGame ||
      router.currentRoute.value.name.toString() === 'BoardGamesEventViewWeb'
    )
      return

    const useCore = useCoreStore()
    const renderQueueStore = useRenderQueueStore()
    useCore.setIsMinigame(false)
    renderQueueStore.setNextInRenderQueue()

    try {
      if (sessionStorage.getItem(MINIGAME_ID)) {
        try {
          internalAxios.post<{}, true>(minigameRevertDelete, {
            minigame_start_id: sessionStorage.getItem(MINIGAME_ID),
          })
        } catch (error: unknown) {
          console.error(error)
        }
        sessionStorage.removeItem(MINIGAME_ID)
      }

      const frame = <HTMLIFrameElement>document.getElementById('inlineFrameExample')
      if (frame) {
        frame.src = 'about:blank'
      }
      let isTutorialState: Nullable<boolean> = null
      // Vypnutie iframe - metoda je v App.vue - context pozicany odtial.
      const responseStore = useResponseTaskStore()
      await responseStore.updateParametersAfterMinigame()
      if (!responseStore.getUserLevelUp) {
        isTutorialState = await tutorialDecider(message.data)
      }

      setTimeout(async (): Promise<void> => {
        const useTutorial = useTutorialStore()
        if (context) {
          context.stopIframe()
        } else {
          const useIframe = useIframeStore()
          useIframe.setIframe(false)
          useIframe.setIframeUrl('')
        }
        // TODO: Analyze
        const responseStore = useResponseTaskStore()
        await responseStore.loadNotifications()

        const modeStore = useModeStore()
        const modes = modeStore.getMode
        const ROUTE_OBJECT = routeObject(modes)
        const ALLOWED_DISCIPLINE_MODES = allowedDisciplineModes(modes)
        // if (isMobile()) {
        //   usePhaserGameIntegrationStore().resumeScene()
        // }
        const userStore = useUserStore()
        const musicEnabled = userStore.getMusicVolume
        if (musicEnabled) {
          // eslint-disable-next-line no-useless-escape
          const enableMusic = '{\r\n  "event": "setMusic",\r\n "enabled": true\r\n}'
          sendToFlutter(enableMusic)
          if (musicTheme && !musicTheme.playing() && !isMobile()) {
            musicTheme.play()
          }
        }

        if (isTutorialState || useTutorial.getIsTutorial) return

        const mode = Number(message.data.mode)
        const disciplineId = Number(message.data.discipline_id)
        const reportId = message.data.report_id
        const report = { reportId, disciplineId }
        useTutorial.setReport(report)
        const usePhaser = usePhaserStore()
        const emitter = usePhaser.getEmitter
        if (emitter && userStore.getHasClub && userStore.isClubInChampionship) {
          emitter.emit('createChampionshipInfoLabel')
        }

        // if we came from discipline tutorial
        if (message.data.redirect) {
          router.push({
            name: message.data.redirect,
            params: { id: disciplineId },
            query: {
              redirectFrom: router.currentRoute.value.query.redirectFrom ?? undefined,
            },
          })
          return
        }

        if (mode === modes.duel || mode === modes.club_duel) {
          if (disciplineId && reportId && userStore.getShowResultScreen) {
            router.push({
              name: getRouteWebName(ROUTE_OBJECT[mode].route),
              query: {
                match_id: reportId,
                discipline_id: disciplineId,
                match_time: LIVE,
                redirectFrom: router.currentRoute.value.query.redirectFrom ?? undefined,
              },
            })
            return
          }
          router.push({
            name: getRouteWebName(
              mode === modes.club_duel ? 'ClubsChampionshipCompetitions' : 'ArenaView',
            ),
            params: mode === modes.duel ? { id: disciplineId } : null,
            query: {
              reloadData: 'true',
              redirectFrom: router.currentRoute.value.query.redirectFrom ?? undefined,
            },
          })
          return
        }
        if (mode === modes.boss_fight || mode === modes.event_boss_fight) {
          router.push({
            name: getRouteWebName(ROUTE_OBJECT[mode].route),
            query: {
              match_id: reportId,
              discipline_id: disciplineId,
              match_time: LIVE,
            },
          })
          return
        }

        if (mode === modes.tournament) {
          const tournamentId = Number(message.data.tournament_id)

          let routeNameSuffix = ''
          switch (message.data.tournament_type) {
            case MECHANIC_TOURNAMENTS_DISCIPLINE_2:
              routeNameSuffix = 'Discipline2'
              break
          }

          router.push({
            name: getRouteWebName(ROUTE_OBJECT[mode].route + routeNameSuffix),
            params: tournamentId ? { id: tournamentId } : null,
            query: { reloadData: 'true' },
          })
          return
        }
        if (mode in ROUTE_OBJECT) {
          router.push({
            name: getRouteWebName(ROUTE_OBJECT[mode].route),
            params: ALLOWED_DISCIPLINE_MODES.includes(mode) ? { id: disciplineId } : {},
            query: {
              reloadData: 'true',
            },
          })
        }
      }, 1000)
    } catch (error) {
      appStartErrorHandler()
      const useCore = useCoreStore()
      useCore.postError({
        type: 'global-js-error',
        message: JSON.stringify({
          text: 'Mame problem po navrate z minihry',
          error: `Message: ${error.message}, Error: ${error}`,
        }),
      })
    }
  })
}

export const tutorialDecider = async (message: MessageData): Promise<boolean> => {
  try {
    const useTutorial = useTutorialStore()
    // Interceptor handles state management -> REFACTOR!!!
    // FIXME: Tu sa vola tutorial 2 krat.
    await useTutorial.loadTutorial()
    const report = { reportId: message.report_id, disciplineId: Number(message.discipline_id) }
    const actualTutorial = useTutorial.getActualStage
    if (!actualTutorial) return false
    if (report.reportId && report.disciplineId) {
      useTutorial.setReport(report)
      await useTutorial.loadTutorial()
    }

    const currentStageName = actualTutorial.name ?? ''

    const tutorialId = useTutorial.getTutorialId
    const TutorialClass = tutorialClasses[`Tutorial${capitalize(tutorialId as string)}`]
    const tutorial = new TutorialClass()
    const actualStage = tutorial
      .stages()
      .find((stage: TutorialStage) => stage.name === currentStageName)

    if (!actualTutorial?.name) {
      useTutorial.setTutorial(false)
      useTutorial.setActualStage(null)
    } else {
      useTutorial.setActualStage(actualStage)
    }

    if (actualStage.pageName === 'ArenaReportTutorial') {
      router.push({
        name: 'ArenaReportTutorial',
        query: {
          match_id: report.reportId,
          discipline_id: report.disciplineId,
          match_time: LIVE,
        },
      })
    } else {
      router.push({
        name: actualStage.pageName,
        query: {
          reloadData: 'true',
          redirectFrom: router.currentRoute.value.query.redirectFrom ?? undefined,
        },
      })
    }

    return true
  } catch (error) {
    appStartErrorHandler()
    const useCore = useCoreStore()
    useCore.postError({
      type: 'global-js-error',
      message: JSON.stringify({
        text: 'Mame problem po navrate z minihry Tutorial',
        error: `Message: ${error.message}, Error: ${error}`,
      }),
    })
    return false
  }
}
