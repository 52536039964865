<template>
  <main class="tournaments-view absolute w-full h-full flex flex-col">
    <tournaments-header />
    <component :is="activeView" />
  </main>
</template>

<script lang="ts">
import TournamentsHeader from '@/components/Tournaments/TournamentsHeader.vue'
import TournamentsCalendar from '@/components/Tournaments/Calendar/CalendarMain.vue'
import TournamentsHistory from '@/components/Tournaments/History/HistoryMain.vue'
import TournamentsDetail from '@/components/Tournaments/Detail/DetailMain.vue'
import TournamentsMilestones from '@/components/Tournaments/Milestones/MilestonesMain.vue'
import TournamentsRankings from '@/components/Tournaments/Rankings/RankingsMain.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TournamentsView',
  components: {
    TournamentsHeader,
    TournamentsCalendar,
    TournamentsHistory,
    TournamentsDetail,
    TournamentsMilestones,
    TournamentsRankings,
  },
  computed: {
    supportedViews(): Record<string, string> {
      return {
        TournamentsCalendar: 'TournamentsCalendar',
        TournamentsHistory: 'TournamentsHistory',
        TournamentsDetail: 'TournamentsDetail',
        TournamentsMilestones: 'TournamentsMilestones',
        TournamentsRankingsPoints: 'TournamentsRankings',
        TournamentsRankingsTitles: 'TournamentsRankings',

        // IBU eCup
        TournamentsDetailDiscipline2: 'TournamentsDetail',
        TournamentsRankingsDiscipline2: 'TournamentsRankings',
      }
    },
    activeView(): string {
      return this.supportedViews[
        this.$route.name.toString().replace(/Web$/, '') ?? 'TournamentsCalendar'
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.tournaments-view {
  padding-top: 5.875rem;
  @include background(url('#{$path-images}/tournaments/background.avif'), cover);
}
</style>
