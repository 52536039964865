<template>
  <section class="flex items-center justify-between w-full h-full relative px-14 py-4">
    <div v-if="results" class="playoff-order h-full flex items-center relative">
      <template v-for="(round, i) in results.rounds" :key="i">
        <div
          v-if="i === 0 && 'pairs' in round"
          class="playoff-order-loss h-full flex flex-col justify-around"
        >
          <div
            v-for="(pair, j) in round.pairs"
            :key="j"
            class="playoff-pair relative"
            :class="{ [pair.players[0].color]: true }"
          >
            <playoff-box
              v-for="(player, k) in pair.players"
              :key="'box-full-' + k"
              :player="player"
              :active-icon="activeIcons[i]"
            />
          </div>
        </div>
        <div
          v-if="i === 1 && 'pairs' in round"
          class="playoff-order-quarterfinal h-full flex flex-col justify-around relative"
        >
          <div
            class="title w-full text-center text-24 font-bold text-texts-standard-default uppercase absolute"
            :class="{ active: currentRound === 2 }"
          >
            {{ getTitleTranslationKey(i + 1) }}
          </div>
          <div
            v-for="j in 2"
            :key="j + 1000"
            class="playoff-pair h-1/2 flex flex-col justify-around relative"
          >
            <template v-if="currentRound <= 1">
              <playoff-box
                v-for="k in 2"
                :key="'box-empty-' + k"
                :active-icon="activeIcons[i]"
                :player="{
                  ...EMPTY_PLAYER_OBJECT,
                  color: QUARTERFINAL_COLORS[(j - 1) * 2 + (k - 1)],
                }"
              />
            </template>
            <playoff-box
              v-for="(player, k) in round.pairs[j - 1].players"
              v-else
              :key="'box-full-' + k"
              :player="player"
              :active-icon="activeIcons[i]"
            />
          </div>
        </div>
        <div
          v-if="i === 2 && 'pairs' in round"
          class="playoff-order-semifinal h-full flex flex-col justify-around relative"
        >
          <div
            class="title w-full text-center text-24 font-bold text-texts-standard-default uppercase absolute"
            :class="{ active: currentRound === 3 }"
          >
            {{ getTitleTranslationKey(i + 1) }}
          </div>
          <div class="h-full flex flex-col justify-around relative">
            <template v-if="currentRound <= 2">
              <playoff-box v-for="k in 2" :key="'box-empty-' + k" :is-large="true" />
            </template>
            <playoff-box
              v-for="(player, k) in round.pairs[0].players"
              v-else
              :key="'box-full-' + k"
              :is-large="true"
              :active-icon="activeIcons[i]"
              :player="player"
            />
          </div>
        </div>
        <div
          v-if="i === 3 && 'winner' in round"
          class="playoff-order-winner absolute"
          :class="{
            'is-masters': state[0].is_master_playoff,
          }"
        >
          <template v-if="currentRound <= 3">
            <app-user
              :name="$te('playoffDrawRound4')"
              class="playoff-order-winner-name justify-center"
              :user-class="'!text-36 uppercase'"
            />
          </template>
          <template v-else>
            <app-user
              :name="round.winner.username"
              :country="round.winner.country"
              :no-country-text="true"
              class="playoff-order-winner-name justify-center"
              :user-class="'!text-36 uppercase'"
              :flag-class="'playoff-box-flag !w-9 !h-7'"
            />
            <div class="playoff-box-rewards flexing text-36 text-texts-standard-default italic">
              <span class="inline-flex flex-col w-[1em] h-[1em] mr-2">
                <app-main-icon
                  class="!mx-0"
                  :icon-name="activeIcons[Object.keys(activeIcons).length - 1]"
                  :icon-size="32"
                />
              </span>
              <span class="inline-flex flex-col">
                {{ $filters.$formatNumber(round.winner.points) }}
              </span>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div
      v-if="playoffResults?.league"
      class="league-flag absolute"
      :class="playoffResults.league"
    />
    <playoff-rewards-info
      v-if="playoffResults?.league"
      :league="playoffResults.league"
      state="draw"
      :title="getTitleTranslationKey(currentRound)"
      :task="getTaskTranslationKey()"
      :icon="activeIcons[currentRound - 1]"
      :current-round="currentRound"
      :eliminated-in-round="eliminatedInRound"
      :next-draw-in="countdown"
    />
  </section>
</template>

<script lang="ts">
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import PlayoffBox from './PlayoffBox.vue'
import PlayoffRewardsInfo from './PlayoffRewardsInfo.vue'
import type { TaskIcons } from '@/interfaces/events/QualificationTable'
import type PlayoffResultsApiResponse from '@/interfaces/responses/events/playoff/PlayoffResultsApiResponse'
import { PlayoffType } from '@/interfaces/events/Playoff'

const QUARTERFINAL_COLORS = ['blue', 'green', 'lightgreen', 'red', 'grey']

const EMPTY_PLAYER_OBJECT: PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0] = {
  user_games_id: '',
  username: '',
  country: '',
  advancing: null,
  points: 0,
  color: '',
}

interface ComponentData {
  QUARTERFINAL_COLORS: typeof QUARTERFINAL_COLORS
  EMPTY_PLAYER_OBJECT: typeof EMPTY_PLAYER_OBJECT
}

export default defineComponent({
  name: 'PlayoffDraw',
  components: {
    AppUser,
    PlayoffRewardsInfo,
    PlayoffBox,
  },
  data(): ComponentData {
    return {
      QUARTERFINAL_COLORS,
      EMPTY_PLAYER_OBJECT,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userId: 'getUserId',
    }),
    ...mapState(useEventPlayoffStore, {
      state: 'getPlayoffState',
      config: 'getPlayoffConfig',
      playoffResults: 'getPlayoffResults',
    }),
    currentRound(): number {
      if (!this.playoffResults?.rounds) return null
      return this.playoffResults.rounds.filter(
        ({ pairs, winner }: PlayoffResultsApiResponse['rounds'][0]): boolean => !!pairs || !!winner,
      ).length
    },
    results(): PlayoffResultsApiResponse {
      if (!this.playoffResults?.rounds) return

      const players: Record<
        PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0]['user_games_id'],
        (typeof QUARTERFINAL_COLORS)[0]
      > = {}
      const results: PlayoffResultsApiResponse = { ...this.playoffResults }

      for (let i = results.rounds.length; i < 4; i++) {
        results.rounds.push({ round: i + 1, pairs: null })
      }

      results.rounds[0].pairs.forEach(
        (pair: PlayoffResultsApiResponse['rounds'][0]['pairs'][0], i: number): void => {
          pair.players.forEach(
            (player: PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0]): void => {
              players[player.user_games_id] = QUARTERFINAL_COLORS[i]
            },
          )
        },
      )

      results.rounds = results.rounds.map(
        (
          round: PlayoffResultsApiResponse['rounds'][0],
        ): PlayoffResultsApiResponse['rounds'][0] => ({
          ...round,
          pairs: round.pairs
            ? round.pairs.map(
                (
                  pair: PlayoffResultsApiResponse['rounds'][0]['pairs'][0],
                ): PlayoffResultsApiResponse['rounds'][0]['pairs'][0] => ({
                  ...pair,
                  players: pair.players.map(
                    (
                      player: PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0],
                    ): PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0] => ({
                      ...player,
                      color: players[player.user_games_id],
                    }),
                  ) as [
                    PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0],
                    PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0],
                  ],
                }),
              )
            : null,
        }),
      )

      return results
    },
    eliminatedInRound(): number {
      if (!this.playoffResults?.rounds) return

      let eliminatedInRound: number
      const currentId =
        this.state[0].playoff_type === PlayoffType.Club ? this.playoffResults?.club_id : this.userId

      this.playoffResults.rounds.forEach(
        ({ round, pairs, winner }: PlayoffResultsApiResponse['rounds'][0]): void => {
          if (winner && winner.user_games_id === currentId) {
            eliminatedInRound = round
            return
          }

          if (!pairs) return

          pairs.forEach(({ players }: PlayoffResultsApiResponse['rounds'][0]['pairs'][0]): void => {
            players.forEach(
              ({
                user_games_id,
              }: PlayoffResultsApiResponse['rounds'][0]['pairs'][0]['players'][0]): void => {
                if (user_games_id === currentId) {
                  eliminatedInRound = round
                  return
                }
              },
            )
          })
        },
      )

      return eliminatedInRound !== this.currentRound ? eliminatedInRound : -1
    },
    countdown(): number {
      return this.currentRound !== 4 ? this.results.next_draw : 0
    },
    taskLength(): number {
      return Object.keys(this.config.qualification.tasks).length
    },
    activeIcons(): TaskIcons {
      const newTaskIcons = {}
      Object.values(this.config?.draw.tasks || {}).forEach((id: number, i: number): void => {
        newTaskIcons[i] = this.config?.task_icons[id]
      })
      return newTaskIcons
    },
  },
  async created(): Promise<void> {
    await this.loadPlayoffResults({ state: this.state[0].state })
  },
  methods: {
    ...mapActions(useEventPlayoffStore, ['loadPlayoffResults']),
    getTitleTranslationKey(round: number): string {
      return round === 4 ? this.$te('playoffEnded') : this.$t(`event.playoffDrawRound${round}`)
    },
    getTaskTranslationKey(): string {
      if (!this.currentRound || !this.eliminatedInRound) return ''

      if (this.eliminatedInRound === -1 && this.currentRound === 4) return this.$te('playoffWinner')
      if (this.eliminatedInRound > 0)
        return this.$t(`event.playoffEliminatedInRound${this.eliminatedInRound}`)

      const disciplineId = this.playoffResults.tasks.find(
        ({ round }: PlayoffResultsApiResponse['tasks'][0]): boolean => round === this.currentRound,
      )?.discipline_id

      if (disciplineId) {
        return this.$replacePlaceholder(
          this.$t(
            this.playoffResults.tasks.find(
              ({ round }: PlayoffResultsApiResponse['tasks'][0]): boolean =>
                round === this.currentRound,
            )?.translation,
          ),
          '{discipline}',
          this.$translateDiscipline(disciplineId),
        )
      }
      return this.$t(
        this.playoffResults.tasks.find(
          ({ round }: PlayoffResultsApiResponse['tasks'][0]): boolean =>
            round === this.currentRound,
        )?.translation,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
$playoff_colors: ('blue', 'green', 'lightgreen', 'red', 'grey');
$playoff_leagues: ('bronze', 'silver', 'gold', 'platinum', 'diamond');

.playoff-order {
  width: 80rem;

  &-loss,
  &-quarterfinal,
  &-semifinal {
    width: 22rem;

    .title {
      width: 16.75rem;
      text-shadow: 0.5px 0.9px 1px rgba(39, 39, 39, 0.86);
      -webkit-text-stroke: 0.0625rem #4c4c4c;

      &.active {
        color: #fdb946;
        -webkit-text-stroke: 0.0625rem #6a3714;
      }
    }
  }

  &-quarterfinal .title {
    top: 0.75rem;
  }

  &-semifinal .title {
    top: 6.25rem;
    width: 20rem;
  }

  &-winner {
    @include background(url($path-events + 'playoff/playoff-winner.avif'), contain);
    width: 29.5rem;
    height: 13.25rem;
    top: 15.75rem;
    right: -0.75rem;

    &-name {
      height: 4rem;
      margin-top: 3.25rem;
    }

    &.is-masters {
      background-image: url($path-events + 'playoff/playoff-winner-master.avif');
    }
  }

  .playoff-pair.relative {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -0.25rem;
      width: 9.5rem;
      height: 100%;
      @include background(url($path-events + 'playoff/playoff-line-grey.avif'), contain);
    }

    @each $color in $playoff_colors {
      &.#{$color}::after {
        background-image: url($path-events + 'playoff/playoff-line-#{$color}.avif');
      }
    }
  }

  &-quarterfinal .playoff-pair.relative::after {
    top: 3.125rem;
    bottom: 3.125rem;
    height: auto;
  }
}

.league-flag {
  width: 62rem;
  height: 5rem;
  top: 0.5rem;
  right: 6rem;

  @each $league in $playoff_leagues {
    &.#{$league} {
      @include background(url($path-events + 'playoff/playoff-#{$league}.avif'), contain);
    }
  }
}
</style>
