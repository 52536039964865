<template>
  <div :id="rewardId" :data-cy="rewardId" class="rewards flexing">
    <div
      v-for="(reward, index) in rewardData"
      :key="index"
      class="reward flexing"
      :class="[
        `${isVertical} ${getPositionClass(reward)}`,
        {
          'starts-bg': reward.type === STARTS && isEventPassActive && isHighlightedReward,
          'reputation-bg': reward.type === EVENT_REPUTATION,
          'no-margin': index === rewardData.length - 1 && modifierTag !== 'career',
        },
      ]"
      :data-modifier-tag="modifierTag"
      :data-reward-ad-state="isVideoRewardEarned ? 'earned' : ''"
    >
      <template
        v-if="reward.category?.includes('video_ads') && isRewardedAdLoaded && !isNoVideoAds"
      >
        <div
          v-show="isVideoAdAvailable || isVideoRewardEarned"
          class="flexing"
          :class="isVideoRewardEarned ? 'opacity-100' : 'opacity-50 ad-ribbon-bg'"
        >
          <span class="text-34 text-texts-standard-important italic">+</span>
          <p :class="['text-' + setFontSize, 'text-texts-standard-' + textColor]">
            {{ $filters.$formatNumber(reward.count ?? reward.value) }}
          </p>
          <app-main-icon
            :icon-size="48"
            :icon-name="getIconName(reward)"
            :tooltip="translateIconName(reward)"
          />
        </div>
        <app-multi-button
          v-if="!isVideoRewardEarned"
          v-show="isVideoAdAvailable"
          btn-type="glowing"
          btn-size="md"
          :btn-text="$t('button.watch')"
          multi-width="20.25rem"
          :loading="isMobilePlayingVideo"
          :disabled="!isVideoAdAvailable"
          @click="watchVideo(reward.category)"
        >
          <template #rightPart>
            <app-icon :icon-name="'play-ad-md'" />
          </template>
        </app-multi-button>
      </template>
      <template v-else>
        <p
          v-tippy="{
            theme: 'WSM',
            content: $filters.$formatNumber(reward.count ?? reward.value),
            placement: 'top',
            textAlign: 'center',
          }"
          :class="[
            `text-${setFontSize}`,
            `text-texts-standard-${textColor}`,
            {
              'font-bold': isFontBold,
              'ml-4 mr-6': iconSize === 72,
            },
          ]"
        >
          <!-- TODO: THIS IS HOTFIX, WE NEED TO HAVE SAME DATA STRUCTURE -->
          {{ $filters.$abbrRewardValue(reward.count ?? reward.value) }}
        </p>
        <div v-if="reward.type === DURABILITY" class="revert-durability flexing">
          <div
            v-tippy="{
              theme: 'WSM',
              content: $t('equipmentShop.durability'),
              placement: 'top',
            }"
            class="icon-equipment-durability-56 ml-4"
          />
        </div>
        <div v-else-if="reward.type === STARS" class="flexing">
          <div class="icon-star-full-56 ml-4" />
        </div>
        <app-icon
          v-else-if="reward?.eventCurrency"
          :icon-name="reward?.type"
          :tooltip-text="$t(`event.${reward?.name}`)"
          class="ml-4"
        />
        <app-main-icon
          v-else
          :class="{
            '!mx-0': iconSize === 72,
          }"
          :icon-size="iconSize"
          :icon-name="getIconName(reward)"
          :tooltip="translateIconName(reward)"
        />
      </template>
    </div>
    <div v-if="hasUnlocks && unlockItem" class="flexing mr-16 standard-position">
      <span class="text-34 text-texts-standard-important italic">+</span>
      <tippy theme="WSM" placement="top" max-width="70rem">
        <div class="icon-locked-shield reward-unlock">
          <div class="reward-unlock-new" />
        </div>
        <template #content>
          <career-unlock-tooltip :unlock-item="unlockItem" />
        </template>
      </tippy>
    </div>
  </div>
</template>

<script lang="ts">
import CareerUnlockTooltip from '@/components/Career/CareerUnlockTooltip.vue'
import {
  EVENT_REPUTATION,
  GRAND_PRIZE,
  OFFER_STATES,
  STARTS,
  DURABILITY,
  STARS,
  ICON_SIZES,
  VIDEO_AD_DURATION,
  AVATAR,
  AVATAR_BG,
} from '@/globalVariables'
import { createRandomId, requestWebAd, capitalize } from '@/helpers'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'
import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'

interface ComponentData {
  STARTS: typeof STARTS
  EVENT_REPUTATION: typeof EVENT_REPUTATION
  DURABILITY: typeof DURABILITY
  STARS: typeof STARS
}

export default defineComponent({
  name: 'RewardsComponent',
  components: {
    CareerUnlockTooltip,
  },
  props: {
    rewardId: {
      // id pre hlavny wrapper a data-cy
      type: String,
      default: createRandomId('reward'),
    },
    vertical: {
      // v pripade vertikalneho zobrazenia rewardov nadstavit na true
      type: Boolean,
      default: false,
    },
    rewardData: {
      type: Array as PropType<Reward[]>,
      required: true,
    },
    iconSize: {
      // velkost ikoniek
      type: Number,
      default: 56,
      validator(value: number): boolean {
        return ICON_SIZES.includes(value)
      },
    },
    textColor: {
      type: String,
      default: 'default',
    },
    hasUnlocks: {
      type: Boolean,
      default: false,
    },
    unlockItem: {
      type: Object as PropType<Nullable<CareerUnlockItem>>,
      default: () => null,
    },
    isVideoAdAvailable: {
      type: Boolean,
      default: false,
    },
    isVideoRewardEarned: {
      type: Boolean,
      default: false,
    },
    isFontBold: {
      type: Boolean,
      default: true,
    },
    customFontSize: {
      // velkost ikoniek
      type: Number,
      default: 0,
    },
    modifierTag: {
      type: String,
      default: '',
    },
    isHighlightedReward: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['watches', 'rewardedWatch'],
  data(): ComponentData {
    return {
      STARTS,
      EVENT_REPUTATION,
      DURABILITY,
      STARS,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isMobilePlayingVideo: 'getMobileVideoPlaying',
      isRewardedAdLoaded: 'getRewardedAdLoaded',
    }),
    ...mapState(useUserStore, {
      sex: 'getSex',
      eventPassInfo: 'getEventPassInfo',
    }),
    ...mapState(useGamePassStore, ['isNoVideoAds']),
    isVertical(): string {
      if (this.vertical) return 'rewards--vertical'
      return ''
    },
    setFontSize(): number {
      if (this.customFontSize) return this.customFontSize
      switch (this.iconSize) {
        case 32:
          return 28
        case 48:
          return 30
        case 56:
          return 36
        case 72:
          return 50
        default:
          return 34
      }
    },
    isEventPassActive(): boolean {
      return this.eventPassInfo.state === OFFER_STATES.active
    },
  },
  methods: {
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
      setVideoPlay: 'setVideoPlay',
      setRewardType: 'setRewardtype',
      setReward: 'setReward',
    }),
    getIconName(reward: Reward): string {
      // Note: The reward type is not the same as the task progress reward response.
      // rewards.map in ProgressMessages

      const rewardType = reward.type || reward.name

      // For avatar rewards: e.g. Reports *aggregated* footer (not in RewardBox >> AvatarBox)
      if (reward.name === AVATAR) return `avatar-${this.sex}-${reward.value}`
      if (reward.name === AVATAR_BG) return 'avatar-background-' + reward.value

      // Must have for AF event
      if (reward.multiple) {
        return reward.multiple === 2 ? 'double-reward' : 'triple-reward'
      }
      if (reward.icon) return reward.icon

      if (rewardType && rewardType.toLowerCase() === 'grandprize')
        return 'gp-' + reward.rarity // Career: rewardType can be undefined
      else return rewardType
    },
    translateIconName(reward: Reward): string {
      const rewardType = reward.type || reward.name
      if (reward.rarity && rewardType === 'gp-' + reward.rarity) {
        return this.$replacePlaceholder(
          this.$t('grandPrize.tier' + capitalize(reward.rarity)),
          '{grandprize}',
          GRAND_PRIZE,
        )
      } else {
        return rewardType
      }
    },
    getPositionClass(reward: Reward): string {
      if (reward.category?.includes('video_ads') && !this.isNoVideoAds) return 'last-position'
      return 'standard-position'
    },
    async watchVideo(category: string): Promise<void> {
      if (!this.isVideoAdAvailable) return
      this.setVideoPlay(true)
      setTimeout((): void => {
        this.setVideoPlay(false)
      }, VIDEO_AD_DURATION)
      this.$emit('watches', category)

      if (!this.$isMobile()) {
        requestWebAd({
          id: 'video-ad-web-default',
          onRequest: (): void => {
            this.webVideoPlay(true)
          },
          onSuccess: (): void => {
            this.webVideoPlay(true)
          },
          onError: (): void => {
            this.webVideoPlay(false)
          },
          onCallbackComplete: (): void => {
            this.$emit('rewardedWatch')
            this.webVideoPlay(false)
            this.setReward(true)
            this.setRewardType(category)
          },
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/equipment-icons.scss';
@import '@/assets/styles/components/icons/stars-icons.scss';

[data-modifier-tag=''] {
  --margin-right: 2rem;
}

[data-modifier-tag='tournaments'],
[data-modifier-tag='career'] {
  --margin-right: 1rem;
}

[data-modifier-tag='autumn-fair'] {
  --margin-right: 0.75rem;
}

[data-reward-ad-state=''] {
  --last-position-item: absolute;
}

[data-reward-ad-state='earned'] {
  --last-position-item: relative;
}

.no-margin {
  margin-right: 0 !important;
}

.reward {
  margin-right: var(--margin-right);

  & .ad-ribbon-bg {
    position: relative;

    & > * {
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: url($path-images + 'career/ad-ribbon-bg--orange.avif');
      width: 14.375rem;
      height: 3.75rem;
      right: -1.025rem;
      background-size: contain;
      background-position: right;
      background-repeat: no-repeat;
      z-index: 0;
    }
  }
}

.standard-position {
  order: 1;
}

.last-position {
  order: 2;
  position: var(--last-position-item);
  right: 0rem;
}

.rewards {
  &--vertical {
    display: flex;
    flex-direction: column-reverse;
  }
}

.reward-unlock {
  width: 3rem;
  height: 3rem;
  margin-left: 1.5rem;
  position: relative;

  &-new {
    width: 3em;
    height: 1.2rem;
    background-image: url($path-images + 'map/building_new_label.jpeg');
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    right: -2rem;
  }
}

.starts-bg {
  background: linear-gradient(
    90deg,
    rgba(140, 0, 178, 0) 0%,
    rgba(140, 0, 178, 1) 25%,
    rgba(140, 0, 178, 1) 50%,
    rgba(140, 0, 178, 1) 75%,
    rgba(140, 0, 178, 0) 100%
  );
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-style: solid;
  border-width: 0.125rem;
  border-image-source: linear-gradient(
    90deg,
    rgba(207, 7, 249, 0) 0%,
    rgba(207, 7, 249, 1) 20%,
    rgba(207, 7, 249, 1) 50%,
    rgba(207, 7, 249, 1) 80%,
    rgba(207, 7, 249, 0) 100%
  );
  border-image-slice: 1;
}

.reputation-bg {
  background: linear-gradient(
    90deg,
    rgba(11, 97, 159, 0) 0%,
    rgba(11, 97, 159, 0.8) 30%,
    rgba(11, 97, 159, 0.8) 70%,
    rgba(11, 97, 159, 0) 100%
  );
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-style: solid;
  border-width: 0.125rem;
  border-image-source: linear-gradient(
    90deg,
    rgba(36, 214, 255, 0) 0%,
    rgba(36, 214, 255, 0.8) 30%,
    rgba(36, 214, 255, 0.8) 70%,
    rgba(36, 214, 255, 0) 100%
  );
  border-image-slice: 1;
}
</style>
