<template>
  <div class="table-standard">
    <div
      v-for="(row, i) in rows"
      :key="i"
      class="club-activity-table text-texts-standard-default flex table-standard-row"
    >
      <app-user
        v-if="playerOrClub(row.activity_type) === 'player'"
        :id="row.oauth_user_games_id"
        class="club-activity-table-name"
        :country="row.country.three_letter_code"
        :name="row.username"
        :role="row.club_member_role"
        :badges-data="row.event_badges ?? null"
      />
      <app-club-name
        v-else
        class="club-activity-table-name"
        :club-id="getClubInfo.id"
        :name="getClubInfo.name"
        :country="getClubInfo.country"
        :club-logo-id="getClubInfo.logoId"
        :clubs-background-id="getClubInfo.logoBgId"
      />

      <section class="flex club-activity-table-text text-24 text-left">
        <p>
          {{ setActivityText(row.activity_type, row?.value) }}
          <span>
            <p v-if="hasPrestigeReward(row.activity_type)" class="flex">
              {{ $t('club.prestige_gained') + ':' }}
              <span class="flex items-center text-texts-standard-important pl-2">
                {{ row.value?.value }}
                <app-main-icon
                  v-if="hasPrestigeReward(row.activity_type)"
                  :icon-size="24"
                  :icon-name="'prestige'"
                />
              </span>
            </p>
          </span>
        </p>
      </section>
      <p class="club-activity-table-date text-32 flexing">
        {{ row.created_at }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { pathImages } from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubActivityResponse } from '@/interfaces/responses/club/ClubManagementResponse'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'ClubsActivityTable',
  components: {
    AppUser,
    AppClubName,
  },
  props: {
    rows: {
      type: Array as PropType<ClubActivityResponse[]>,
      default: () => [],
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useClubStore, {
      getClubInfo: 'getClubInfo',
    }),
  },
  methods: {
    hasPrestigeReward(activity: string): boolean {
      return (
        activity in
        {
          prestige_for_daily_league: true,
          prestige_for_daily_task: true,
          prestige_for_athlete_of_the_day: true,
          prestige_for_club: true,
          prestige_for_event: true,
          prestige_for_club_from_event: true,
          clubs_league_result: true,
          clubs_tournament_prestige: true,
        }
      )
    },
    setActivityText(row: string, value: ClubActivityResponse['value']): string {
      let text = ''
      let replacedValue = ''
      switch (row) {
        case 'club_manager_inactivity':
          text = this.$replacePlaceholder(this.$t('club.' + row), '{manager}', value?.manager || '')
          text = this.$replacePlaceholder(text, '{number}', value?.number || '')
          text = this.$replacePlaceholder(text, '{username}', value?.username || '')
          break
        case 'employee_hired':
        case 'employee_contract_terminated':
        case 'clubs_championship_result':
        case 'prestige_for_event':
          replacedValue =
            row !== 'clubs_championship_result'
              ? value?.name
              : value?.value
                ? value.value.toString()
                : ''
          text = this.$replacePlaceholder(
            this.$t('club.' + row),
            /{name}|%s|{event}/gi,
            replacedValue,
          )
          break

        case 'clubs_league_result':
          text = this.$replacePlaceholder(this.$t('club.clubs_league_result'), '%s', value?.rank)
          break
        case 'prestige_for_club_from_event':
          text = this.$replacePlaceholder(this.$t('club.' + row), '%s', value?.position || '')
          text = this.$replacePlaceholder(text, '{event}', value?.event ? this.$t(value.event) : '')
          break
        case 'clubs_tournament_prestige':
          text = this.$replacePlaceholder(
            this.$t('club.' + row),
            '{tournament}',
            this.$t(value?.tournament),
          )
          break
        default:
          text = this.$t('club.' + row)
      }
      return text
    },
    playerOrClub(text: string): string {
      const club = [
        'clubhouse_level_up',
        'stadium_level_up',
        'clubs_championship_result',
        'employee_hired',
        'employee_contract_terminated',
        'new_logo_available',
        'club_manager_inactivity',
        'clubs_league_result',
        'prestige_for_club_from_event',
        'clubs_tournament_prestige',
      ]
      if (club.includes(text)) return 'club'
      return 'player'
    },
  },
})
</script>

<style lang="scss" scoped>
.club-activity-table {
  width: 100%;
  height: 6.25rem;
  margin-bottom: 0.75rem;

  &-name {
    width: 30%;
    line-height: 2rem;
    padding-left: 1.5rem;
  }

  &-text {
    width: 45%;
    line-height: 2rem;
    display: flex;
    align-items: center;
  }

  &-date {
    width: 25%;
  }
}
</style>
