<template>
  <div class="app-page-wrapper reports w-full h-full relative flex flex-col items-center">
    <menu-component menu-type="reports" :title="$t('reports.reports')" />
    <section class="reports-main flex justify-between safe-area">
      <main class="reports-wrapper m-auto" :class="{ 'has-rewards': anyRewards }">
        <header-info>{{ $replacePlaceholder($t('reports.reportsInfo'), '%s', ttl) }}</header-info>

        <section
          v-if="!reports.length"
          class="reports-empty w-full h-full flex flex-col justify-center"
        >
          <p class="text-36 text-texts-standard-important uppercase">
            {{ $t('reports.noReports') }}
          </p>
          <p class="text-36 text-texts-standard-additional">
            {{ $replacePlaceholder($t('reports.noReportsText'), '%s', ttl) }}
          </p>
        </section>

        <app-scrollbar
          v-else
          scroll="y"
          slide="y"
          width="100%"
          height="100%"
          class="reports-list-scroll mt-7"
        >
          <report-item
            v-for="report in reports"
            :key="report.id"
            :report-data="report"
            @claim="claimRewards"
            @show-popup="showReportPopup"
            @time-out="getReports"
          />
        </app-scrollbar>
      </main>

      <div v-if="anyRewards" class="reports-footer w-full flex justify-center">
        <div
          class="reports-footer-wrapper h-full w-full flex items-center justify-between safe-area-x"
        >
          <div class="flexing">
            <p class="text-texts-standard-important text-34 mr-2">
              {{ $t('common.allRewards') }}
            </p>
            <div class="reports-footer-rewards flex h-full">
              <rewards :reward-data="shownRewards || []" :icon-size="48" :is-font-bold="false" />
              <tippy theme="WSM" placement="top" max-width="100%">
                <div
                  v-if="allRewards.length > maxRewardsInFooter"
                  class="reports-footer-rewards-additional flexing"
                >
                  <p class="hidden-rewards-count text-40">
                    +{{ allRewards.length - maxRewardsInFooter }}
                  </p>
                </div>
                <template #content>
                  <report-rewards-tooltip :rewards="hiddenRewards" />
                </template>
              </tippy>
            </div>
          </div>
          <app-button
            class="reports-footer-btn"
            :btn-type="$isWsm ? 'secondary' : 'confirm'"
            btn-size="md"
            :btn-text="$t('common.takeRewards')"
            @click="claimAllRewards"
          />
        </div>
      </div>

      <info-popup v-if="showPopup" :popup-title="popup.title" @close="showPopup = false">
        <ranking-table-head ranking-type="clubActivity" />
        <app-scrollbar
          class="ranking-club-activity-scrollbar"
          width="100%"
          height="43rem"
          scroll="y"
          slide="y"
        >
          <ranking-row-table :rows="popup.clubActivity" table="clubActivity" width="81.1875rem" />
        </app-scrollbar>
        <ranking-player :player-data="popup.myActivity" ranking-type="clubActivity" />
      </info-popup>
    </section>
  </div>
</template>

<script lang="ts">
import HeaderInfo from '@/components/Header/HeaderInfo.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import ReportItem from '@/components/Reports/ReportItem.vue'
import ReportRewardsTooltip from '@/components/Reports/ReportRewardsTooltip.vue'
import Rewards from '@/components/Rewards.vue'
import {
  ALL,
  AVATAR,
  AVATAR_BG,
  PRESTIGE,
  reportsEndpoint,
  reportsRewardsClaimEndpoint,
} from '@/globalVariables'
import { playSound } from '@/helpers'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import type {
  ReportsDetailApiResponse,
  ReportDetail,
} from '@/interfaces/responses/reports/ReportsApiResponse'
import type { NameValueString } from '@/interfaces/Global'
import type Reward from '@/interfaces/Reward'

interface Popup {
  title: string
  clubActivity: RankingUser[]
  myActivity: RankingUser
}

interface Report {
  id: string
  image: string
  title: string
  text: string
  timestamp: Date
  rewards: Reward[]
  rewardsClaimed: boolean
  timer: number
  replacementValue: string | number
  btnRoute: string
  btnText: string
  popup?: Popup
}

interface ComponentData {
  ttl: number
  maxRewardsInFooter: number
  showPopup: boolean
  reports: Report[]
  allRewards: Reward[]
  popup: Popup
}

export default defineComponent({
  name: 'ReportsComponent',
  components: {
    HeaderInfo,
    ReportItem,
    ReportRewardsTooltip,
    Rewards,
    InfoPopup,
    RankingTableHead,
    RankingRowTable,
    RankingPlayer,
  },
  data(): ComponentData {
    return {
      ttl: 3,
      reports: [],
      allRewards: [],
      maxRewardsInFooter: 8,
      showPopup: false,
      popup: null,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
      userData: 'getUserData',
    }),
    shownRewards(): Reward[] {
      return this.allRewards.slice(0, this.maxRewardsInFooter)
    },
    hiddenRewards(): Reward[] {
      return this.allRewards.slice(this.maxRewardsInFooter - this.allRewards.length)
    },
    anyRewards(): boolean {
      return this.reports.some((report: Report): boolean => {
        return report.rewards.length && !report.rewardsClaimed
      })
    },
  },
  async created(): Promise<void> {
    this.clearReportsNotifications()
    await this.getReports()
  },
  methods: {
    ...mapActions(useClubStore, {
      loadClubInfo: 'loadClubInfo',
    }),
    ...mapActions(useResponseTaskStore, {
      clearReportsNotifications: 'clearReportsNotifications',
    }),
    ...mapActions(useUserStore, ['loadUserData']),
    getReportTitle(item: NameValueString): boolean {
      return item.name === 'title'
    },
    getReportText(item: NameValueString): boolean {
      return item.name === 'text'
    },
    getReportIcon(item: NameValueString): boolean {
      return item.name === 'iconSource'
    },
    getBtnRoute(item: NameValueString): boolean {
      return item.name === 'buttonAction'
    },
    getBtnText(item: NameValueString): boolean {
      return item.name === 'button'
    },
    getReportTimeStamp(reportAge: number): Date {
      return new Date(Date.now() - reportAge * 1000)
    },
    mapRewards(reward: Reward): Reward {
      return { ...reward, name: reward.name.toLowerCase().replace('_gdd_id_', '-') }
    },
    async getReports() {
      const response = await this.$axios.get<{}, ReportsDetailApiResponse>(reportsEndpoint)
      if (response.defaultTTL) {
        this.ttl = Math.round(response.defaultTTL / 60 / 60)
      }
      this.allRewards = []
      this.reports = []
      const translations = []
      this.reports =
        response?.reports.reduce((finalArray: Report[], report: ReportDetail): Report[] => {
          const reportRewards = report.rewards?.map(this.mapRewards)
          const reportData = {
            id: report.id,
            image: report.type?.parameters?.find(this.getReportIcon)?.value ?? '',
            title: report.type?.parameters?.find(this.getReportTitle)?.value ?? '',
            text: report.type?.parameters?.find(this.getReportText)?.value ?? '',
            timestamp: this.getReportTimeStamp(report.existsTime),
            rewards: reportRewards,
            rewardsClaimed: report.rewardsClaimed,
            timer: report.timeToLive * 1000,
            replacementValue: report.value ?? '',
            btnRoute:
              report.type?.parameters?.find(this.getBtnRoute)?.value.replace('redirect', '') ?? '',
            btnText: report.type?.parameters?.find(this.getBtnText)?.value ?? '',
          }
          finalArray.push(reportData)
          if (reportData.replacementValue) {
            if (reportData.text.includes('equipment'))
              translations.push('equipmentShop.' + reportData.replacementValue)
            if (reportData.text.includes('research')) translations.push(reportData.replacementValue)
          }
          if (!report.rewardsClaimed && reportRewards.length) {
            this.sumRewards(reportRewards)
          }
          return finalArray
        }, []) ?? []
    },
    sumRewards(reportRewards: Reward[]): void {
      for (const currentReward of reportRewards) {
        const rewardIndex = this.allRewards.findIndex(
          (reward: Reward): boolean =>
            reward.name === currentReward.name && reward.rarity === currentReward.rarity,
        )
        if (rewardIndex >= 0) {
          // add to existing amount
          this.allRewards[rewardIndex].value += Number(currentReward.value)
        } else {
          // add this reward to array
          this.allRewards.push({ ...currentReward })
        }
      }
    },
    async claimAllRewards(): Promise<void> {
      try {
        await this.$axios.put<{}, ReportsDetailApiResponse>(reportsRewardsClaimEndpoint, {
          reports_id: ALL,
        })
        playSound('click-button-get-gems')
      } catch (error: unknown) {
        console.error(error)
      }
      this.getReports()
    },
    async claimRewards(report: Report, refreshData: boolean = true): Promise<void> {
      try {
        const hasUserAppearanceData = !!report?.rewards?.find(({ name }: Reward): boolean =>
          [AVATAR, AVATAR_BG].includes(name),
        )

        await this.$axios.put<{}, ReportsDetailApiResponse>(reportsRewardsClaimEndpoint, {
          reports_id: report.id,
        })
        playSound('click-button-get-gems')

        // Update avatar & background immediately after claim without refreshing the page
        if (hasUserAppearanceData) {
          this.loadUserData()
        }
      } catch (error: unknown) {
        console.error(error)
      }
      if (refreshData) {
        await this.getReports()

        const isPrestigeReward = report.rewards?.find(
          (item: Reward): boolean => item.name === PRESTIGE,
        )
        if (this.hasClub && isPrestigeReward) {
          const clubId = this.userData.club_stats?.id
          await this.loadClubInfo(clubId)
        }
      }
    },
    showReportPopup(reportId: string): void {
      // TODO club championships result data are not yet included in response from endpoint
      this.popup = this.reports.find((report: Report): boolean => {
        return report.id === reportId
      }).popup
      if (Object.keys(this.popup).length) {
        this.showPopup = true
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.reports {
  &-main {
    width: 98%;
    min-height: 0 !important;
    height: 100%;
    margin: 1.375rem auto;
  }

  &-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.has-rewards {
      padding-bottom: 6.875rem;
    }
  }

  &-footer {
    height: calc(6.875rem + var(--safe-area-inset-bottom));
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0rem -1rem 2.5rem 0rem rgb(0 0 0 / 60%);
    padding-left: var(--safe-area-inset-left);
    padding-right: var(--safe-area-inset-left);
    padding-bottom: var(--safe-area-inset-bottom);
    @if $isWsm {
      border-top: solid 0.125rem #5ba3dc;
      background: #142b45;
    }
    @if $isSsm {
      border-top: solid 0.125rem #5672a4;
      background: #252f45;
    }

    &-wrapper {
      padding-left: $default-margin;
      padding-right: $default-margin + 2rem;
    }

    &-rewards {
      margin-left: 1.25rem;

      &-additional {
        width: 4rem;
        height: 5rem;
        border: 0.063rem solid #ffe98e;
        background-color: rgba(3, 62, 86, 0.7);
        box-shadow:
          inset 0.063rem 0 1.25rem 0 rgba(25, 73, 105, 0.8),
          inset 0.75rem 0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8),
          inset 0 0 1.25rem 0 rgba(25, 73, 105, 0.8),
          inset -0.688rem -0.188rem 1.25rem 0 rgba(25, 73, 105, 0.8);
        position: relative;
        border-bottom-right-radius: 0.875rem;
        margin: 1rem;

        &:after {
          content: '';
          width: 0.938rem;
          height: 0.938rem;
          position: absolute;
          border-right: 0.063rem solid #fff;
          border-top: 0.063rem solid #fff;
          top: -0.063rem;
          right: -0.063rem;
        }

        &:before {
          content: '';
          width: 0.938rem;
          height: 0.938rem;
          bottom: -0.063rem;
          left: -0.063rem;
          position: absolute;
          border-left: 0.063rem solid #fff;
          border-bottom: 0.063rem solid #fff;
        }

        & .hidden-rewards-count {
          color: #fadd1e;
        }
      }
    }
  }
}
</style>
