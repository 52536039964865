<template>
  <footer
    v-if="playerData && Object.keys(playerData).length > 0"
    class="player-position flex items-center text-32"
    :class="{
      'justify-between': !isRecordsTable,
      'text-texts-standard-default': $isSsm,
      'text-texts-standard-dark': $isWsm,
      'is-not-firefox': isTournamentTable && !$isFirefox(),
    }"
  >
    <p
      class="player-position-position font-bold flexing h-full text-36"
      :class="{
        'text-texts-standard-default': $isWsm,
        'text-texts-standard-dark': $isSsm || ($isWsm && playerData.position <= 3),
        [`is-rank-${playerData.position}`]: playerData.position <= 3,
      }"
    >
      {{ playerData.position }}
    </p>
    <div
      class="player-position-player flex items-center h-full"
      :class="[
        {
          records: isRecordsTable,
          disciplines: isDisciplinesTable,
          'no-rewards': hideRewards,
          'is-tournament mr-auto ': isTournamentTable,
        },
      ]"
      :style="isClubActivityTable ? { 'flex-grow': 2 } : {}"
    >
      <p
        class="country text-24 uppercase"
        :class="{
          'text-texts-standard-default': $isSsm,
          'text-texts-standard-dark': $isWsm,
          'font-bold': !isTournamentTable || $isWsm,
        }"
      >
        {{ playerData.country }}
      </p>
      <div
        class="flag"
        :style="{
          backgroundImage: 'url(' + pathImages + 'flags/102x68/' + playerData.country + '.avif)',
        }"
      />
      <p class="name table-standard-row-username text-32">
        {{ playerData.username }}
      </p>
      <span v-if="playerData.event_badges?.length" class="badge-wrapper flexing">
        <event-badge :current-badges="playerData.event_badges ?? null" class="ml-2" />
      </span>
    </div>
    <p
      v-if="!isClubActivityTable && !isTournamentResultsTable && !isLeagueTable"
      class="player-position-level h-full flexing name"
      :class="[rankingType, { 'no-rewards': hideRewards }]"
    >
      {{ playerData.LEVEL ?? playerData.level }}
    </p>
    <p
      v-if="
        !hiddenColumns.includes('club') &&
        !isClubActivityTable &&
        !isTournamentResultsTable &&
        !isLeagueTable
      "
      class="player-position-club h-full flexing text-texts-standard-dark"
      :class="[rankingType, { 'no-rewards': hideRewards }]"
    >
      <app-club-name
        :club-id="playerData.club_id"
        :name="playerData.club"
        :club-logo-id="playerData.club_logo_id"
        :clubs-background-id="playerData.club_background_logo_id"
        :dark-text="$isWsm"
      />
    </p>
    <div
      v-if="!isRecordsTable && !isClubActivityTable && !isTournamentTable && !isLeagueTable"
      class="player-position-points h-full flexing"
      :class="rankingType"
    >
      <p>{{ playerData.RANKING_POINTS }}</p>
      <app-main-icon icon-name="ranking_points" :icon-size="48" />
    </div>
    <div
      v-if="isDisciplinesTable && playerData.rewards"
      class="player-position-rewards font-bold flexing"
    >
      <div
        v-for="(rewardObject, index) in playerData.rewards"
        :key="index"
        class="player-position-rewards-item flexing"
      >
        <p>{{ rewardObject.value ?? '' }}</p>
        <app-main-icon
          :icon-size="48"
          :icon-name="
            rewardObject.rarity
              ? rewardObject.type + capitalize(rewardObject.rarity)
              : rewardObject.type
          "
        />
      </div>
    </div>
    <p
      v-if="isRecordsTable"
      class="player-position-season h-full flexing text-texts-standard-important font-bold"
      :class="rankingType"
    >
      {{ playerData.season }}
    </p>
    <p v-if="isRecordsTable" class="player-position-result h-full flexing" :class="rankingType">
      {{ formatResult(playerData.result, playerData.discipline_id) }}
    </p>
    <div
      v-if="
        isClubActivityTable ||
        isTournamentPointsTable ||
        isTournamentTitlesTable ||
        isTournamentDiscipline2PointsTable ||
        isLeagueTable
      "
      class="player-position-points h-full flexing"
      :class="[rankingType, { 'is-tournament': isTournamentTable }]"
    >
      <template v-if="isTournamentPointsTable">
        <p>{{ $filters.$formatNumber(playerData.tournament_points) }}</p>
        <app-main-icon :icon-size="48" :icon-name="TOURNAMENT_POINTS" />
      </template>
      <template v-else-if="isTournamentTitlesTable">
        <p>{{ $filters.$formatNumber(playerData.tournament_titles) }}</p>
        <app-main-icon :icon-size="48" :icon-name="TOURNAMENT_TITLES" />
      </template>
      <template v-else-if="isTournamentDiscipline2PointsTable">
        <p>{{ $filters.$formatNumber(playerData.tournament_points) }}</p>
        <app-icon
          :icon-name="TOURNAMENT_POINTS_DISCIPLINE_2"
          :tooltip-text="$replacePlaceholder($t('tournaments.specialPoints'), '{partner}', 'IBU')"
          class="ml-2"
        />
      </template>
      <template v-else-if="isLeagueTable">
        <p>{{ $filters.$formatNumber(playerData.points) }}</p>
        <app-main-icon icon-name="league_points" :icon-size="48" />
      </template>
      <template v-else>
        <p>{{ playerData.points }}</p>
        <app-main-icon icon-name="ranking_points" :icon-size="48" />
      </template>
    </div>
    <div
      v-if="isTournamentResultsTable && !hiddenColumns.includes('result')"
      class="player-position-result h-full flexing"
      :class="rankingType"
    >
      {{ formatResult(playerData.result, playerData.discipline_id) }}
    </div>
    <div
      v-if="
        (isTournamentResultsTable || isTournamentDiscipline2PointsTable || isLeagueTable) &&
        playerData.rewards &&
        !hiddenColumns.includes('rewards')
      "
      class="player-position-rewards flexing"
      :class="{ 'is-only-one': isTournamentDiscipline2PointsTable }"
    >
      <div
        v-for="(rewardObject, index) in playerData.rewards"
        :key="index"
        class="player-position-rewards-item flexing"
      >
        <p>{{ rewardObject.value ?? '' }}</p>
        <!-- Shared icon, but event (AF) BE league sends duplicate rarity to type -->
        <app-main-icon
          :icon-size="48"
          :icon-name="
            rewardObject.rarity && isTournamentResultsTable
              ? rewardObject.type + capitalize(rewardObject.rarity)
              : rewardObject.type
          "
          class="no-margin ml-2"
        />
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import EventBadge from '@/components/GlobalComponents/EventBadge.vue'
import {
  pathImages,
  TOURNAMENT_POINTS,
  TOURNAMENT_TITLES,
  TOURNAMENT_POINTS_DISCIPLINE_2,
} from '@/globalVariables'
import { capitalize, formatResult } from '@/helpers'
import { defineComponent } from 'vue'

import { RankingType } from '@/interfaces/RankingsInterfaces'
import type { PropType } from 'vue'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'

interface ComponentData {
  TOURNAMENT_POINTS: typeof TOURNAMENT_POINTS
  TOURNAMENT_TITLES: typeof TOURNAMENT_TITLES
  TOURNAMENT_POINTS_DISCIPLINE_2: typeof TOURNAMENT_POINTS_DISCIPLINE_2
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'RankingPlayer',
  components: {
    AppClubName,
    EventBadge,
  },
  props: {
    playerData: {
      type: Object as PropType<Nullable<RankingUser>>,
      default: () => null,
    },
    rankingType: {
      type: String,
      default: RankingType.Global,
      validator: (value: RankingType): boolean => {
        return [
          RankingType.Global,
          RankingType.Discipline,
          RankingType.Records,
          RankingType.ClubActivity,
          RankingType.TournamentPoints,
          RankingType.TournamentTitles,
          RankingType.TournamentResults,
          RankingType.League,
        ].includes(value)
      },
    },
    hideRewards: {
      type: Boolean,
      default: false,
    },
    hiddenColumns: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
  },
  data(): ComponentData {
    return {
      TOURNAMENT_POINTS,
      TOURNAMENT_TITLES,
      TOURNAMENT_POINTS_DISCIPLINE_2,
      pathImages,
    }
  },
  computed: {
    isDisciplinesTable(): boolean {
      return this.rankingType === RankingType.Discipline
    },
    isRecordsTable(): boolean {
      return this.rankingType === RankingType.Records
    },
    isClubActivityTable(): boolean {
      return this.rankingType === RankingType.ClubActivity
    },
    isTournamentPointsTable(): boolean {
      return this.rankingType === RankingType.TournamentPoints
    },
    isTournamentTitlesTable(): boolean {
      return this.rankingType === RankingType.TournamentTitles
    },
    isTournamentResultsTable(): boolean {
      return this.rankingType === RankingType.TournamentResults
    },
    isTournamentDiscipline2PointsTable(): boolean {
      return this.rankingType === RankingType.TournamentPointsDiscipline2
    },
    isTournamentTable(): boolean {
      return (
        this.isTournamentPointsTable ||
        this.isTournamentTitlesTable ||
        this.isTournamentResultsTable ||
        this.isTournamentDiscipline2PointsTable
      )
    },
    isLeagueTable(): boolean {
      return this.rankingType === RankingType.League
    },
    setIconNames(reward: Reward): string {
      return reward.rarity ? `${reward.type}-${reward.rarity}` : reward.type
    },
  },
  methods: {
    capitalize,
    formatResult,
  },
})
</script>

<style lang="scss" scoped>
.player-position {
  width: 100%;
  height: 4rem;
  @if $isWsm {
    background: #d9ecff;
    border: 0.063rem solid #6c9ec4;
  }
  @if $isSsm {
    background: #cd4a8b;
    border: 0.125rem solid #c85493;
  }

  &.is-not-firefox {
    padding-right: 1rem;
  }

  &-position {
    width: 6.4375rem;
    position: relative;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);

    &:not([class*='is-rank-']) {
      @if $isWsm {
        background: #102636;
      }
      @if $isSsm {
        background: #fff;
      }
    }
  }

  &-player {
    padding-left: 1.0625rem;
    width: 29rem;

    &.disciplines {
      width: 36rem;

      &.no-rewards {
        padding-left: 3.0625rem;
      }
    }

    &.records {
      width: 35rem;
    }

    &.is-tournament {
      width: 30rem;

      .country {
        width: 3.75rem;
        margin: 1.8rem 0 0.875rem 0;
      }

      .flag {
        margin-left: 0;
      }
    }

    .flag {
      width: 3.0625rem;
      min-width: 3.0625rem;
      height: 2rem;
      background: url($path-images + 'flags/70x49/FLAG_SVK.avif') center no-repeat;
      background-size: contain;
      margin-right: 1.0625rem;
      margin-left: 0.5625rem;
    }
  }

  &-level {
    &.disciplines {
      width: 4.875rem;

      &.no-rewards {
        padding-left: 1rem;
      }
    }

    &.records {
      width: 2.875rem;
      margin-left: 0.7rem;
    }

    width: 8.125rem;
  }

  &-club {
    &.disciplines {
      width: 34rem;

      &.no-rewards {
        padding-left: 1rem;
      }
    }

    &.records {
      width: 37rem;
      margin-left: 1.5rem;
    }

    width: 20.75rem;
  }

  &-points,
  &-season,
  &-result {
    &.disciplines {
      width: 8rem;
    }

    width: 16.9375rem;

    &.is-tournament {
      width: 15rem;

      // na Vladyho ziadost
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }

  &-season {
    &.records {
      width: 15rem;
    }
  }

  &-result {
    &.records {
      width: 16rem;
    }
  }

  &-rewards {
    width: 30rem;
    margin-left: 1rem;

    &-item {
      margin-right: 1.25rem;
    }

    &.is-only-one {
      width: 15rem;

      :deep(.player-position-rewards-item) {
        margin-right: 0 !important;
      }
    }
  }
}
</style>
