import { playoffConfig, playoffResults, playoffState } from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import type { PlayoffState } from '@/interfaces/events/Playoff'
import type PlayoffConfigApiResponse from '@/interfaces/responses/events/playoff/PlayoffConfigApiResponse'
import type PlayoffStateApiResponse from '@/interfaces/responses/events/playoff/PlayoffStateApiResponse'
import type PlayoffResultsApiResponse from '@/interfaces/responses/events/playoff/PlayoffResultsApiResponse'

interface PlayoffStoreState {
  playoffId: PlayoffConfigApiResponse['playoffs'][0]['playoff_id'] | null
  playoffConfig: PlayoffConfigApiResponse['playoffs'][0] | null
  playoffConfigs: PlayoffConfigApiResponse['playoffs'] | null
  playoffState: PlayoffStateApiResponse | null
  results: PlayoffResultsApiResponse | null
}

export const useEventPlayoffStore = defineStore('eventPlayoff', {
  state: (): PlayoffStoreState => ({
    playoffId: null,
    playoffConfig: null,
    playoffConfigs: null,
    playoffState: null,
    results: null,
  }),
  getters: {
    getPlayoffId(): PlayoffConfigApiResponse['playoffs'][0]['playoff_id'] {
      return this.playoffId
    },
    getPlayoffConfig(): PlayoffConfigApiResponse['playoffs'][0] {
      if (this.playoffConfig) return this.playoffConfig
      if (this.playoffConfigs.length > 0) {
        return this.playoffConfigs.find(
          ({ playoff_id }: PlayoffConfigApiResponse['playoffs'][0]): boolean =>
            playoff_id === this.playoffId,
        )
      }
      return null
    },
    getNextPlayoffConfig(): PlayoffConfigApiResponse['playoffs'][0] | null {
      if (!this.playoffConfigs?.length) return null

      const sortedPlayoffConfigs = this.playoffConfigs.sort(
        (
          a: PlayoffConfigApiResponse['playoffs'][0],
          b: PlayoffConfigApiResponse['playoffs'][0],
        ): number => a.playoff_id - b.playoff_id,
      )

      const playoffConfig = sortedPlayoffConfigs.find(
        ({ playoff_id }: PlayoffConfigApiResponse['playoffs'][0]): boolean =>
          playoff_id > this.playoffId,
      )
      if (playoffConfig) return playoffConfig
      return null
    },
    getPlayoffConfigs(): PlayoffConfigApiResponse['playoffs'][0][] {
      return this.playoffConfigs
    },
    getPlayoffState(): PlayoffStateApiResponse {
      return this.playoffState
    },
    getPlayoffResults(): PlayoffResultsApiResponse {
      return this.results
    },
    getPlayoffType(): PlayoffStateApiResponse[0]['playoff_type'] {
      return this.playoffState?.[0]?.playoff_type ?? null
    },
  },
  actions: {
    async loadPlayoffConfig(isTournament: boolean = false): Promise<void> {
      try {
        if (this.playoffConfig !== null && this.playoffConfigs !== null) return
        const data = await internalAxios.post<{}, PlayoffConfigApiResponse>(playoffConfig)
        if (!data) return

        // TODO: Remove this
        if (isTournament) {
          this.playoffConfigs = data.playoffs
          return
        }
        this.playoffId = data.playoffs[0].playoff_id
        this.playoffConfig = data.playoffs[0]
        this.playoffConfigs = data.playoffs
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadPlayoffState(playoffId?: number): Promise<void> {
      try {
        if (playoffId) this.playoffId = playoffId
        const data = await internalAxios.post<{}, PlayoffStateApiResponse>(playoffState, {
          playoff_id: this.playoffId,
        })
        if (!data) return
        this.playoffState = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadPlayoffResults({ state }: { state: PlayoffState }): Promise<void> {
      try {
        const data = await internalAxios.post<{}, PlayoffResultsApiResponse>(playoffResults, {
          playoff_id: this.playoffId,
          state,
        })
        if (!data) return
        this.results = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
