<template>
  <main v-if="loaded" class="playoff-main flexing flex-col flex-grow w-full">
    <event-message
      v-if="state[0].is_recalculating && state[0]?.activity === 1"
      :in-recalculation="true"
      :timer="state[0].playoff_recalculation_ends"
    />
    <template v-else-if="state[0].state === PlayoffState.Qualification">
      <event-message
        v-if="state[0].round === 0"
        :not-started="true"
        :timer="config.playoff_start"
      />
      <event-message v-else-if="state[0].round !== 0" :in-progress="true" />
    </template>
    <template v-else-if="state[0].state === PlayoffState.Draw">
      <event-message
        v-if="results.playoff_started && !results.player_in_playoff"
        :eliminated="true"
        :timer="nextPlayoffStart"
      />
      <playoff-draw v-else />
    </template>
  </main>
</template>

<script lang="ts">
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventMessage from '../../EventMessage.vue'
import PlayoffDraw from './PlayoffDraw.vue'
import { PlayoffState } from '@/interfaces/events/Playoff'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  PlayoffState: typeof PlayoffState
  loaded: boolean
  refreshTimeout: Nullable<ReturnType<typeof setTimeout>>
}

export default defineComponent({
  name: 'PlayoffMain',
  components: {
    EventMessage,
    PlayoffDraw,
  },
  emits: ['reloadOperation'],
  data(): ComponentData {
    return {
      PlayoffState,
      loaded: false,
      refreshTimeout: null,
    }
  },
  computed: {
    ...mapState(useEventPlayoffStore, {
      config: 'getPlayoffConfig',
      state: 'getPlayoffState',
      results: 'getPlayoffResults',
      nextPlayoffConfig: 'getNextPlayoffConfig',
    }),
    nextPlayoffStart(): string {
      return this.nextPlayoffConfig?.playoff_start
    },
  },
  mounted(): void {
    if (this.state[0].is_recalculating && this.state[0]?.activity === 1) {
      const currentTime = new Date().getTime()
      const recalculationEndsTime = new Date(this.state[0].playoff_recalculation_ends).getTime()

      if (recalculationEndsTime > currentTime) {
        const timeDifference = recalculationEndsTime - currentTime

        this.refreshTimeout = setTimeout((): void => this.$emit('reloadOperation'), timeDifference)
      }
    }
  },
  beforeUnmount(): void {
    if (this.refreshTimeout) clearTimeout(this.refreshTimeout)
  },
  async created(): Promise<void> {
    await this.loadState()
    this.loaded = true
  },
  methods: {
    ...mapActions(useEventPlayoffStore, ['loadPlayoffResults']),
    async loadState(): Promise<void> {
      await this.loadPlayoffResults({ state: this.state[0]?.state })
    },
  },
})
</script>
