<template>
  <div v-if="contentLoaded" class="gp-main app-page-wrapper gp-bg absolute">
    <section class="w-full h-full safe-area overflow-hidden">
      <div class="gp-main-content h-auto">
        <div class="flexing relative">
          <grand-prize-main-header
            :header-data="{
              gpType: getGrandPrizeDetail.gpType,
              level: getGrandPrizeDetail.level,
            }"
            :open-helmets="openHelmets"
          />
          <app-main-icon
            v-if="getGrandPrizeDetail.gpType"
            :icon-name="`gp-${getGrandPrizeDetail.gpType}`"
            :icon-size="152"
            class="gp-logo"
            :class="$isMobile() && 'gp-logo-mobile'"
          />
        </div>
        <main>
          <grand-prize-main-title
            :title-data="{
              canOpenCount: getGrandPrizeDetail.canOpenCount,
              allRewardsOpened,
            }"
          />

          <div class="gp-main-content flexing" :class="$isMobile() && 'mobile-height'">
            <div
              v-for="(reward, i) in gpRewards"
              :key="i"
              :class="'gp-reward-box box-' + reward.id"
            >
              <transition :duration="750">
                <div v-if="reward.accessible" class="absolute">
                  <div
                    :id="`gp-reward-item-${reward.id}`"
                    class="gp-reward-item"
                    :class="{ 'is-disabled': isClaimingDisabled }"
                    @click="
                      startAnimation($event.target as HTMLElement, 'rotate-out-up-right'),
                        clickOnHelmet(reward.id)
                    "
                  />
                  <div class="gp-reward-shadow" />
                </div>
              </transition>

              <transition name="flip-in-y">
                <div v-if="!reward.accessible">
                  <grand-prize-badges
                    :badge="reward?.reward?.rarity ?? ''"
                    size="xl"
                    :reward="reward.reward"
                    :is-glowable="true"
                  />
                </div>
              </transition>
            </div>
          </div>
          <div class="gp-main-panel" :class="{ 'justify-end': isAnimatingButton }">
            <transition :name="getGrandPrizeDetail.canOpenCount > 0 ? 'fadeInDelayed' : 'fadeOut'">
              <div
                v-if="getGrandPrizeDetail.canOpenCount > 0 && !isAnimatingButton"
                class="gp-main-move"
                :class="{ 'gp-main-move-extended': moves.length > 2 }"
              >
                <p class="text-texts-standard-default text-28 uppercase font-bold gp-text">
                  <short-text
                    class="default-font-size"
                    :text="$t('common.free') + ':'"
                    :first-cut="8"
                    :second-cut="11"
                  />
                </p>
                <div class="gp-moves">
                  <div v-for="(move, i) in moves" :key="i" class="gp-moves-box flex relative">
                    <div class="gp-moves-item" :class="{ 'item-used': move.used }">
                      <div class="item-used" />
                    </div>
                    <transition-group name="fadeIn">
                      <app-icon v-if="move.used" :key="i" icon-name="done-sm" />
                    </transition-group>
                  </div>
                </div>
              </div>
            </transition>

            <transition
              :name="getGrandPrizeDetail.canOpenCount === 0 ? 'fadeInDelayed' : 'fadeOut'"
            >
              <div v-if="getGrandPrizeDetail.canOpenCount === 0" class="gp-main-buttons">
                <app-multi-button
                  btn-id="gp-buy-more-steps-btn"
                  btn-type="credit"
                  btn-size="sm"
                  multi-width="23.125rem"
                  :disabled="allOpened || isBuyExtraDisabled"
                  @click="!getNotShowConfirm ? showConfirmBuyMoreMoves() : buyMoves()"
                >
                  <template #leftPart>
                    <div class="flexing">
                      2x
                      <i class="gp-moves-item" />
                    </div>
                  </template>
                  <template #rightPart>
                    <span class="text-texts-standard-default">
                      {{ getGrandPrizeDetail.extraOpenPrice }}
                    </span>
                  </template>
                </app-multi-button>

                <confirm-popup
                  v-if="!getNotShowConfirm && showConfirmBuyMove"
                  :button-data="confirmButtonData"
                  :popup-title="$t('grandPrize.bonusSlots')"
                  :button-text-left="$t('button.continue')"
                  :button-text-right="getGrandPrizeDetail.extraOpenPrice"
                  @close="showConfirmBuyMove = false"
                  @action="buyMoves()"
                >
                  <div class="gp-buy-moves">
                    <p class="text-texts-standard-default text-36">
                      {{ $t('grandPrize.bonusSlotsConfirm') }}
                    </p>
                  </div>
                </confirm-popup>
                <template v-if="getGrandPrizeDetail.showAdButton && isRewardedAdLoaded">
                  <app-multi-button
                    v-if="isNoVideoAds"
                    btn-id="game-pass-claim-button"
                    btn-type="confirm"
                    btn-size="sm"
                    multi-width="23.125rem"
                    :loading="isLoadingGamePassRewards"
                    :disabled="allOpened || isLoadingGamePassRewards || isClaimedGamePassRewards"
                    @click="claimGamePassRewards"
                  >
                    <template #leftPart>
                      <div class="flexing">
                        2x
                        <i class="gp-moves-item" />
                      </div>
                    </template>
                    <template #rightPart>
                      <div class="flexing">FREE</div>
                    </template>
                  </app-multi-button>
                  <app-multi-button
                    v-else
                    btn-id="gp-show-video-ad-btn"
                    btn-type="primary"
                    btn-size="sm"
                    multi-width="23.125rem"
                    :loading="isMobilePlayingVideo"
                    :disabled="allOpened"
                    @click="playVideo"
                  >
                    <template #leftPart>
                      <div class="flexing">
                        2x
                        <i class="gp-moves-item" />
                      </div>
                    </template>
                    <template #rightPart>
                      <span class="text-texts-standard-default"> 1x </span>
                      <i class="play-ad-icon icon-ad-sm" />
                    </template>
                  </app-multi-button>
                </template>
              </div>
            </transition>

            <div class="gp-main-claim text-bg-gradient">
              <p class="text-texts-standard-default text-28 font-bold uppercase mx-4">
                {{ $t('grandPrize.getAllRewards') }}
              </p>
              <app-button
                class="z-1"
                btn-type="credit"
                btn-size="xsm"
                :disabled="allOpened || disabledBuyAll"
                @click="
                  !getNotShowConfirm
                    ? (showConfirmGainAll = true)
                    : $debounce(gainAllRewards, [false])
                "
              >
                <div class="flexing">
                  <p>
                    {{ getGrandPrizeDetail.instantOpenPrice }}
                  </p>
                  <app-main-icon :icon-size="56" :icon-name="'gems'" />
                </div>
              </app-button>
              <confirm-popup
                v-if="!getNotShowConfirm && showConfirmGainAll"
                :button-data="confirmButtonGainData"
                :popup-title="$t('grandPrize.getAllRewards')"
                :button-text-left="$t('grandPrize.openAll')"
                :button-text-right="getGrandPrizeDetail.instantOpenPrice"
                @close="showConfirmGainAll = false"
                @action="gainAllRewards"
              >
                <div class="gp-buy-moves">
                  <p class="text-texts-standard-default text-36">
                    {{ $t('grandPrize.getAllRewardsConfirm') }}
                  </p>
                </div>
              </confirm-popup>
            </div>
          </div>
        </main>
        <footer class="bordered-1">
          <div class="left-aside">
            <section v-if="!allRewards.length" class="flexing">
              <div class="flex items-center">
                <app-check-box
                  :size="$isWsm ? 'md' : 'sm'"
                  :is-checked="getNotShowConfirm"
                  @checked-action="changedConfirmChecked"
                >
                  <p
                    class="text-texts-standard-additional text-36 m-4"
                    :class="{
                      uppercase: $isWsm,
                    }"
                  >
                    {{ $t('common.noConfirmWindow') }}
                  </p>
                </app-check-box>
              </div>
            </section>
            <section v-else class="flexing">
              <p class="text-texts-standard-additional text-36 uppercase text-reward">
                {{ $t('common.allRewards') }}
              </p>
              <div class="flex">
                <transition-group name="popFadeIn">
                  <reward-box
                    v-for="(reward, index) in allRewards"
                    :key="index"
                    :reward-icon="72"
                    :reward-icon-name="reward.name?.toLowerCase()"
                    :reward-number="reward.value"
                    :class="{
                      'bounce-in--animated': checkAggregatedReward(reward.name),
                    }"
                  />
                </transition-group>
              </div>
            </section>
          </div>
          <div class="right-aside">
            <app-button
              btn-id="gp-claim-latter-btn"
              btn-type="primary"
              :btn-text="$t('common.claimLater')"
              btn-size="md"
              :disabled="!somethingOpened || isClaimButtonDisabled"
              @click="$debounce(beforeClaim, [true])"
            />

            <app-button
              btn-id="gp-claim-btn"
              btn-type="confirm"
              :btn-text="$t('common.claim')"
              btn-size="md"
              :disabled="!somethingOpened || isClaimButtonDisabled"
              @click="$debounce(beforeClaim, [false])"
            />
          </div>
        </footer>
      </div>
      <popup-window
        v-if="showLeavingPopup"
        popup-type="info"
        :popup-title="$t('common.warning')"
        @close="closeLeavingPopup"
      >
        <grand-prize-confirm-leave-popup
          :gain-all-price="getGrandPrizeDetail.instantOpenPrice"
          @claim="claim(isClickedLater)"
          @gain-all="$debounce(gainAllRewards, [false])"
          @is-checked="setLeavingPopupDisplay($event)"
        />
      </popup-window>

      <video-ad-web v-show="isWebVideo" @hide-video="webVideoPlay(false)" />
    </section>
  </div>
</template>

<script lang="ts">
import GrandPrizeBadges from '@/components/GrandPrize/GrandPrizeBadges.vue'
import GrandPrizeConfirmLeavePopup from '@/components/GrandPrize/GrandPrizeConfirmLeavePopup.vue'
import GrandPrizeMainHeader from '@/components/GrandPrize/GrandPrizeMainHeader.vue'
import GrandPrizeMainTitle from '@/components/GrandPrize/GrandPrizeMainTitle.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import ShortText from '@/components/ShortText.vue'
import {
  GEMS,
  GRAND_PRIZE,
  GRAND_PRIZE_REWARD_OPENED,
  GRAND_PRIZE_REWARD_UNOPENED,
  REWARD_RARITY,
  grandPrizeInstantEndPoint,
  grandPrizeShowEndPoint,
  grandPrizeSlotsUnlockEndPoint,
  grandPrizesClaimEndPoint,
  metaPremiumAdsClaimEndpoint,
} from '@/globalVariables'
import { playSound, requestWebAd, sendToFlutter } from '@/helpers'
import { useAdStore } from '@/store/pinia/adStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useGrandPrizeDetailStore } from '@/store/pinia/grandPrize/detailStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'

import type { GrandPrizeMainReward } from '@/interfaces/GrandPrize'
import type {
  GrandPrizeBuyMovesApiResponse,
  GrandPrizeShowApiResponse,
  GrandPrizeInstantApiResponse,
  GrandPrizePossibleReward,
} from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'
import type Reward from '@/interfaces/Reward'
import type { Nullable } from '@/interfaces/utils'
import type { PremiumVideoAdsClaimApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsClaimApiResponse'
import { VideoAdState } from '@/enums/VideoAdStates'

interface GrandPrizeButtonData {
  btnId: string
  btnType: string
  btnSize: string
}

interface GrandPrizeMove {
  used: boolean
}

interface ComponentData {
  GRAND_PRIZE: typeof GRAND_PRIZE
  contentLoaded: boolean
  showConfirmBuyMove: boolean
  showConfirmGainAll: boolean
  confirmButtonData: GrandPrizeButtonData
  confirmButtonGainData: GrandPrizeButtonData
  gpRewards: GrandPrizeMainReward[]
  gpAvailableRewards: GrandPrizePossibleReward[]
  moves: GrandPrizeMove[]
  allRewards: Reward[]
  openHelmets: number
  allHelmets: number
  showLeavingPopup: boolean
  noDisplayLeavingPopup: boolean
  allRewardsOpened: boolean
  isAnimatingButton: boolean
  gpClickCount: number
  isClickedLater: boolean
  isClaimButtonDisabled: boolean
  isLoadingGamePassRewards: boolean
  isClaimedGamePassRewards: boolean
  isClaimingDisabled: boolean
}

export default defineComponent({
  name: 'GrandPrizeMain',
  components: {
    GrandPrizeBadges,
    GrandPrizeConfirmLeavePopup,
    GrandPrizeMainHeader,
    GrandPrizeMainTitle,
    ShortText,
    ConfirmPopup,
    PopupWindow,
    VideoAdWeb,
  },
  data(): ComponentData {
    return {
      GRAND_PRIZE,
      contentLoaded: false,
      showConfirmBuyMove: false,
      showConfirmGainAll: false,
      confirmButtonData: {
        // button v confirme buyMoves
        btnId: 'gp-main-buy-moves',
        btnType: 'credit',
        btnSize: 'md',
      },
      confirmButtonGainData: {
        // button v confirme gainAll
        btnId: 'gp-main-gain-all',
        btnType: 'credit',
        btnSize: 'md',
      },

      gpRewards: [],
      gpAvailableRewards: [],
      moves: [
        // kolko GP možem otvoriť. Defaultne 2 ale mozem si kupiť dalsie 2
      ],
      allRewards: [],
      openHelmets: 0, // kolko heliem mam uz vykliknutych
      allHelmets: 6, // vsetky helmy dokopy
      showLeavingPopup: false,
      noDisplayLeavingPopup: false, // setter pre okliknutie popupu (vykona sa len raz a potom uz nikdy viac),
      allRewardsOpened: false,
      isAnimatingButton: false,
      gpClickCount: 0,
      isClickedLater: false,
      isClaimButtonDisabled: false,
      isLoadingGamePassRewards: false,
      isClaimedGamePassRewards: false,
      isClaimingDisabled: false,
    }
  },

  computed: {
    ...mapState(usePremiumStore, {
      isMobilePlayingVideo: 'getMobileVideoPlaying',
      isWebVideo: 'getWebVideoPlaying',
      isRewardedAdLoaded: 'getRewardedAdLoaded',
    }),
    ...mapState(useUserStore, {
      getNotShowConfirm: 'getNotShowConfirm',
      getGpLeavingPopup: 'getGpLeavingPopup',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useGrandPrizeDetailStore, {
      getGrandPrizeDetail: 'getGrandPrizeDetail',
      getOpenHelmetsLength: 'getOpenHelmetsLength',
      getOpenGps: 'getOpenGps',
    }),
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
    }),
    ...mapState(useGamePassStore, ['isNoVideoAds']),
    allOpened(): boolean {
      return (
        this.gpRewards.filter((reward: GrandPrizeMainReward): boolean => !reward.accessible)
          .length === this.gpRewards.length
      )
    },
    somethingOpened(): boolean {
      return (
        this.gpRewards.filter((reward: GrandPrizeMainReward): boolean => !reward.accessible)
          .length > 0
      )
    },
    disabledBuyAll(): boolean {
      if (!this.moves) return false
      return (
        this.moves.filter((move: GrandPrizeMove): boolean => move.used).length >
          this.getGrandPrizeDetail.freeSlotsCount ||
        this.gems.value < this.getGrandPrizeDetail.instantOpenPrice
      )
    },
    isBuyExtraDisabled(): boolean {
      return this.gems.value < this.getGrandPrizeDetail.extraOpenPrice
    },
  },

  async created(): Promise<void> {
    await this.loadGrandPrizeDetail(this.$route.params?.id?.toString())
    this.initGrandPrize(this.getGrandPrizeDetail)
    this.gpAvailableRewards = this.getGrandPrizeDetail.available
    this.initMoves()
    this.setHelmets()
    this.setContentLoaded()
  },

  mounted(): void {
    window.rewardedVideoEarned = this.rewardedVideoEarned
    window.failedToLoadVideoAds = this.rewardedVideoFailed
    window.rewardedVideoEnd = this.rewardedVideoEnd
  },

  methods: {
    sendToFlutter,
    ...mapActions(useAdStore, ['setAdBlockState']),
    ...mapActions(usePremiumStore, {
      setVideoPlay: 'setVideoPlay',
      webVideoPlay: 'setWebVideoPlay',
    }),
    ...mapActions(useUserStore, ['setProfileAttributes', 'setNotShowConfirm']),
    ...mapActions(useGrandPrizeDetailStore, {
      loadGrandPrizeDetail: 'loadGrandPrizeDetail',
      setOpenGp: 'setOpenGp',
    }),
    rewardedVideoFailed(): void {
      this.setAdBlockState(true)
      this.setVideoPlay(false)
    },
    rewardedVideoEnd(): void {
      this.setVideoPlay(false)
    },
    setHelmets(): void {
      this.openHelmets = this.getOpenHelmetsLength
    },
    setContentLoaded(): void {
      this.contentLoaded = true
    },

    setSlot(slotNumber: number, slotState: boolean, reward: Reward): GrandPrizeMainReward {
      return {
        id: slotNumber,
        accessible: slotState,
        reward,
      }
    },
    setReward(rarity: string, name: string, value: number): GrandPrizeMainReward['reward'] {
      return {
        rarity,
        name,
        value,
      }
    },
    setMove(used: boolean): GrandPrizeMove {
      return { used }
    },
    initCheckedMoves(opened: { amount: number }, count: number): number {
      let usedCount = 0
      for (let i = 1; i <= count; i++) {
        let used = false
        if (opened.amount > 0) {
          used = true
          opened.amount--
          usedCount++
        }
        this.moves.push(this.setMove(used))
      }
      return usedCount
    },
    initMoves(): void {
      const opened = {
        amount: this.gpRewards.filter((reward: GrandPrizeMainReward): boolean => !reward.accessible)
          .length,
      }
      const usedCount = this.initCheckedMoves(opened, this.getGrandPrizeDetail.freeSlotsCount)
      if (
        this.moves.length === this.getGrandPrizeDetail.freeSlotsCount &&
        this.moves.length === usedCount &&
        this.getGrandPrizeDetail.canOpenCount > 0
      ) {
        this.initCheckedMoves(opened, this.getGrandPrizeDetail.adSlots)
      }
    },
    // TODO!: fix type
    initGrandPrize(response: any): void {
      const gainedRewards = []
      const res = response?.rewards ?? response
      this.gpRewards = res.map((reward: Reward): GrandPrizeMainReward => {
        if (reward.slot_state === GRAND_PRIZE_REWARD_OPENED) {
          const gainedReward = gainedRewards.find((item: Reward) => item.name === reward.name)
          if (gainedReward) {
            gainedReward.value += reward.value
          } else {
            gainedRewards.push(reward)
          }
        }

        return this.setSlot(
          reward.slot_number,
          reward.slot_state === GRAND_PRIZE_REWARD_UNOPENED,
          reward.slot_state === GRAND_PRIZE_REWARD_OPENED
            ? this.setReward(reward?.rarity, reward?.name, reward?.value)
            : {},
        )
      })

      this.allRewards = gainedRewards
    },
    async loadGrandPrize(id: string): Promise<void> {
      try {
        const response = await this.$axios.get<{}, GrandPrizeShowApiResponse>(
          grandPrizeShowEndPoint + id,
        )

        response.rewards.map((reward: Reward): number | void => {
          if (reward.slot_state === 'opened') {
            return this.openHelmets++
          }
        })
        this.initGrandPrize(response)
        this.initMoves()
      } catch (err: unknown) {
        console.error(err)
      } finally {
        this.contentLoaded = true
      }
    },
    changedConfirmChecked(): void {
      this.setNotShowConfirm()
    },
    async openSlot(slotNumber: Nullable<number> = null): Promise<void> {
      await this.setOpenGp({
        gpId: this.getGrandPrizeDetail.gpId,
        slotNumber,
      })
    },
    async openGp(slotId: number): Promise<void> {
      this.isClaimButtonDisabled = true
      playSound('click-daily-rewards-balls')
      this.openHelmets++
      const addRwd = this.gpRewards.find(
        (reward: GrandPrizeMainReward): boolean => reward?.id === slotId,
      )

      if (addRwd) addRwd.accessible = false

      const availableReward = this.gpAvailableRewards[0]
      // Creates a new gpAvailableRewards array starting from index 1 (second element) to the end
      this.gpAvailableRewards = this.gpAvailableRewards.slice(1)

      addRwd.reward = availableReward

      const response: Reward = {
        slot_number: availableReward?.slot_number,
        slot_state: 'opened',
        value: availableReward?.value,
        name: availableReward?.name,
        rarity: availableReward?.rarity,
      }

      const reward = this.allRewards.find(
        (reward: Reward): boolean => reward.name === response?.name,
      )

      if (reward) {
        reward.value += response?.value
      } else {
        this.allRewards.push(response)
      }

      switch (response?.rarity) {
        case REWARD_RARITY.bronze:
          playSound('action-daily-rewards-bronze')
          break

        case REWARD_RARITY.silver:
          playSound('action-daily-rewards-silver')
          break

        case REWARD_RARITY.gold:
          playSound('action-daily-rewards-gold')
          break
        default:
      }

      await this.openSlot(availableReward?.slot_number)
      this.getGrandPrizeDetail.canOpenCount-- // odrataj mi tah
      this.movesStatus()
      await this.loadGrandPrizeDetail(this.$route.params?.id?.toString())

      this.isClaimButtonDisabled = false
    },
    movesStatus(): void {
      // metoda ktora meni stav dostupnych tahov
      const move = this.moves.find((move: GrandPrizeMove): boolean => !move.used)

      if (!move) return
      move.used = true
    },
    async buyMoves(): Promise<void> {
      // po kupení pridaj tahy
      this.showConfirmBuyMove = false
      this.gpClickCount = 0
      this.isAnimatingButton = true
      const response = await this.$axios.put<{}, GrandPrizeBuyMovesApiResponse>(
        grandPrizeSlotsUnlockEndPoint,
        {
          grand_prize_id: this.getGrandPrizeDetail.gpId,
          unlock_type: GEMS,
        },
      )
      // at this moment the available rewards field is empty
      // we fill it with rewards after unlocking/purchasing

      this.gpAvailableRewards = response.available
      if (
        this.gpRewards.filter((reward: GrandPrizeMainReward): boolean => reward.accessible)
          .length <= response.can_open_grand_prize_slots_count
      ) {
        this.finishAll()
        playSound('click-daily-rewards-balls')

        await this.openSlot()
        const response: any = await this.getOpenGps

        // fetch remaining gp-reward-item elements representing helmets that need to be animated with rotation
        const unopenedElements = document.querySelectorAll('.gp-reward-item')
        unopenedElements.forEach((element: HTMLElement) => {
          this.startAnimation(element, 'rotate-out-up-right', true)
        })

        this.gpRewards = response.map((reward: Reward): GrandPrizeMainReward => {
          return this.setSlot(
            reward.slot_number,
            reward.slot_state === GRAND_PRIZE_REWARD_UNOPENED,
            reward.slot_state === GRAND_PRIZE_REWARD_OPENED
              ? this.setReward(reward?.rarity, reward?.name, reward?.value)
              : {},
          )
        })
        this.loadGrandPrizeDetail(this.$route.params?.id?.toString())
        this.initGrandPrize(response)

        setTimeout(() => {
          this.isAnimatingButton = false
        }, 200)

        return
      }
      this.initCheckedMoves(
        { amount: 0 },
        response.can_open_grand_prize_slots_count - this.getGrandPrizeDetail.canOpenCount,
      )
      this.getGrandPrizeDetail.canOpenCount = response.can_open_grand_prize_slots_count

      setTimeout((): void => {
        this.isAnimatingButton = false
      }, 200)
    },
    async gainAllRewards(): Promise<void> {
      this.showConfirmGainAll = false
      this.openHelmets = 6

      const response = await this.$axios.put<
        {},
        GrandPrizeInstantApiResponse,
        { grand_prize_id: string }
      >(grandPrizeInstantEndPoint, {
        grand_prize_id: this.getGrandPrizeDetail.gpId,
      })

      this.showConfirmBuyMove = false
      this.showLeavingPopup = false
      this.finishAll()
      playSound('click-daily-rewards-balls')

      const unopenedElements = document.querySelectorAll('.gp-reward-item')
      unopenedElements.forEach((element: HTMLElement): void => {
        this.startAnimation(element, 'rotate-out-up-right', true)
      })

      this.loadGrandPrizeDetail(this.$route.params?.id?.toString())

      this.initGrandPrize(response.grand_prize)
    },
    async playVideo(): Promise<void> {
      this.setVideoPlay(true)
      setTimeout((): void => {
        this.setVideoPlay(false)
      }, 6000)
      if (!this.$isMobile()) {
        this.webVideoPlay(true)
      }

      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: 'grand_prize_slot',
          state: VideoAdState.Started,
        })
      } catch (error: unknown) {
        console.error(error)
      }

      if (this.$isMobile()) {
        this.sendToFlutter('{\r\n "event":"playRewarded"\r\n}')
      }

      if (!this.$isMobile()) {
        requestWebAd({
          id: 'video-ad-web-default',
          onCallbackComplete: (): void => {
            this.rewardedVideoEarned()
            this.setVideoPlay(false)
          },
        })
      }
    },
    beforeClaim(later: boolean): void {
      if (this.isClaimButtonDisabled) return
      this.setHelmets()

      if (
        this.openHelmets !== this.allHelmets &&
        (this.getGpLeavingPopup === undefined || this.getGpLeavingPopup)
      ) {
        this.showLeavingPopup = true
        this.isClickedLater = later
      } else this.claim(later)
    },

    async claim(later: boolean = false): Promise<void> {
      this.setGPLeavingProfileAttribute()
      if (!this.allRewards.length) return
      try {
        await this.$axios.put<{}, true>(grandPrizesClaimEndPoint, {
          grand_prizes_id: this.getGrandPrizeDetail.gpId,
          later: later,
        })
      } catch (error: unknown) {
        console.error(error)
      }

      if (this.actualStageData && this.actualStageData.name === 'closeGrandPrizeNarrative') {
        this.$router.replace({ name: 'GrandPrizeWarehouseTutorial' })
      } else {
        this.redirectToWareHouse()
      }
    },
    redirectToWareHouse(): void {
      this.$router.replace({ name: this.$getWebView('GrandPrizeWarehouse') })
    },
    finishAll(): void {
      this.moves.forEach((move: GrandPrizeMove): void => {
        move.used = true
      })
      this.allRewardsOpened = true
    },
    setLeavingPopupDisplay(bool: boolean): void {
      this.noDisplayLeavingPopup = bool
    },
    closeLeavingPopup(): void {
      this.setGPLeavingProfileAttribute()
      this.showLeavingPopup = false
    },
    async setGPLeavingProfileAttribute(): Promise<void> {
      if (this.noDisplayLeavingPopup) {
        await this.setProfileAttributes({
          name: 'grand_prize_leaving_popup',
          value: 0,
        })
      }
    },
    async clickOnHelmet(rewardId: number): Promise<void> {
      if (this.isClaimingDisabled) return

      this.gpClickCount++

      if (this.getGrandPrizeDetail.canOpenCount > 0 && this.gpClickCount <= 2) {
        this.isClaimingDisabled = true
        await this.openGp(rewardId)
        this.isClaimingDisabled = false
      } else {
        this.showConfirmBuyMove = true
      }
    },
    async rewardedVideoEarned(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: 'grand_prize_slot',
          state: VideoAdState.Finished,
        })
      } catch (error: unknown) {
        console.error(error)
      }

      this.webVideoPlay(false)
      this.moves = []
      await this.loadGrandPrizeDetail(this.$route.params?.id?.toString())
      this.gpAvailableRewards = this.getGrandPrizeDetail.available
      this.gpClickCount = 0
      this.initMoves()
      this.setVideoPlay(false)
    },
    startAnimation(item: HTMLElement, name: string, force: boolean = false): void {
      if (this.isClaimingDisabled) return

      const element = item

      // handling + processing secondary step of purchasing rewards using gems or performing bulk FORCE turn of rewards
      if (this.getGrandPrizeDetail.canOpenCount > 0 || force) {
        element.classList.add(`${name}--animated`, 'pointer-events-none')

        element.addEventListener(
          'animationend',
          () => {
            element.classList.remove(`${name}--animated`, 'pointer-events-none')
          },
          { once: true },
        )
      }
    },
    checkAggregatedReward(rewardName: string): boolean {
      const rewards = this.getGrandPrizeDetail.rewards
      const count = rewards.filter(
        (reward: Reward): boolean => reward.name === rewardName && reward.slot_state === 'opened',
      ).length
      return count > 1
    },
    showConfirmBuyMoreMoves(): void {
      if (this.allOpened) return
      this.showConfirmBuyMove = true
    },
    async claimGamePassRewards(): Promise<void> {
      if (!this.isNoVideoAds) return
      if (this.isLoadingGamePassRewards || this.isClaimedGamePassRewards) return

      try {
        this.isLoadingGamePassRewards = true
        this.isClaimedGamePassRewards = false
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: 'grand_prize_slot',
          state: VideoAdState.Started,
        })
        this.moves = []
        await this.loadGrandPrizeDetail(this.$route.params?.id?.toString())
        this.gpAvailableRewards = this.getGrandPrizeDetail.available
        this.gpClickCount = 0
        this.initMoves()
        this.isClaimedGamePassRewards = true
      } catch (error: unknown) {
        console.error(error)
      } finally {
        this.isLoadingGamePassRewards = false
      }
    },
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/views/grand-prize/grandPrizeMain.scss';
@import '@/assets/styles/animations.scss';

.play-ad-icon {
  margin-bottom: 0.5rem;
  margin-left: 0.8rem;
  background-size: 100% 100%;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  opacity: 0;
  animation: 0.5s fade-in;
}

.fadeOut-enter-active,
.fadeOut-leave-active {
  animation: 0.3s fade-out;
}

.fadeInDelayed-enter-active,
.fadeInDelayed-leave-active {
  opacity: 0;
  animation: 0.5s 0.6s fade-in;
}

.popFadeIn-enter-active,
.popFadeIn-leave-active {
  opacity: 0;
  animation: 0.5s 0.6s pop-fade-in;
}

.popFadeOut-enter-active,
.popFadeOut-leave-active {
  animation: 0.4s pop-fade-out;
}

.flip-in-y-enter-active,
.flip-in-y-leave-active {
  animation: 1.3s flip-in-y;
}

.gp-reward-item {
  opacity: 0;
  animation: fade-in-down 0.3s forwards;
}

#gp-reward-item {
  &-1 {
    animation-delay: 50ms;
  }

  &-2 {
    animation-delay: 150ms;
  }

  &-3,
  &-4 {
    animation-delay: 200ms;
  }

  &-5 {
    animation-delay: 100ms;
  }

  &-6 {
    animation-delay: 0ms;
  }
}

.rotate-out-up-right--animated {
  animation: 0.75s rotate-out-up-right !important;
}

.bounce-in--animated {
  animation: 0.75s bounce-in !important;
}
</style>
