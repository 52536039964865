import {
  ARENA,
  MULTIPLIER,
  Multiplier,
  TRAINING_HALL,
  disciplineMenuEndpoint,
  disciplinesByGameEndpoint,
  gamesConfigEndpoint,
  trainerDisciplinesEndpoint,
  userDisciplinesEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { useUserStore } from '@/store/pinia/userStore'
import { defineStore } from 'pinia'
import { gameToken } from '@/helpers'
import { ApiService } from '@/services/ApiService'

import type {
  AvailableDisciplinesApiResponse,
  DisciplineConfigResponse,
  DisciplineMenuDataResponse,
  TrainerDisciplineResponse,
  SpecificDisciplineResponse,
} from '@/interfaces/responses/disciplines/DisciplinesApiResponses'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { FinalObject } from '@/helpers'
// FIXME: Toto malo byt externalizovane v responsoch a nie tu.
// + Treba pomenit aj vsade, kde sa to importlo.

interface TrainerDisciplineInterface {
  id: number
  slotId: string
  state: string
  expiration: number
}

export interface DisciplineStoreState {
  unlockedDisciplines: AvailableDisciplinesApiResponse[]
  disciplinesUnlockConfig: DisciplineConfigResponse[]
  disciplinesMenuData: DisciplineMenuData[]
  specificDiscipline: FinalObject
  currentGameDisciplinesIds: number[]
  isLoading: boolean
}

export const useDisciplineStore = defineStore('disciplineStore', {
  state: (): DisciplineStoreState => ({
    unlockedDisciplines: [],
    disciplinesUnlockConfig: [],
    disciplinesMenuData: [],
    specificDiscipline: null,
    currentGameDisciplinesIds: null,
    isLoading: false,
  }),
  getters: {
    getUnlockedDisciplines: (
      state: DisciplineStoreState,
    ): DisciplineStoreState['unlockedDisciplines'] => state.unlockedDisciplines,
    getUnlockedDisciplinesIds: (state: DisciplineStoreState) =>
      state.unlockedDisciplines
        .map(
          (
            discipline: AvailableDisciplinesApiResponse,
          ): AvailableDisciplinesApiResponse['games_disciplines_id'] =>
            discipline.games_disciplines_id,
        )
        .sort(),
    getUnlockedDisciplinesIdsUnsorted: (state: DisciplineStoreState) =>
      state.unlockedDisciplines.map(
        (
          discipline: AvailableDisciplinesApiResponse,
        ): AvailableDisciplinesApiResponse['games_disciplines_id'] =>
          discipline.games_disciplines_id,
      ),
    getFirstUnlockedDisciplineId: (
      state: DisciplineStoreState,
    ): AvailableDisciplinesApiResponse['games_disciplines_id'] =>
      state.unlockedDisciplines[0]?.games_disciplines_id,
    getDisciplinesUnlockConfig: (
      state: DisciplineStoreState,
    ): DisciplineStoreState['disciplinesUnlockConfig'] => state.disciplinesUnlockConfig,
    getDisciplineUnlockLevel:
      (state: DisciplineStoreState) =>
      (disciplineId: number): number => {
        // since both WSM and SSM disciplinesUnlockConfig response is indexed from 0,
        // requested SSM disciplines from 11 and higher would not fit with indexing with the old [disciplineId - 1]
        const fallbackValue = 9
        if (!disciplineId) return fallbackValue

        const disciplineIndex = state.currentGameDisciplinesIds.indexOf(disciplineId)
        if (disciplineIndex === -1) return fallbackValue
        return state.disciplinesUnlockConfig?.[disciplineIndex]?.level_required ?? 0
      },
    getDisciplinesMenuData: (
      state: DisciplineStoreState,
    ): DisciplineStoreState['disciplinesMenuData'] => state.disciplinesMenuData,
    getSpecificDiscipline: (
      state: DisciplineStoreState,
    ): DisciplineStoreState['specificDiscipline'] => state.specificDiscipline,
    getIsLoading: (state: DisciplineStoreState): DisciplineStoreState['isLoading'] =>
      state.isLoading,
    getCurrentGameDisciplinesIds: (state: DisciplineStoreState): number[] =>
      state.currentGameDisciplinesIds,
    getTrainerDisciplinesIds: (state: DisciplineStoreState): number[] =>
      state.disciplinesMenuData
        ?.filter((discipline: DisciplineMenuData): boolean => discipline.isTrainer === true)
        .map((discipline: DisciplineMenuData): number => discipline.id) ?? [],
  },
  actions: {
    async loadDisciplines(): Promise<void> {
      const availableDisciplines =
        await ApiService.post<AvailableDisciplinesApiResponse[]>(userDisciplinesEndpoint)
      this.unlockedDisciplines = availableDisciplines
      const allDisciplinesIds = await ApiService.get<number[]>(
        disciplinesByGameEndpoint + gameToken,
      )
      this.currentGameDisciplinesIds = allDisciplinesIds
    },
    async loadDisciplinesUnlockConfig(): Promise<void> {
      const config = await ApiService.post<DisciplineConfigResponse[], { keys: string }>(
        gamesConfigEndpoint,
        { keys: 'discipline_level_unlock_config' },
        { cache: true },
      )
      this.disciplinesUnlockConfig = config
    },
    async loadDisciplinesForMenu(
      type: string,
      force: boolean = false,
    ): Promise<DisciplineMenuData> {
      this.isLoading = true

      const response = await ApiService.get<DisciplineMenuDataResponse[]>(
        `${disciplineMenuEndpoint}/${type ?? ARENA}`,
        { force },
      )

      if (response == null) return

      const data = response.map((discipline: DisciplineMenuDataResponse): DisciplineMenuData => {
        return {
          id: discipline.discipline_id,
          locked: discipline.locked,
          stat: discipline.stat ?? 0,
          statTarget: discipline.stat_target ?? 0,
          completed: discipline.coach_training_completed ?? null,
          isTrainer: discipline.is_trainer ?? null,
          levelToUnlock: discipline.unlock_level ?? null,
          branchType: discipline.career_branch_type ?? null,
          careerId: discipline.career_id ?? null,
          isTracked: discipline.is_tracked ?? false,
          temporaryLock: discipline?.temporary_lock ?? false,
          completedQuests: discipline?.completed_quests ?? null,
          // temporary while we dont fix discipline menu
          lock: discipline.locked,
          specialTournament: discipline?.special_tournament ?? null,
        }
      })

      this.isLoading = false
      this.disciplinesMenuData = data
    },
    async loadSpecificDiscipline({
      disciplineId = null,
      type = TRAINING_HALL,
      isTrainer = false,
      isClubChampionship = false,
    }: {
      disciplineId?: number
      type?: string
      isTrainer?: boolean
      isClubChampionship?: boolean
    }): Promise<void> {
      const useUser = useUserStore()
      const supermatch = `?${MULTIPLIER}=${
        useUser.getActiveMultiplicator(
          isClubChampionship ? Multiplier.ClubChampionship : Multiplier.Arena,
        ).name
      }`
      const response = isClubChampionship
        ? await internalAxios.get<{}, SpecificDisciplineResponse>(
            `${userDisciplinesEndpoint}/clubs${supermatch}`,
          )
        : await internalAxios.get<{}, SpecificDisciplineResponse>(
            `${userDisciplinesEndpoint}/${type}/${disciplineId.toString()}${supermatch}`,
          )

      if (response == null) return

      let trainerData: TrainerDisciplineInterface

      if (type === TRAINING_HALL && isTrainer) {
        const trainerResponse = await internalAxios.get<{}, TrainerDisciplineResponse>(
          `${trainerDisciplinesEndpoint}/${disciplineId.toString()}`,
        )

        if (trainerResponse.slots && trainerResponse.slots.length) {
          const trainerDiscipline: TrainerDisciplineInterface = {
            id: trainerResponse.slots[0].discipline_id,
            slotId: trainerResponse.slots[0].slot_id,
            state: trainerResponse.slots[0].state,
            expiration: trainerResponse.expiration,
          }

          trainerData = trainerDiscipline
        }
      }

      const baseAttributes = response.user_attributes?.find(
        (item: SpecificDisciplineResponse['user_attributes'][0]): boolean => item.name === 'BASE',
      )
      const seasonalAttributes = response.user_attributes?.find(
        (item: SpecificDisciplineResponse['user_attributes'][0]): boolean =>
          item.name === 'SEASONAL',
      )
      const disciplineLevel = response.mechanic_stats?.find(
        (item: SpecificDisciplineResponse['mechanic_stats'][0]): boolean =>
          item.name === 'DISCIPLINE_LEVEL',
      )
      const bestScoreKey = isTrainer ? 'BEST_SCORE_TRAINER' : 'BEST_SCORE'
      const bestScore = response.mechanic_stats?.find(
        (item: SpecificDisciplineResponse['mechanic_stats'][0]): boolean =>
          item.name === bestScoreKey,
      )

      const discipline: FinalObject = {
        id: response.discipline_id,
        consumableCost: response.consumable_cost?.original ?? 0,
        consumableCostDiscounted: response.consumable_cost?.discounted ?? 0,
        buildingLimitReached: response.building_limit_reached ?? false,
        locked: response.locked ?? false,
        levelToUnlock: response.unlock_level ?? 0,
        mechanicStats: {
          disciplineLevel: disciplineLevel?.value ?? null,
          bestScore: bestScore?.value ?? 0,
        },
        userAttributes: {
          base: {
            value: baseAttributes?.value,
            maxValue: baseAttributes?.max_value,
          },
          seasonal: {
            value: seasonalAttributes?.value,
            maxValue: seasonalAttributes?.max_value,
          },
          baseSeasonal: {
            value: (baseAttributes?.value ?? 0) + (seasonalAttributes?.value ?? 0),
            maxValue: (baseAttributes?.max_value ?? 0) + (seasonalAttributes?.max_value ?? 0),
          },
        },
        userStats: {
          trainingPoints:
            response.user_stats?.find(
              (item: SpecificDisciplineResponse['user_stats'][0]): boolean =>
                item.name === 'TRAINING_POINTS',
            )?.value ?? null,
          rankingPoints:
            response.user_stats?.find(
              (item: SpecificDisciplineResponse['user_stats'][0]): boolean =>
                item.name === 'RANKING_POINTS',
            )?.value ?? null,
        },
        trainingPointsTarget: response.training_points_target,
        rewards: response.rewards ?? null,
        rewardsWithoutBenefit: response.rewards_without_benefit ?? null,
        isTutorialPassed: response.discipline_tutorial_passed ?? false,
        slotId: trainerData?.slotId ?? null,
        state: trainerData?.state ?? '',
        expiration: trainerData?.expiration ?? null,
      }

      this.setSpecificDiscipline(discipline)
    },
    setSpecificDiscipline(discipline: FinalObject): void {
      this.specificDiscipline = discipline
    },
    updateDisciplineAttribute(data: {
      currentTp: number
      targetTp: number
      attribute: number
      discipline: number
    }): void {
      // data from tasks
      const invalid = Object.entries(data).filter(
        ([key, value]: [string, number]): boolean =>
          typeof value !== 'number' ||
          !['currentTp', 'targetTp', 'attribute', 'discipline'].includes(key),
      ).length
      if (invalid) return

      const discipline = this.specificDiscipline
      // minus previous value, because discipline.attribute == base + seasonal
      const baseValue = discipline.userAttributes.base.value
      discipline.userAttributes.base.value = baseValue - baseValue + data.attribute
      discipline.userStats.trainingPoints = data.currentTp
      discipline.userAttributes.base.value = data.attribute
      discipline.trainingPointsTarget = data.targetTp
    },
  },
})
