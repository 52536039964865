// LOCALIZATION ENDPOINTS
export const languageTranslateEndpoint = 'language/translate/'
export const translationEndpoint = 'language/translation/'
export const countriesTranslateEndpoint = 'language/translate'
export const languagestranslationEndpoint = 'language/languages'
export const allTranslationsEndpoint = '/language/translations/'
// AUTH ENDPOINTS
export const nonAuthLanguagestranslationEndpoint = 'oauth/languages'
export const mobileTasksEndpoint = 'oauth/mobile/tasks'

// US ENDPOINTS
export const userDisciplinesEndpoint = 'us/disciplines/user'
export const userUpdateParameter = 'us/parameter/update/'
export const userUpdateParameterEndpoint = 'us/parameter/update/'
export const userDataEndpoint = 'us/stats/user'
export const userProfileEndpoint = 'us/profile'
export const userProfileUserEndpoint = 'us/profile/user'
export const userProfileUsernameCheckEndpoint = 'us/profile/username/check'
export const userProfileAvatars = 'us/profile/avatars'
export const userDisciplineStatsEndpoint = 'us/discipline/stats/'
export const userDisciplinesAttributesEndpoint = 'us/attributes/disciplines'
export const userMechanicsEndpoint = 'us/mechanics'
export const userDisciplineEndpoint = 'us/discipline/'
export const userSystemConfigEndpoint = 'us/system/config/user'
export const userBenefitsSlotsEndpoint = 'us/benefits/slots'
export const userParameterMapItemEndpoint = 'us/parameter/map-item'
export const userFastForwardExperienceEndpoint = 'us/fast-forward/experience'
export const disciplineMenuEndpoint = 'us/disciplines/user/discipline_list'
export const userLevelUpEndpoint = 'us/fast-forward/level'
export const userConsentEndpoint = 'us/consent'

// ARENA ENDPOINTS
export const arenaBuildingEndpoint = 'meta/buildings/type/arena'
export const arenaUserBuildingEndpoint = 'meta/buildings/user/arena'
export const arenaOpponentsEndpoint = 'arena/opponents/'
export const arenaNewOpponentsEndpoint = 'arena/opponents/discipline'
export const arenaHistoryEndpoint = 'arena/match/history'
export const arenaRankingEndpoint = 'arena/ranking/'
export const arenaMatchEndpoint = 'arena/match/'
export const arenaStatsDisciplineEndpoint = 'arena/stats/discipline/'
export const dailyLeagueStandingsEndpoint = 'arena/daily-league/standings'
export const dailyLeagueAthleteEndpoint = 'arena/athlete-of-the-day'
export const dailyLeagueDisciplinesEndpoint = 'arena/daily-league'
export const arenaRankingGlobalEndpoint = 'arena/ranking/top/100/global'
export const arenaRankingGlobalDisciplineEndpoint = 'arena/ranking/top/100/global/discipline'
export const arenaRankingRecordAllDisciplineEndpoint = 'arena/ranking/top/100/record/all/discipline'
export const arenaRankingRecordSeasonalDisciplineEndpoint =
  'arena/ranking/top/100/record/seasonal/discipline'
export const arenaRankingNationalEndpoint = 'arena/ranking/top/100/national'
export const arenaRankingRecordNationalEndpoint = 'arena/ranking/top/100/record/national'
export const arenaRankingClubsEndpoint = 'arena/ranking/top/100/club'
export const arenaBossFightPreFightOpponentsEndpoint = 'arena/boss-fight/pre-fight-opponents'

// TRAINING ENDPOINTS
export const trainingGradesEndpoint = '/meta/buildings/type/training_hall/'
export const trainingActualGradesEndpoint = '/meta/buildings/user/training_hall'
export const trainingUpgradeLevel = 'training_hall/grades'
export const trainingDisciplinesEndpoint = 'training_hall/disciplines'
export const trainingStatsEndpoint = 'training_hall/stats/disciplines/'
export const trainerDisciplinesEndpoint = 'training_hall/coach/slots'
export const trainingEndpoint = 'training_hall/training'
export const upgradeAttributeEndpoint = 'training_hall/attribute/upgrade'

// AUTH ENDPOINTS
export const passwordForgotEndpoint = 'oauth/password/forgot'
export const registerGoogleEndpoint = 'oauth/register/google/token'
export const signInWithGoogleEndpoint = 'oauth/register/google/callback'
export const registerFacebookEndpoint = 'oauth/register/facebook'
export const checkEmailRegisteredEndpoint = 'checkEmailRegistered/web'
export const connectedAccountsEndpoint = 'connections/web'
export const loginEndpoint = 'oauth/login'
export const registerEndpoint = 'oauth/register'
export const passwordResetEndpoint = 'oauth/password/reset'
export const tokenValidationEndpoint = 'oauth/is-token-valid/'
export const addLoginMethodEndpoint = 'addLoginMethod'
export const emailVerification = 'email-verification'

// DELETE ACC ENPOINTS

export const deleteRequest = 'us/account/request-delete'
export const cancelDeleteRequest = 'us/account/revert-delete'
export const deleteAccount = 'us/account'

// META ENDPOINTS
export const metaBuildingsEndpoint = 'meta/buildings/user'
export const metaBuildingsUpgradeEndpoint = 'meta/buildings/upgrade/'
export const metaBuildingsDowngradeEndpoint = 'meta/buildings/downgrade/'
export const logErrorEndpoint = 'meta/log/js'
export const sendBugReportMailEndpoint = 'meta/mail'
export const metaNotificationsEndpoint = 'meta/notifications'
export const metaPremiumPacksEndpoint = 'meta/premium/packs/gems/'
export const metaPremiumRetGemClaimEndpoint = 'meta/premium/gem_retention/claim'
export const metaPremiumPayEndpoint = 'meta/premium/pay'
export const metaPremiumExchangeEndpoint = 'meta/premium/exchange/'
export const metaPremiumPackEndpoint = 'meta/premium/pack/'
export const metaPremiumPackInfoEndpoint = 'meta/premium/pack/info/'
export const metaPremiumAdsEndpoint = 'meta/ads/status'
export const metaPremiumAdsClaimEndpoint = 'meta/ads/state'
export const metaPremiumPackOfferEndpoint = 'meta/premium/packs/offer'
export const metaPremiumPacksEndpointType = 'meta/premium/packs/'
export const metaNewsGetAllUserNewsEndpoint = 'meta/news/get-all-user-news/'
export const metaNewsUnreadEndpoint = 'meta/news/unread/'
export const metaNewsActionedEndpoint = 'meta/news/actioned/'
export const metaRightBoxesEndpoint = 'meta/premium/rightBoxes'
export const metaInterstitialsEndpoint = 'meta/interstitials'
export const metaPremiumWeeklyOffersEndpoint = 'meta/premium/packs/weekly'
export const metaPremiumRefreshBundle = 'meta/premium/bundle'
export const newStatusChampionshipBuilding = 'meta/buildings/open/club_championship'
export const openArenaEndpoint = 'meta/buildings/open/arena'
export const openTrainingHallEndpoint = 'meta/buildings/open/training_hall'
export const openPremiumEndpoint = 'meta/buildings/open/premium'
export const cookiesLogEndpoint = 'meta/cookies/log'
export const availableAdsEndpoint = 'meta/ads/available-ads/'
export const claimedAdTypeEndpoint = 'meta/ads/claimed-ad-type'
export const levelBagsEndpoint = 'meta/levelBags'
export const FlashNewsEndpoint = 'meta/flash_news'
export const FlashNewsClaimEndpoint = 'meta/flash_news/claim'
export const announcementsEndpoint = 'meta/announcements'

// CLUB ENDPOINTS
export const clubBuildingsEndpoint = 'club/buildings'
export const clubBuildingsEmployeesEndpoint = 'club/buildings/employees'
export const clubEmployeesHireEndpoint = 'club/employee/hire'
export const clubEndpoint = 'club'
export const clubDetailEndpoint = 'club/detail'
export const manageNotice = 'club/board'
export const clubsEndpoint = 'club/clubs'
export const joinClubEndpoint = 'club/members/requests/join'
export const invitePlayerToClubEndpoint = 'club/members/requests/invite'
export const availablePlayersEndpoint = 'club/members/prospects'
export const clubRequestsEndpoint = 'club/members/requests'
export const clubRequestDeclineAllEndpoint = 'club/members/requests/decline/all'
export const clubDeclineInvitationEndpoint = 'club/members/requests/invitation/decline/'
export const clubAcceptInvitationEndpoint = 'club/members/requests/invitation/accept'
export const clubRewards = 'club/rewards'
export const clubRewardsEmployeesEndpoint = 'club/rewards/employees'
export const clubRewardsBuildingEndpoint = 'club/rewards/building'
export const clubActivityEndpoint = '/club/activity'
export const clubChangeRoleEndpoint = 'club/members/role'
export const clubDeleteMemberEndpoint = 'club/members/dismiss'
export const clubMembersInfoEndpoint = 'club/members'
export const clubLeaveEndpoint = 'club/members/leave'
export const clubConfigEndpoint = 'club/config'
export const clubDailyTaskEndpoint = 'club/daily-task'
export const clubDailyTaskContributionsEndpoint = 'club/daily-task/contributions'
export const clubMembersNotifications = 'club/members/notifications'

export const clubChampionshipsLeaguesEndpoint = 'club/championships/leagues'
export const clubChampionshipsDetailEndpoint = 'club/championships/detail'

// GAME ENDPOINTS
export const disciplinesByGameEndpoint = 'games/disciplines/game/'
export const gameDisciplinesEndpoint = 'games/disciplines/'
export const rewardsEndpoint = 'games/rewards/'
export const gamesConfigEndpoint = 'games/config'
export const profileAllEndpoint = 'games/profile/all'
export const gameStateEndpoint = 'games/state'
export const mechanicsUnlocksEndpoint = 'games/mechanics/unlocks'

// HEADER ENDPOINTS
export const userHeaderEndpoint = 'us/parameters/user'

// CAREER ENDPOINTS
export const careerInfo = 'career'
export const careerQuests = 'career/quests/all'
export const claimRewards = 'career/quest/reward/claim'
export const careerDailyTasksEndpoint = 'career/daily-tasks'
export const careerNotificationsEndpoint = 'career/notifications'
export const careerGenerateNewDailyTaskEndpoint = 'career/daily-task/randomize'
export const careerDailyTasksClaimEndpoint = 'career/daily-tasks/claim'
export const careerQuestFastForwardEndpoint = 'career/quest/fast-forward'
export const careerTaskTrackerEndpoint = 'meta/task/tracker'
export const careerListOfUnlocksEndpoint = 'career/unlocks'
export const careerDetailEndpoint = 'career/detail'

// SHOP ENDPOINTS
export const grandPrizesEndPoint = 'shop/grandprize/grand_prizes/tier'
export const grandPrizesLastOpenedEndPoint = 'shop/grandprize/last'
export const grandPrizesClaimEndPoint = 'shop/grandprize/claim'
export const grandPrizeProgressesEndPoint = 'shop/grandprize/progress'
export const grandPrizeHistoryEndPoint = 'shop/grandprize/history'
export const grandPrizeRewardsEndPoint = 'shop/grandprize/grand_prizes/rewards'
export const grandPrizeEndPoint = 'shop/grandprize'
export const grandPrizeClaimEndPoint = 'shop/grandprize/claim'
export const grandPrizeShowEndPoint = 'shop/grandprize/show/'
export const grandPrizeInstantEndPoint = 'shop/grandprize/instant'
export const grandPrizeInfoEndPoint = 'shop/grandprize/tier/info'
export const grandPrizeSlotsUnlockEndPoint = 'shop/grandprize/slots/unlock'
export const shopItemEndPoint = 'shop/item/'
export const shopItemRepairEndPoint = 'shop/item/repair'
export const shopItemUpgradeEndPoint = 'shop/item/upgrade'
export const shopOfferEndPoint = 'shop/offer/'
export const shopEndPoint = 'shop'
export const shopOperationCheckEndPoint = 'shop/operation/check'
export const shopEquipmentShopEndPoint = 'shop/equipment_shop'
export const shopItemsEquipmentDisciplineEndPoint = 'shop/items/equipment/discipline'
export const shopItemsPremiumEquipmentDisciplineEndPoint = 'shop/items/premium_equipment/discipline'
export const shopAvailablePremiumItemEndPoint = 'shop/availablePremiumItem/'
export const shopOfferSpeedupEndPoint = 'shop/offer/speedup'
export const shopItemEquipEndPoint = 'shop/item/equip'
export const shopItemStatsEndpoint = 'shop/item/stats/'
export const shopItemSpendEndpoint = 'shop/item/spend'
export const shopItemsEndpoint = 'shop/items/'
export const shopBenefitsEndpoint = 'shop/benefits'
export const shopBenefitsConvertEndpoint = 'shop/benefits/convert'
export const shopPremiumEndpoint = 'shop/premium_shop'
export const shopPremiumClaimEndpoint = 'shop/premium/claim'
export const premiumAddonEndpoint = 'shop/premium_addon'
export const shopOffersEndpoint = 'shop/equipment_shop/discipline'
export const shopPremiumEquipmentProlong = 'shop/premium/prolong'

// TUTORIAL ENDPOINTS
export const tutorialEndPoint = 'us/tutorial'
export const allTutorialsEndPoint = 'meta/tutorials/all'
export const narrativeDialogues = 'meta/tutorials/actions/dialogues/'
export const initTriggerTutorialEndpoint = 'us/tutorial/init-trigger'

// REPORTS ENDPOINTS
export const reportsEndpoint = 'meta/reports/detail'
export const reportsCountEndpoint = 'meta/reports'
export const reportsRewardsEndpoint = 'meta/reports/rewards'
export const reportsRewardsClaimEndpoint = 'meta/reports/rewards/claim'

// RESEARCH LAB ENDPOINTS
export const researchCellEndpoint = '/research/'
export const researchInfoDisciplineEndpoint = '/research/discipline/'
export const researchInfoEconomicsEndpoint = '/research/economy'
export const researchInfoCampsEndpoint = '/research/camps'
export const researchInfoCellEndpoint = '/research/cell'
export const speedUpResearchEndpoint = '/research/speedup/'
export const researchStatusEndpoint = '/research/status'
export const researchApplyEndpoint = '/research/apply'
export const researchSeenEndpoint = '/research/cells/seen'

// MAIL ENDPOINTS
export const mailDetailEndpoint = 'meta/messages/detail/'
export const mailMarkUnreadEndpoint = 'meta/messages/mark/unread'
export const mailMarkReadEndpoint = 'meta/messages/mark/read'
export const mailReceivedEndpoint = 'meta/messages/received'
export const mailSentEndpoint = 'meta/messages/sent'
export const mailSendEndpoint = 'meta/messages/send'
export const mailForwardEndpoint = 'meta/messages/forward'
export const mailReplyEndpoint = 'meta/messages/reply'
export const mailBlockEndpoint = 'meta/messages/block'
export const mailBlockedEndpoint = 'meta/messages/blocked'
export const mailClubMessageEndpoint = 'club/members/message'

// MINIGAME ENDPOINTS
export const minigameTasksEndpoint = 'minigame/tasks'
export const minigameRevertDelete = 'minigame/revert-delete'

// EVENTS ENDPOINTS
export const boardGamesEventStateEndpoint = 'meta/boardgames/state'
export const eventInfo = 'meta/events/state/current'
export const getLuckyWheelConfig = 'meta/events/lucky_wheel/config'
export const getLuckyWheelRewards = 'meta/events/lucky_wheel/rewards'
export const getLuckyWheelState = 'meta/events/lucky_wheel/state'
export const spinLuckyWheel = 'meta/events/lucky_wheel/spin'
export const claimLuckyWheelReward = 'meta/events/lucky_wheel/claim'
export const milestonesConfig = 'meta/events/milestones/config'
export const milestonesState = 'meta/events/milestones/state'
export const milestonesTotalPointsEndpoint = 'meta/events/milestones/total_points'
export const clubContibutorMilestone = 'meta/events/milestones/club_contributions'
export const milestoneClubRewards = 'meta/events/milestones/club_rewards'
export const personalMilestoneClaimRewards = 'meta/events/milestones/claim'
export const dispatchGameLogin = 'meta/dispatch/game/login'
export const participateEvent = 'meta/events/masters_playoff/participate'
export const eventTournamentStatus = 'meta/events/masters_playoff/status'

export const teaserSummary = 'meta/events/teaser/summary'
export const taskchainConfig = 'meta/events/taskchain/config'
export const taskchainState = 'meta/events/taskchain/state'
export const taskchainSetTeam = 'meta/events/taskchain/signpost'
export const taskchainSkipTask = 'meta/events/taskchain/skip'
export const taskchainClaimRewards = 'meta/events/taskchain/claim'
export const taskchainRewardsSummary = 'meta/events/taskchain/summed_rewards'

export const mastersTextChange = 'meta/events/masters_playoff/replacement_texts'
export const eventMasterRankings = 'meta/events/masters_playoff/rankings'

export const playoffConfig = 'meta/events/playoff/config'
export const playoffState = 'meta/events/playoff/state'
export const playoffResults = 'meta/events/playoff/results'
export const playoffGroups = 'meta/events/playoff/groups'
export const playoffRewards = 'meta/events/playoff/rewards'

export const premiumEventShop = 'meta/premium/packs/event'
export const premiumEventPassClaimFreeResources = 'meta/events/event_pass/claim'
export const premiumEventPassInfo = 'meta/events/event_pass/info'

export const warehouseConfigEndpoint = 'meta/events/warehouse/config'
export const warehouseStateEndpoint = 'meta/events/warehouse/state'
export const warehouseBuyPackageEndpoint = 'meta/events/warehouse/buy_package'
export const warehouseBuildArtefactEndpoint = 'meta/events/warehouse/build'
export const warehouseMeltIngredientEndpoint = 'meta/events/converter/destroy'
export const warehouseCreateIngredientEndpoint = 'meta/events/converter/create'
export const warehouseClaimIngredientEndpoint = 'meta/events/converter/claim'
export const warehouseSkipProductionTimeEndpoint = 'meta/events/converter/skip'

export const eventLeagueConfigEndpoint = 'meta/events/league/config'
export const eventLeagueStateEndpoint = 'meta/events/league/state'
export const eventLeagueClaimEndpoint = 'meta/events/league/claim'
export const eventLeagueContributorsEndpoint = 'meta/events/league/contributors'
export const eventTotalRankingsEndpoint = 'meta/events/league/rankings'

export const bountyBoardConfigEndpoint = 'meta/events/bounty_board/config'
export const bountyBoardStateEndpoint = 'meta/events/bounty_board/state'
export const bountyBoardActivateTaskEndpoint = 'meta/events/bounty_board/activate'
export const bountyBoardDeactivateTaskEndpoint = 'meta/events/bounty_board/deactivate'
export const bountyBoardSkipTaskEndpoint = 'meta/events/bounty_board/skip'
export const bountyBoardClaimEndpoint = 'meta/events/bounty_board/claim'
export const bountyBoardResetTasksEndpoint = 'meta/events/bounty_board/reset_tasks'

export const fortuneBasketConfigEndpoint = 'meta/events/fortune_basket/config'
export const fortuneBasketStateEndpoint = 'meta/events/fortune_basket/state'
export const fortuneBasketClaimEndpoint = 'meta/events/fortune_basket/claim'
export const fortuneBasketCollectEndpoint = 'meta/events/fortune_basket/collect'
export const fortuneBasketShuffleEndpoint = 'meta/events/fortune_basket/shuffle'
export const fortuneBasketBuyTokensEndpoint = 'meta/events/fortune_basket/buy_tokens'

export const shootingRangeConfigEndpoint = 'meta/events/shooting_range/config'
export const shootingRangeStateEndpoint = 'meta/events/shooting_range/state'
export const shootingRangeBuyAmmoEndpoint = 'meta/events/shooting_range/buy_ammo'
export const shootingRangeShootEndpoint = 'meta/events/shooting_range/shoot'
export const shootingRangeClaimEndpoint = 'meta/events/shooting_range/claim'

// SEASONS
export const seasonEndpoint = 'meta/season'
export const seasonEndEndpoint = 'meta/season/summary'

// GAME PASS
export const gamePassConfigEndpoint = 'meta/gamePass'
export const gamePassStateEndpoint = 'meta/gamePass/user'
export const gamePassClaimMilestone = 'meta/gamePass/claimMilestone'
export const gamePassActiveEffects = 'meta/gamePass/activeEffects'

// PIGGY BANK
export const piggyBankConfigEndpoint = '/meta/piggyBank'
export const piggyBankStateEndpoint = 'meta/piggyBank/user'

// TOURNAMENTS
export const tournamentsCalendarStateEndpoint = 'tournaments/get-calendar'
export const tournamentsDetailoinEndpoint = 'tournaments/join-tournament/'
export const tournamentsDetailStateEndpoint = 'tournaments/get-tournament-detail/'
export const tournamentsDetailDisciplineStateEndpoint =
  'tournaments/get-discipline-tournament-detail/'
export const tournamentsHistoryStateEndpoint = 'tournaments/get-user-tournaments'
export const tournamentsMilestonesConfigEndpoint = 'tournaments/milestones'
export const tournamentsMilestonesStateEndpoint = 'tournaments/milestones/user'
export const tournamentsMilestonesClaimEndpoint = 'tournaments/milestones/claim'
export const tournamentsRankingsStateEndpoint = 'tournaments/rankings'
export const tournamentsActiveStateEndpoint = 'tournaments/get-user-tournaments/active'
