<template>
  <div class="calendar-empty w-full h-full flexing">
    <div class="trophies flex flex-col justify-center items-center space-y-8">
      <p class="message w-full flexing text-32 text-texts-standard-default">
        {{
          $replacePlaceholder(
            $t('tournaments.fullAcccessTooltip'),
            '%s',
            CAREER_TASK_UNLOCK.toString(),
          )
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { TOURNAMENTS_HISTORY_LIMIT } from '@/globalVariables'
import { defineComponent } from 'vue'

const CAREER_TASK_UNLOCK = 25

interface ComponentData {
  TOURNAMENTS_HISTORY_LIMIT: typeof TOURNAMENTS_HISTORY_LIMIT
  CAREER_TASK_UNLOCK: typeof CAREER_TASK_UNLOCK
}

export default defineComponent({
  data(): ComponentData {
    return {
      TOURNAMENTS_HISTORY_LIMIT,
      CAREER_TASK_UNLOCK,
    }
  },
})
</script>

<style lang="scss" scoped>
.calendar-empty {
  .trophies {
    width: 50.75rem;
    height: 40.313rem;
    @include background(url('#{$path-images}/tournaments/calendar/trophies.avif'), contain);

    .message {
      height: 7.5rem;

      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba($color: #144a73, $alpha: 0.7),
          rgba($color: #144a73, $alpha: 0.7),
          transparent
        );
      }
    }

    @if $isSsm {
      background-image: linear-gradient(to right, transparent, #56627f, #56627f, transparent);
    }
  }
}
</style>
