import TutorialInit from '@/data/tutorial/tutorialInit.ts'
import TutorialUnlockBenefits from '@/data/tutorial/tutorialUnlockBenefits.js'
import TutorialUnlockClubs from '@/data/tutorial/tutorialUnlockClubs.js'
import TutorialUnlockDailyTasks from '@/data/tutorial/tutorialUnlockDailyTasks.js'
import TutorialUnlockDiscipline1 from '@/data/tutorial/tutorialUnlockDiscipline1.ts'
import TutorialUnlockDiscipline2 from '@/data/tutorial/tutorialUnlockDiscipline2.ts'
import TutorialUnlockDiscipline3 from '@/data/tutorial/tutorialUnlockDiscipline3.ts'
import TutorialUnlockFirstClubJoin from '@/data/tutorial/tutorialUnlockFirstClubJoin.js'
import TutorialUnlockMapItems from '@/data/tutorial/tutorialUnlockMapItems.js'
import TutorialUnlockPersonalCoach from '@/data/tutorial/tutorialUnlockPersonalCoach.js'
import TutorialUnlockResearch from '@/data/tutorial/tutorialUnlockResearch.js'
import TutorialUnlockStarOfTheDay from '@/data/tutorial/tutorialUnlockStarOfTheDay.js'
import TutorialUnlockSuperCompetition from '@/data/tutorial/tutorialUnlockSuperCompetition.js'
import TutorialUnlockSpecialTournamentsIbu from './tutorialUnlockSpecialTournamentsIbu'
import TutorialUnlockTournaments from './tutorialUnlockTournaments'

export const tutorialClasses = {
  TutorialInit,
  TutorialUnlockDiscipline1,
  TutorialUnlockDiscipline2,
  TutorialUnlockDiscipline3,
  TutorialUnlockMapItems,
  TutorialUnlockDailyTasks,
  TutorialUnlockPersonalCoach,
  TutorialUnlockSuperCompetition,
  TutorialUnlockResearch,
  TutorialUnlockBenefits,
  TutorialUnlockStarOfTheDay,
  TutorialUnlockClubs,
  TutorialUnlockFirstClubJoin,
  TutorialUnlockSpecialTournamentsIbu,
  TutorialUnlockTournaments,
}
