<template>
  <div v-if="loaded" class="bounty-board-container flexing w-full h-full">
    <event-message v-if="eventCollectionActive" :event-ended="true" />
    <div v-else class="flex flex-col w-full h-full">
      <div class="bounty-board-header">
        <div class="bounty-board-header-description flex items-center">
          <info-text :text="$te('noticeBoardCTA')" />
          <super-multiplier
            v-if="!isActiveTask"
            :multiplier-type="Multiplier.AutumnFair"
            @switched-event-multiplier="loadState"
          />
          <span
            v-else
            class="selected-multiplier flex justify-end gap-5 items-center text-30 text-texts-standard-default"
          >
            Selected multiplier:
            <span class="text-texts-standard-important text-38 font-bold">
              {{ `${activeMultiplier.value}x` }}
            </span>
          </span>
          <tippy theme="WSM" placement="bottom" max-width="50rem">
            <app-icon icon-name="info-50 ml-4" />
            <template #content>
              <div class="p-2">
                <p class="text-center">{{ $te('taskMultiplierInfo') }}</p>
                <p class="mt-4 text-left">
                  {{ $replacePlaceholder($te('taskMultiplierInfo2'), '{pass}', $te('eventpass')) }}
                </p>
                <ul class="list-disc ml-8 text-left">
                  <li v-for="(tier, index) in multipliersTooltipInfo" :key="index">
                    Tier {{ tier.tier }}:
                    {{
                      tier.multipliers
                        .map((multiplier: number): string => `${multiplier}x`)
                        .join(', ')
                    }}
                  </li>
                </ul>
              </div>
            </template>
          </tippy>
        </div>
      </div>
      <div
        class="bounty-board-main flex-col flexing"
        :class="{ 'bounty-board-main--grid': !isActiveTask }"
      >
        <div v-if="isActiveTask" class="flex justify-between w-full">
          <app-button
            btn-type="danger"
            :btn-text="$te('cancelTask')"
            :disabled="activeTask.reward_ready"
            @click="showDeactivatePopup = true"
          />
          <info-popup
            v-if="showDeactivatePopup"
            :popup-title="$te('cancelTask')"
            @close="showDeactivatePopup = false"
          >
            <div class="deactivate-task-popup">
              <p class="text-34 text-texts-standard-default">{{ $te('cancelTaskConfirm') }}</p>
              <app-button
                btn-type="danger"
                :btn-text="$te('cancelTask')"
                @click="$debounce(handleTaskDeactivation)"
              />
            </div>
          </info-popup>
          <app-multi-button1
            btn-type="credit"
            :btn-text="$te('finishNow')"
            :btn-count="skipPrice"
            :disabled="activeTask.reward_ready"
            :popup-data="{
              title: $te('actualTaskFinish'),
              btnText: $te('finishNow'),
              btnType: 'confirm',
              btnValue: skipPrice,
              btnId: 'finish-task-now-id',
              text: $te('confirmActualTaskFinish'),
            }"
            @action="$debounce(skipTask, [activeTask.id_task])"
          />
        </div>
        <div
          v-if="!isActiveTask"
          class="bounty-board-main__content bounty-board-main__content--grid"
        >
          <bounty-board-task
            v-for="task in tasks"
            :key="task.id"
            :task="task"
            @activate-task="activateTask"
          />
        </div>
        <div v-else class="bounty-board-main__content bounty-board-main__content--detail">
          <bounty-board-task :task="activeTask" />
        </div>
      </div>
      <div class="bounty-board-footer flexing gap-20">
        <app-multi-button1
          v-if="!isActiveTask"
          btn-type="credit"
          :btn-text="$te('newTasks')"
          :btn-count="config?.reset_price?.count"
          :popup-data="{
            title: $te('newTasks'),
            btnText: $te('refreshTasks'),
            btnType: 'confirm',
            btnValue: config?.reset_price?.count,
            btnId: 'new-board-tasks-id',
            text: $te('newTasksConfirm'),
          }"
          @action="$debounce(resetTasks)"
        />
        <div class="flexing">
          <span class="flex text-32 leading-8 text-texts-standard-important text-wrap text-right">
            {{ $te('tasksCompleted') }}
          </span>
          <bounty-board-milestone class="ml-12" :milestones="milestones" />
        </div>
      </div>
    </div>
  </div>
  <component-loading v-else :is-loading="true" height="100%" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { Multiplier } from '@/globalVariables'
import InfoText from '@/components/InfoText.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import BountyBoardTask from '@/components/Events/BoardComponents/BountyBoard/BountyBoardTask.vue'
import BountyBoardMilestone from '@/components/Events/BoardComponents/BountyBoard/BountyBoardMilestone.vue'
import SuperMultiplier from '@/components/GamePass/SuperMultiplier.vue'
import EventMessage from '@/components/Events/EventMessage.vue'
import { useTaskBoardStore } from '@/store/pinia/events/taskBoardStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'

import type { MultiplicatorItem } from '@/types/userProfile'
import type { BoardTask } from '@/interfaces/responses/events/task-board/TaskBoardStateApiResponse'
import type { EventMultiplier } from '@/interfaces/responses/events/task-board/TaskBoardConfigApiResponse'

interface ComponentData {
  Multiplier: typeof Multiplier
  loaded: boolean
  showDeactivatePopup: boolean
}

export default defineComponent({
  name: 'BountyBoardMain',
  components: {
    InfoText,
    InfoPopup,
    BountyBoardTask,
    SuperMultiplier,
    BountyBoardMilestone,
    EventMessage,
  },
  data(): ComponentData {
    return {
      Multiplier,
      loaded: false,
      showDeactivatePopup: false,
    }
  },
  computed: {
    ...mapState(useTaskBoardStore, {
      config: 'getConfig',
      tasks: 'getTasks',
      milestones: 'getCompetitionMilestones',
    }),
    ...mapState(useUserStore, {
      getSuperMultipliers: 'getSuperMultipliers',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
    activeTask(): BoardTask {
      const tasks = Object.values(this.tasks)
      return tasks?.find((task: BoardTask): boolean => !!task.active)
    },
    activeMultiplier(): MultiplicatorItem {
      const autumnMultipliers = this.getSuperMultipliers[Multiplier.AutumnFair]
      return autumnMultipliers?.find((multiplier: MultiplicatorItem): boolean => multiplier.active)
    },
    isActiveTask(): boolean {
      return !!this.activeTask
    },
    skipPrice(): number {
      return this.activeTask?.skip_price?.count ?? 0
    },
    multipliersTooltipInfo(): EventMultiplier[] {
      return this.config?.multipliers_tooltip_info
    },
  },
  async created(): Promise<void> {
    if (this.eventCollectionActive) {
      this.loaded = true
      return
    }
    await Promise.all([this.loadConfig(), this.loadState()])
    this.loaded = true
  },
  methods: {
    ...mapActions(useTaskBoardStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
      activateTask: 'activateTask',
      deactivateTask: 'deactivateTask',
      skipTask: 'skipTask',
      resetTasks: 'resetTasks',
    }),
    handleTaskDeactivation(): void {
      this.deactivateTask()
      this.showDeactivatePopup = false
    },
  },
})
</script>

<style lang="scss" scoped>
.bounty-board-header-description {
  position: relative;
  width: 100%;
  height: 5.813rem;
  margin: 0.25rem 0 1.5rem;
  padding: 0 2rem;
  background: linear-gradient(
    to right,
    rgba(38, 48, 77, 0),
    rgba(38, 48, 77, 1),
    rgba(38, 48, 77, 1),
    rgba(38, 48, 77, 0),
    rgba(38, 48, 77, 0),
    rgba(38, 48, 77, 1),
    rgba(38, 48, 77, 1),
    rgba(38, 48, 77, 0)
  );

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.125rem;
    background: linear-gradient(
      to right,
      rgba(162, 209, 255, 0),
      rgba(162, 209, 255, 1),
      rgba(162, 209, 255, 1),
      rgba(162, 209, 255, 0),
      rgba(162, 209, 255, 0),
      rgba(162, 209, 255, 1),
      rgba(162, 209, 255, 1),
      rgba(162, 209, 255, 0)
    );
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  .selected-multiplier {
    width: 35rem;
  }
}

.bounty-board-main {
  flex: 1 1 auto;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &--grid {
      flex: 0 1 auto;
    }

    &--detail {
      flex: 1 1 auto;
    }
  }

  &--grid {
    .bounty-board-main__content {
      display: grid;
      width: 114.814rem;
      margin: 0 auto;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.438rem 3.438rem;
    }
  }
}

.bounty-board-footer {
  margin-top: 2.875rem;
}

.deactivate-task-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 3.5rem 0.75rem 0.75rem;

  .btn {
    width: 100%;
  }
}
</style>
