import type { BuildingSponsorBadgeInterface } from '@/map-phaser-new/interfaces'

export class BuildingSponsorBadge {
  private sponsorBadge: Phaser.GameObjects.Image

  constructor(
    private positionX: number,
    private positionY: number,
  ) {}

  public addToBuilding(activeScene: Phaser.Scene, config: BuildingSponsorBadgeInterface): void {
    this.createSponsorBadge(activeScene, config)
  }

  private createSponsorBadge(
    activeScene: Phaser.Scene,
    config: BuildingSponsorBadgeInterface,
  ): void {
    this.sponsorBadge = activeScene.add
      .image(this.positionX, this.positionY, config.textureName)
      .setOrigin(config.originX)
      .setDepth(config.depth)
  }

  public removeSponsorBadge(): void {
    this.sponsorBadge.destroy()
    this.sponsorBadge = null
  }
}
