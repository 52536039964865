<template>
  <div
    class="report-rewards-tooltip w-full h-full relative"
    :class="`report-rewards-tooltip--${$isMobile() ? 'mobile' : 'desktop'}`"
  >
    <div class="report-rewards-tooltip-title w-full relative flexing">
      <p class="text-texts-standard-default font-bold text-34">
        {{ $t('reports.otherRewards') }}
      </p>
    </div>
    <div class="report-rewards-tooltip-rewards flexing">
      <div v-for="(reward, i) in rewards" :key="i">
        <!-- Note: Original reward size was 72, but here it has been reduced to 48 (WEB) due to tooltip not scaling -->
        <reward-box v-if="avatarReward(reward)" :reward-data="reward" />
        <reward-box
          v-else
          :key="i"
          :reward-icon-size="$isMobile() ? 72 : 48"
          :reward-icon-name="getIconName(reward)"
          :reward-number="reward.value"
          class="pt-3"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { REWARD_AVATAR_TYPES } from '@/globalVariables'
import type Reward from '@/interfaces/Reward'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'ReportRewardsTooltip',
  props: {
    rewards: {
      type: Array as PropType<Reward[]>,
      default: () => [],
    },
  },
  methods: {
    getIconName(reward: Reward): string {
      if (reward.name === 'grandPrize') return 'gp-' + reward.rarity
      return reward.name
    },
    avatarReward(rewardData: Reward): boolean {
      return REWARD_AVATAR_TYPES.includes(rewardData?.name)
    },
  },
})
</script>

<style lang="scss" scoped>
.report-rewards-tooltip {
  &-title {
    height: 3.125rem;
    top: -1.525rem;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      #236d9b,
      rgba(255, 255, 255, 0)
    );
  }

  :deep() {
    .reward-box {
      margin: 0 0.375rem;
    }
  }

  // NOTE: Only web with scale must have this resize
  &--desktop {
    :deep() {
      // In main rewards row
      .reward-box {
        // 0.72 = default scale
        width: calc(6.938rem * 0.72);
        height: calc(8.5rem * 0.72);

        .reward-box-text {
          font-size: calc(1.75rem * 0.72);
          margin-top: 0.25rem;
        }
      }

      .avatar-box {
        width: 4rem !important;
        height: 4rem !important;
        @apply items-center justify-center flex;

        .avatar-box-background,
        .avatar-box-image {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }
}
</style>
