<template>
  <div v-if="loaded" class="shooting-range flex flex-col w-full h-full">
    <div class="shooting-range__header flexing">
      <info-text :text="$te('shootingRangeCTA')" />

      <div class="menu-info-box flex justify-between items-center">
        <tippy theme="WSM1" placement="top" max-width="42.563rem">
          <template #content>
            <p class="p-3">{{ $te('gunpowderBuyInfo') }}</p>
          </template>
          <app-icon icon-name="info-50" class="revert-skew click-revert" />
        </tippy>
        <aside class="flexing">
          <div
            class="menu-info-box-text mr-3 text-texts-standard-default text-36 font-bold revert-skew"
          >
            <span class="text-texts-standard-important">
              {{ $filters.$formatNumber(gunpowder) }}/</span
            >
            <span>{{ $filters.$formatNumber(potentialGunpowder) }}</span>
          </div>
          <app-main-icon
            :icon-size="56"
            icon-name="gunpowder"
            class="menu-info-box-icon revert-skew"
          />
        </aside>
      </div>
      <app-button
        class="shooting-range__add-button ml-6"
        btn-type="secondary"
        @click="showPurchasePopup"
      >
        <span class="text-60 font-bold revert-skew">+</span>
      </app-button>
      <popup-window
        v-if="isPurchase"
        popup-type="info"
        :popup-title="$te('gunpowder')"
        @close="closePurchasePopup"
      >
        <div class="popup-box-content flexing flex-col">
          <p class="text-texts-standard-default text-36 mt-16 px-10">
            {{ $te('gunpowderBuyInfo') }}
          </p>
          <div
            class="add-supplement m-auto flex items-center text-60 font-bold relative"
            :class="{
              'opacity-50 pointer-events-none': false,
            }"
          >
            <div
              class="text-texts-standard-dark minus flexing cursor-pointer z-2"
              :class="{ 'opacity-50 pointer-events-none': selectedAmount <= 1 }"
              @click="countDownNumber"
            >
              <span :class="{ 'revert-skew': $isWsm }"> - </span>
            </div>
            <div class="number-supplement text-texts-standard-default flexing">
              <span :class="{ 'revert-skew': $isWsm }">
                {{ `${selectedAmount}/${purchasableGunpowder}` }}
              </span>
              <app-main-icon :icon-size="56" :icon-name="'gunpowder'" />
            </div>
            <div
              v-show="showAddingAnimation"
              class="adding-animation absolute text-texts-standard-positive flexing"
            >
              <span :class="{ 'revert-skew': $isWsm }"> +{{ purchasedAmount }} </span>
              <app-main-icon :icon-size="56" :icon-name="'gunpowder'" />
            </div>
            <div
              class="text-texts-standard-dark plus flexing cursor-pointer z-2"
              :class="{
                ['opacity-50 pointer-events-none']: selectedAmount >= purchasableGunpowder,
              }"
              @click="addNumber"
            >
              <span :class="{ 'revert-skew': $isWsm }">+</span>
            </div>
          </div>
          <app-multi-button1
            :btn-id="'add-parameter-' + 'gunpowder' + '-by-gems-id'"
            btn-type="credit"
            :btn-text="$t('button.useGems')"
            :btn-count="gemsToUse"
            :disabled="gemsToUse < 1 || selectedAmount > purchasableGunpowder"
            :popup-data="
              $filters.$confirmPopupData({
                title: $te('gunpowder'),
                text: $te('gunpowderBuyConfirm'),
                btnId: 'add-parameter-by-gems-confirm-id',
                btnText: $te('buyButton'),
                btnValue: gemsToUse,
              })
            "
            @enough-resources-btn-click="showGemConfirm = true"
            @redirect-close="isPurchase = false"
            @action="buyAmmoHandler"
          />
        </div>
      </popup-window>
    </div>
    <div
      class="shooting-range__content flex"
      :class="{ 'shooting-range__content--mobile': $isMobile() }"
    >
      <div class="shooting-range__content-left flex flex-auto items-center">
        <div class="flex-auto">
          <div class="shooting-range__row-reward-label flexing text-30 text-texts-standard-default">
            {{ $te('rowReward') }}
          </div>
          <div class="shooting-range__rows flex flex-col gap-6">
            <shooting-range-row v-for="(row, index) in range" :key="index" :row-data="row" />
          </div>
        </div>
      </div>
      <div class="shooting-range__content-right flexing flex-col flex-auto">
        <div class="shooting-range__main-reward flex flex-col">
          <div
            class="shooting-range__main-reward-header flexing text-texts-standard-default text-32 font-bold"
          >
            {{ $te('mainReward') }}
          </div>
          <div class="shooting-range__main-reward-info flex flex-col justify-between">
            <div class="shooting-range__main-reward-info-box flex justify-end py-3 px-3.5">
              <tippy theme="WSM1" placement="left" max-width="75rem">
                <template #content>
                  <div class="reward-info-box-tooltip flex flex-col">
                    <p
                      class="heading-ribbon flexing text-texts-standard-important text-36 font-bold uppercase"
                    >
                      {{ $te('targetRewards') }}
                    </p>
                    <div class="flex flex-wrap items-center justify-center gap-2">
                      <reward-box
                        v-for="(target, index) in targetRewards"
                        :key="index"
                        :reward-icon="56"
                        :reward-icon-name="target.reward.type"
                        :reward-number="target.reward.value"
                        :is-claimed="target.claimed"
                      />
                    </div>
                    <p class="text-texts-standard-default mt-8 mb-6 text-30">
                      {{ $te('equalDropRate') }}
                    </p>
                    <p
                      class="heading-ribbon flexing text-texts-standard-important text-36 font-bold uppercase"
                    >
                      {{ $te('mainReward') }}
                    </p>
                    <div class="flex flex-wrap items-center justify-center gap-2">
                      <reward-box
                        v-for="(reward, index) in getChestRewards"
                        :key="index"
                        :reward-data="reward"
                        :reward-icon="56"
                        :reward-icon-name="reward.type"
                        :reward-number="reward.value"
                        :sex="sex"
                      />
                    </div>
                  </div>
                </template>
                <app-icon icon-name="info-50" />
              </tippy>
            </div>
            <div class="shooting-range__main-reward-info-chest" />
            <div>
              <p class="text-texts-standard-default pt-6 text-30 leading-7">Shoot all targets</p>
              <div class="pt-2.5 pb-3 flexing">
                <div class="text-36 font-bold text-texts-standard-default mr-4">
                  <span class="text-texts-standard-upgrade">{{ completedTargets }}</span
                  ><span>/{{ totalTargets }}</span>
                </div>
                <div class="reward-box-target-icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mt-6">
          <div v-if="completedTargets < totalTargets">
            <tippy theme="WSM1" placement="top" max-width="42.563rem">
              <template #content>
                <div class="info-box-tooltip text-left p-6 gap-6 flex flex-col">
                  <p class="text-texts-standard-default text-30">
                    {{ $te('gunPowderInfo1') }}
                  </p>
                  <p class="text-texts-standard-default text-30">
                    {{ $replacePlaceholder($te('gunPowderInfo2'), '{2to4}', destroyTargetCost) }}
                  </p>
                  <p class="text-texts-standard-default text-30">
                    {{ $te('gunPowderInfo3') }}
                  </p>
                </div>
              </template>
              <app-multi-button1
                class="shooting-range__shoot-button"
                btn-theme="secondary"
                :btn-text="$te('shoot')"
                :btn-count="destroyTargetCost"
                :disabled="destroyTargetCost > gunpowder"
                :is-resources-status-colored="false"
                @click="$debounce(shoot)"
              >
                <template #rightPart>
                  <app-main-icon :icon-size="48" icon-name="gunpowder" />
                </template>
              </app-multi-button1>
            </tippy>
          </div>

          <div v-else>
            <app-button
              v-if="isChestClaimable && !isChestClaimed"
              btn-type="confirm"
              class="shooting-range__claim-button"
              :btn-text="$te('openChest')"
              :notifications-count="1"
              force-notification
              @click="claim('chest', 1)"
            />
            <div
              v-else
              class="shooting-range__claimed-text flexing text-36 font-bold text-texts-standard-default"
            >
              <shrink-text
                :text="$te('rewardClaimed2')"
                :font-size="28"
                container-class-list="flexing pt-1.5"
                text-class-list="uppercase"
                :animated-overflow="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <component-loading v-else :is-loading="true" height="100%" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import ShootingRangeRow from '@/components/Events/CarnivalStandComponents/ShootingRange/ShootingRangeRow.vue'
import InfoText from '@/components/InfoText.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import ShrinkText from '@/components/ShrinkText.vue'

import { useShootingRangeStore } from '@/store/pinia/events/shootingRangeStore'
import type Reward from '@/interfaces/Reward'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'

interface ComponentData {
  loaded: boolean
  isPurchase: boolean
  gemsToUse: number
  consumableToUse: number
  selectedAmount: number
  purchasedAmount: number
  showGemConfirm: boolean
  showAddingAnimation: boolean
}

export default defineComponent({
  name: 'ShootingRangeMain',
  components: {
    InfoText,
    ShootingRangeRow,
    PopupWindow,
    ShrinkText,
  },
  props: {
    totalSpecialConsumables: {
      type: Number,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      loaded: false,
      isPurchase: false,
      gemsToUse: 0,
      consumableToUse: 0,
      selectedAmount: 0,
      purchasedAmount: 0,
      showGemConfirm: false,
      showAddingAnimation: false,
    }
  },
  computed: {
    ...mapState(useShootingRangeStore, {
      gunpowder: 'getGunpowder',
      purchasableGunpowder: 'getPurchasableGunpowder',
      potentialGunpowder: 'getPotentialGunpowder',
      chest: 'getChest',
      range: 'getRange',
      targetRewards: 'getTargetRewards',
      completedTargets: 'getCompletedTargets',
      totalTargets: 'getTotalTargets',
      destroyTargetCost: 'getDestroyTargetCost',
      valuePerConsumable: 'getValuePerConsumable',
      gemValuePerConsumable: 'getGemValuePerConsumable',
    }),
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
    }),
    ...mapState(useUserStore, {
      sex: 'getSex',
    }),
    getChestRewards(): Reward[] {
      return this.chest?.rewards
    },
    isChestClaimable(): boolean {
      return this.chest?.claimable
    },
    isChestClaimed(): boolean {
      return this.chest?.claimed
    },
  },
  async created(): Promise<void> {
    await Promise.all([this.loadConfig(), this.loadState()])
    this.loaded = true
  },
  methods: {
    ...mapActions(useShootingRangeStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
      buyAmmo: 'buyAmmo',
      shoot: 'shoot',
      claim: 'claim',
    }),
    countDownNumber(): void {
      if (this.selectedAmount > 0) {
        this.selectedAmount -= this.valuePerConsumable
        this.gemsToUse -= this.gemValuePerConsumable
        this.consumableToUse -= 1
      }
    },
    addNumber(): void {
      if (this.gems.value > this.gemsToUse || this.totalSpecialConsumables > this.consumableToUse) {
        // pokial nemame dostatok zakupitelnych consumable
        if (this.purchasableGunpowder < 1) return
        this.selectedAmount += this.valuePerConsumable
        this.gemsToUse += this.gemValuePerConsumable
        this.consumableToUse += 1
      }
    },
    triggerAddingAnimation(): void {
      this.showAddingAnimation = true
      setTimeout((): void => {
        this.showAddingAnimation = false
      }, 1500)
    },
    async buyAmmoHandler(): Promise<void> {
      this.purchasedAmount = this.selectedAmount
      await this.buyAmmo(this.selectedAmount)
      this.triggerAddingAnimation()
      this.showGemConfirm = false
      // It is necessary to display the reset purchase popup to continue
      this.resetPurchasePopup()
      this.addNumber()
    },
    showPurchasePopup(): void {
      this.isPurchase = true
      this.addNumber()
    },
    closePurchasePopup(): void {
      this.isPurchase = false
      this.resetPurchasePopup()
    },
    resetPurchasePopup(): void {
      this.selectedAmount = 0
      this.gemsToUse = 0
      this.consumableToUse = 0
    },
  },
})
</script>

<style lang="scss" scoped>
.shooting-range {
  position: relative;
  width: 98%;
  margin: 0 auto;

  &__header {
    position: relative;
    width: 100%;
    height: 5.813rem;
    margin: 0.25rem 0 1.5rem;
    padding-left: 2rem;
    background: linear-gradient(
      to right,
      rgba(38, 48, 77, 0),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 0)
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(
        to right,
        rgba(162, 209, 255, 0),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 0)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .menu-info-box {
      min-width: 16.875rem;
      height: 4.375rem;
      transform: $skewX-value;
      border-radius: 0.125rem;
      padding: 0.5rem;

      @if $isWsm {
        background-color: rgb(15, 50, 81);
        border: 0.125rem solid #f4d91e;
        box-shadow:
          inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
          inset -0.019625rem -0.1865rem 2.5rem 0 rgba(6, 34, 55, 0.48);
      }

      @if $isSsm {
        background-color: #363e5d;
        border-style: solid;
        border-width: 0.125rem;
        border-image-source: linear-gradient(to right, #f6c717, #fff284);
        border-image-slice: 1;
        box-shadow:
          inset -3px 0px 40px 0 rgba(6, 34, 55, 0.48),
          inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
      }
    }
  }

  &__content {
    gap: 4.25rem;
    flex: 1 1 auto;

    &--mobile {
      gap: 11.25rem;
    }

    &-left {
      flex: 1 1 auto;
    }

    &-right {
      position: relative;
      width: 22.5rem;
      max-width: 22.5rem;
    }
  }

  &__row {
    position: relative;
    gap: 7.25rem;

    &-reward {
      position: relative;

      &-label {
        position: relative;
        width: 24rem;
        height: 3.188rem;
        background-image: linear-gradient(
          to right,
          rgba(14, 116, 105, 0),
          rgba(14, 116, 105, 1),
          rgba(14, 116, 105, 1),
          rgba(14, 116, 105, 0)
        );

        &::before,
        &::after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 0.125rem;
          background-image: linear-gradient(
            to right,
            rgba(14, 231, 204, 0.01),
            rgba(14, 231, 204, 1),
            rgba(14, 231, 204, 0.01)
          );
        }

        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }
      }
    }
  }

  &__main-reward {
    width: 22.5rem;
    height: 32.25rem;
    background: url($path-events + 'autumn-fair/shooting-range/box-reward-bg.avif') no-repeat center;
    background-size: contain;

    &-header {
      position: relative;
      height: 5rem;
    }

    &-info {
      flex: 1 1 auto;

      &-chest {
        background: url($path-events + 'autumn-fair/shooting-range/event-chest.avif') no-repeat
          center;
        background-size: contain;
        width: 22rem;
        height: 14.75rem;
        margin: 0 auto;
      }
    }
  }

  &__shoot-button,
  &__claim-button {
    width: 100%;
  }

  &__claimed-text {
    position: relative;
    width: 100%;
    height: 5rem;
    background-image: linear-gradient(
      to right,
      rgba(14, 116, 105, 0),
      rgba(14, 116, 105, 1),
      rgba(14, 116, 105, 1),
      rgba(14, 116, 105, 0)
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background-image: linear-gradient(
        to right,
        rgba(14, 231, 204, 0.01),
        rgba(14, 231, 204, 1),
        rgba(14, 231, 204, 0.01)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__add-button {
    width: 4.375rem;
    height: 4.375rem;
    min-width: 4.375rem;
  }

  &__rows {
    padding-left: 2rem;
  }
}

.reward-box-target-icon {
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  background: url($path-events + 'autumn-fair/shooting-range/target.avif') no-repeat center;
  background-size: contain;
}

.reward-info-box-tooltip {
  padding: 2rem;

  .heading-ribbon {
    position: relative;
    width: 62.5rem;
    height: 4.625rem;
    margin: 0 auto 1.5rem;
    padding-left: 2rem;
    background: linear-gradient(
      to right,
      rgba(38, 48, 77, 0),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 0)
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(
        to right,
        rgba(162, 209, 255, 0),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 0)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  .reward-box {
    width: 5.625rem;
    height: 6.875rem;
    margin: 0;
  }
}

.popup-box-content {
  width: 66.25rem;
  padding: 0.875rem 1.875rem;
  gap: 4.375rem;

  .add-supplement {
    position: relative;
    width: 28rem;
    height: 5rem;

    .minus,
    .plus {
      position: absolute;
      width: 4.375rem;
      height: 4.375rem;
      transform: $skewX-value;

      @if $isWsm {
        background-image: linear-gradient(
          to top,
          rgba(154, 231, 255, 0.7),
          rgba(204, 239, 255, 0.7),
          #c6e9ff
        );
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #6f7da6, #c6cfff);
        border: solid 0.125rem #d1d9ff;
      }

      &:active {
        transform: scale(0.95) $skewX-value;
      }
    }

    .minus {
      left: 0;
    }

    .plus {
      right: 0;
    }

    .number-supplement {
      width: 100%;
      height: 100%;
      transform: $skewX-value;
      padding: 0 4.375rem;

      @if $isWsm {
        background-color: rgb(37, 89, 126);
        box-shadow:
          inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
          inset -0.019625rem -0.1865rem 0.25rem 0 rgba(125, 190, 239, 0.48);
        text-shadow: 0.0401875rem 0.047875rem 0.058125rem rgba(0, 0, 0, 0.71);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, transparent, #636790, #636790, transparent);
        text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
      }
    }

    .adding-animation {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: $skewX-value;
      background: linear-gradient(to right, rgba(12, 30, 54, 0), #0c1e36, rgba(12, 30, 54, 0));
      text-shadow: 0.0401875rem 0.047875rem 0.058125rem rgba(0, 0, 0, 0.71);
      animation: fadeOut 1.5s;

      @keyframes fadeOut {
        0% {
          top: 1rem;
          opacity: 1;
        }

        100% {
          top: -10rem;
          opacity: 0;
        }
      }
    }
  }

  .btn {
    width: 100%;
  }
}
</style>
