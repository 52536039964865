<template>
  <div class="carnival-stand-container w-full h-full safe-area">
    <div class="carnival-stand w-full h-full flex flex-col">
      <div class="carnival-stand__header">
        <carnival-stand-header
          @set-section="setCarnivalStandComponent"
          @show-popup="showPopup = true"
        />
      </div>
      <div class="carnival-stand__content">
        <component :is="carnivalComponent" v-if="!isLoading" />
        <component-loading :is-loading="isLoading" />
      </div>

      <info-popup
        v-if="showPopup"
        :popup-title="$te('carnival')"
        class="carnival-stand-popup"
        width="76rem"
        @close="showPopup = false"
      >
        <div class="carnival-stand-popup__content">
          <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
            <section
              class="carnival-stand-popup-content pt-6 flex flex-col text-32 text-texts-standard-default"
            >
              <div class="carnival-stand-popup-about-title flexing">
                <span class="pt-1.5 text-50 font-bold gradient-text-gold uppercase">
                  {{ $te('pumpkinPatch') }}
                </span>
              </div>
              <ul class="carnival-stand-popup-feature-text-list">
                <li
                  v-for="text in fortuneBasketTexts"
                  :key="text"
                  class="text-32 mb-8 carnival-stand-popup-content-text text-left relative"
                >
                  {{ $replacePlaceholder($replacePlaceholder($te(text), '%s', 10), '{2to4}', 4) }}
                </li>
              </ul>
              <div class="carnival-stand-popup-about-title flexing">
                <span class="pt-1.5 text-50 font-bold gradient-text-gold uppercase">
                  {{ $te('shootingRange') }}
                </span>
              </div>
              <ul class="carnival-stand-popup-feature-text-list">
                <li
                  v-for="text in shootingRangeTexts"
                  :key="text"
                  class="text-32 mb-8 carnival-stand-popup-content-text text-left relative"
                >
                  {{
                    $replacePlaceholder(
                      $replacePlaceholder($te(text), '%s', 10),
                      '{2to4}',
                      gunpowderDailyCount ?? destroyTargetCost ?? 4,
                    )
                  }}
                </li>
              </ul>
            </section>
          </app-scrollbar>
        </div>
      </info-popup>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import CarnivalStandHeader from '@/components/Events/CarnivalStandComponents/CarnivalStandHeader.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import FortuneBasketMain from '@/components/Events/CarnivalStandComponents/FortuneBasket/FortuneBasketMain.vue'
import ShootingRangeMain from '@/components/Events/CarnivalStandComponents/ShootingRange/ShootingRangeMain.vue'
import { CarnivalStandMainComponent } from '@/interfaces/events/CarnivalStand'
import { useFortuneBasketStore } from '@/store/pinia/events/fortuneBasketStore'
import { useShootingRangeStore } from '@/store/pinia/events/shootingRangeStore'

import type { SpecialItem } from '@/interfaces/responses/events/fortune-basket/FortuneBasketStateApiResponse'

interface ComponentData {
  isLoading: boolean
  showPopup: boolean
  carnivalComponent: CarnivalStandMainComponent
  fortuneBasketTexts: string[]
  shootingRangeTexts: string[]
}

export default defineComponent({
  name: 'CarnivalStandMain',
  components: {
    CarnivalStandHeader,
    FortuneBasketMain,
    ShootingRangeMain,
    InfoPopup,
  },
  data(): ComponentData {
    return {
      isLoading: false,
      showPopup: false,
      carnivalComponent: CarnivalStandMainComponent.FortuneBasket,
      fortuneBasketTexts: [
        'pumpkinPatchInfo1',
        'pumpkinPatchInfo2',
        'pumpkinPatchInfo3',
        'pumpkinPatchInfo4',
        'pumpkinPatchInfo5',
        'pumpkinPatchInfo6',
        'pumpkinPatchInfo7',
        'pumpkinPatchInfo8',
      ],
      shootingRangeTexts: [
        'shootingRangeInfo1',
        'gunPowderInfo2',
        'shootingRangeInfo3',
        'shootingRangeInfo4',
        'shootingRangeInfo5',
        'gunPowderInfo3',
      ],
    }
  },
  computed: {
    ...mapState(useShootingRangeStore, {
      destroyTargetCost: 'getDestroyTargetCost',
    }),
    ...mapState(useFortuneBasketStore, {
      specialItems: 'getSpecialItems',
    }),
    getGunpowder(): SpecialItem {
      return this.specialItems?.find((item: SpecialItem): boolean => item?.type === 'gunpowder')
    },
    gunpowderDailyCount(): number {
      return this.getGunpowder?.limit_daily ?? 0
    },
  },
  methods: {
    setCarnivalStandComponent({ component }: { component: CarnivalStandMainComponent }): void {
      this.carnivalComponent = component
    },
  },
})
</script>

<style lang="scss" scoped>
.carnival-stand {
  &__content {
    flex: 1 1 auto;
  }

  &-popup {
    &__content {
      height: 35.375rem;

      .carnival-stand-popup {
        &-header {
          height: 7.75rem;
          background: #0b1c30;
          margin-bottom: 1.25rem;
          padding: 0 1.5rem;
        }

        &-about-title {
          position: relative;
          width: 100%;
          height: 5.188rem;
          margin: 0.25rem 0 1.375rem;
          background: linear-gradient(
            to right,
            transparent,
            rgba(11, 28, 47, 0.7) 25%,
            rgba(11, 28, 47, 1),
            rgba(11, 28, 47, 1),
            rgba(11, 28, 47, 0.7) 75%,
            transparent
          );

          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 0.125rem;
            background: linear-gradient(
              to right,
              transparent,
              rgba(49, 80, 107, 0.7) 25%,
              rgba(49, 80, 107, 1),
              rgba(49, 80, 107, 1),
              rgba(49, 80, 107, 0.7) 75%,
              transparent
            );
          }

          &::before {
            top: 0;
          }

          &::after {
            bottom: 0;
          }
        }
      }
    }

    &-feature-text-list {
      padding-left: 5.313rem;
      padding-right: 2rem;
      list-style: square;
    }
  }
}
</style>
