import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { LAYOUT_VIEW_ROUTE_NAME } from '@/globalVariables'
import type { RouteLocationRaw } from 'vue-router'

const REDIRECT_ROUTE = { name: LAYOUT_VIEW_ROUTE_NAME }

export const mechanicRouteGuard = (mechanic: string | string[]): boolean | RouteLocationRaw => {
  const responseStore = useResponseTaskStore()
  const hasMechanic = responseStore.hasMechanicLocalStorage

  const mechanicArray = !Array.isArray(mechanic) ? [mechanic] : mechanic

  // true is equivalent to the deprecated next()
  return mechanicArray.some((mechanic: string): boolean => hasMechanic(mechanic))
    ? true
    : REDIRECT_ROUTE
}
