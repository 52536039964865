<template>
  <info-popup :popup-title="$te('playoffRules')" width="76.125rem" @close="$emit('close')">
    <section class="playoff-info justify-center">
      <app-scrollbar width="100%" height="100%" scroll="y" slice="y">
        <article v-for="(rule, i) in rulesTexts" :key="i" class="justify-center">
          <header class="font-bold text-50 uppercase relative flexing">
            <p class="gradient-text-gold">
              {{ $te(rule.title) }}
            </p>
          </header>
          <div
            v-for="text in rule.texts"
            :key="text"
            class="text text-34 relative text-texts-standard-default text-left"
          >
            <template v-if="text === 'playoffRulesFour'">
              {{ $replacePlaceholder($te(text), '%s', playoffConfig.advances_to_draw.toString()) }}
            </template>
            <template v-else-if="text === 'tournamentInfo1'">
              {{ $replacePlaceholder($te(text), '%s', qualificationRounds.toString()) }}
            </template>
            <template v-else-if="text === 'clubTournamentInfo1'">
              {{
                $replacePlaceholder($te(text), '%s', playoffConfig?.clubs_min_members_count ?? 5)
              }}
            </template>
            <template v-else>
              {{ $replacePlaceholder($te(text), '{2to4}', qualificationRounds.toString()) }}
            </template>
          </div>
        </article>
      </app-scrollbar>
    </section>
  </info-popup>
</template>

<script lang="ts">
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import { EventType } from '@/interfaces/events/EventInfo'
import { PlayoffType } from '@/interfaces/events/Playoff'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PlayoffRulesPopup',
  components: {
    InfoPopup,
  },
  emits: ['close'],
  computed: {
    ...mapState(useEventPlayoffStore, {
      playoffConfig: 'getPlayoffConfig',
      playoffType: 'getPlayoffType',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    qualificationRounds(): number {
      return Object.keys(this.playoffConfig.qualification.tasks || {}).length
    },
    rulesTexts(): { title: string; texts: string[] }[] {
      return [
        {
          title: 'qualification',
          texts: {
            [PlayoffType.Individual]: [
              this.namespace === EventType.AutumnFair ? 'tournamentInfo1' : 'playoffRulesOne',
              'playoffRulesTwo',
              'playoffRulesThree',
              'playoffRulesFour',
              'playoffRulesFive',
              'playoffRulesSix',
            ],
            [PlayoffType.Masters]: [
              this.namespace === EventType.AutumnFair ? 'tournamentInfo1' : 'playoffRulesOne',
              'playoffRulesTwo',
              'playoffRulesThree',
              'playoffRulesFour',
              'playoffRulesFive',
              'playoffRulesSix',
            ],
            [PlayoffType.Club]: [
              'clubTournamentInfo1',
              'tournamentInfo1',
              'clubTournamentInfo3',
              'clubTournamentInfo4',
              'clubTournamentInfo5',
              'clubTournamentInfo6',
              'clubTournamentInfo7',
              'clubTournamentInfo8',
            ],
          }[this.playoffType],
        },
        {
          title: 'playoff',
          texts: {
            [PlayoffType.Individual]: ['playoffOne', 'playoffTwo', 'playoffThree'],
            [PlayoffType.Masters]: ['playoffOne', 'playoffTwo', 'playoffThree'],
            [PlayoffType.Club]: [
              'clubTournamentInfo9',
              'clubTournamentInfo7',
              'clubTournamentInfo5',
              'playoffThree',
            ],
          }[this.playoffType],
        },
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
.playoff-info {
  height: 41.5rem;
  flex-direction: column;
  padding-top: 2.125rem;

  header {
    background: linear-gradient(to right, transparent 17%, #0b1c2f, transparent 83%);
    height: 5.25rem;
    margin: 0.625rem 0;

    &:before,
    &:after {
      content: '';
      background: linear-gradient(to right, transparent, #31506b, transparent);
      position: absolute;
      width: 80%;
      left: 50;
      transform: translate(0, -50%);
      height: 0.125rem;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .text {
    padding-left: 5rem;
    width: 70.4375rem;
    line-height: 2.8rem;
    margin: 1rem 0;

    &:before {
      content: '';
      position: absolute;
      left: -1rem;
      top: 1rem;
      transform: translate(4rem);
      width: 0.625rem;
      height: 0.625rem;
      background: #fff;
    }
  }
}
</style>
