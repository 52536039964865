<template>
  <div v-if="hideTooltip" :class="getIconType" />
  <div
    v-else
    v-tippy="
      tooltip.length > 0 || iconName
        ? {
            theme: 'WSM',
            content:
              tooltip.length > 0
                ? translateIconName(tooltip)
                : translateIconName(iconName.toLowerCase()),
            placement: 'top',
          }
        : ''
    "
    :class="getIconType"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import {
  BENEFIT_PERSONAL_BRONZE,
  BENEFIT_PERSONAL_GOLD,
  BENEFIT_PERSONAL_SILVER,
  BENEFIT_POWERBOOST_BRONZE,
  BENEFIT_POWERBOOST_GOLD,
  BENEFIT_POWERBOOST_SILVER,
  BENEFIT_PROTRAINING_BRONZE,
  BENEFIT_PROTRAINING_GOLD,
  BENEFIT_PROTRAINING_SILVER,
  CHAMPIONSHIP_POINTS,
  ENERGY_DRINK,
  EVENT_POINTS,
  EXPERIENCE,
  MONEY,
  PRESTIGE,
  RANKING_POINTS,
  RESEARCH_POINTS,
  TOURNAMENT_TITLES,
  TRAINING_POINTS,
  TROPHY_BRONZE,
  TROPHY_GOLD,
  TROPHY_SILVER,
  UNIVERSAL_TRAINING_POINTS,
  WILD_CARD,
  WON_MATCHES,
  TOURNAMENT_POINTS,
  MULLIGANS,
  AVATAR,
  AVATAR_BG,
  TOURNAMENT_POINTS_DISCIPLINE_2,
} from '@/globalVariables'
import { defineComponent } from 'vue'

const GP_TOOLTIP_MAPPING = {
  'gp-basic': 'grandPrize.tierBasic',
  'gp-bronze': 'grandPrize.tierBronze',
  'gp-silver': 'grandPrize.tierSilver',
  'gp-gold': 'grandPrize.tierGold',
  'gp-diamond': 'grandPrize.tierDiamond',
}

export default defineComponent({
  name: 'AppMainIcon',
  props: {
    iconName: {
      type: String,
      required: true,
      validator(value: string): boolean {
        return [
          'training_points',
          'energy',
          'money',
          'gems',
          'starts',
          'UNIVERSAL_TRAINING_POINTS',
          'universal_training_points',
          'prestige',
          'research_points',
          'gallery_pieces',
          'benefits',
          'ranking_points',
          RANKING_POINTS,
          'experience',
          'EXPERIENCE',
          'stars',
          'wild_card',
          'energy_drink',
          'grand_prize',
          'gp-basic',
          'gp-bronze',
          'gp-silver',
          'gp-gold',
          'gp-diamond',
          'powerboost-attribute_bronze',
          'powerboost-attribute_silver',
          'powerboost-attribute_gold',
          'personal-cash_bronze',
          'personal-cash_silver',
          'personal-cash_gold',
          'protraining-training_points_bronze',
          'protraining-training_points_silver',
          'protraining-training_points_gold',
          'benefit-1',
          'benefit-2',
          'benefit-3',
          'benefit-4',
          'benefit-5',
          'benefit-6',
          'benefit-7',
          'benefit-8',
          'benefit-9',
          'prestige',
          'trophy-bronze',
          'trophy-silver',
          'trophy-gold',
          'star-full',
          'career-gold',
          'masters_playoff_points',
          EVENT_POINTS,
          CHAMPIONSHIP_POINTS,
          'gallery_piece',
          WON_MATCHES,
          'won_matches',
          TOURNAMENT_TITLES,
          'helmet',
          'target',
          'event_points_multiplier',
          TROPHY_GOLD,
          'medals',
          'multiplier_5',
          'piggy_bank_universal_training_points',
          'piggy_bank_gems',
          'piggy_bank_money',
          TOURNAMENT_POINTS,
          MULLIGANS,
          'gunpowder',
          'fortune-basket-token',
          'double-reward',
          'triple-reward',
          'refresh',
          'league_points',
          'fortune_basket_shuffle',
          'fortune_basket_double',
          'fortune_basket_triple',
          'open-in-new-tab',
          TOURNAMENT_POINTS_DISCIPLINE_2,
        ].includes(value)
      },
    },
    iconSize: {
      type: Number,
      required: true,
      validator(value: number): boolean {
        return [24, 32, 38, 48, 56, 72, 80, 90, 104, 120, 152, 184, 360].includes(value)
      },
    },
    tooltip: {
      type: String,
      default: '',
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    gpRarity: {
      type: String,
      default: '',
    },
  },
  computed: {
    getIconType(): string {
      return (
        'icon-' +
        (typeof this.iconName === 'string' ? this.iconName.toLowerCase() : '') +
        '-' +
        (this.gpRarity ? this.gpRarity + '-' : '') +
        this.iconSize
      )
    },
  },
  methods: {
    translateIconName(name: string): string {
      if (!name) return ''

      // Normalize the prefixes of Grand Prizes
      if (name.includes('grand_prize-')) name = name.replace('grand_prize-', 'gp-')

      switch (name) {
        case MONEY:
          return this.$t('map.money')

        case WILD_CARD:
          return this.$t('parameters.wild_card')

        case ENERGY_DRINK:
          return this.$t('parameters.energy_drink')

        case RESEARCH_POINTS:
          return this.$t('research.researchPoints')

        case RANKING_POINTS.toLowerCase() || RANKING_POINTS:
          return this.$t('rankingsGroup.rankingPoints')

        case UNIVERSAL_TRAINING_POINTS.toLowerCase():
          return this.$t('map.UNIVERSAL_TRAINING_POINTS')

        case TRAINING_POINTS.toLowerCase():
          return this.$t('rewards.training_points')

        case EXPERIENCE:
          return this.$t('common.experiences')

        case PRESTIGE:
          return this.$t('rankingsGroup.prestige')

        case TROPHY_BRONZE:
          return this.$t('rankingsGroup.trophyBronze')

        case TROPHY_SILVER:
          return this.$t('rankingsGroup.trophySilver')

        case TROPHY_GOLD:
          return this.$t('rankingsGroup.trophyGold')

        case CHAMPIONSHIP_POINTS:
          return this.$t('common.championshipPoints')

        case 'trophy-gold':
          return this.$t('event.tournament')

        case TOURNAMENT_TITLES:
          return this.$t('club.tournamentTitles')

        case BENEFIT_POWERBOOST_BRONZE:
          return 'PowerBoost 15'

        case BENEFIT_POWERBOOST_SILVER:
          return 'PowerBoost 30'

        case BENEFIT_POWERBOOST_GOLD:
          return 'PowerBoost 60'

        case BENEFIT_PERSONAL_BRONZE:
          return 'Personal Sponsor 10'

        case BENEFIT_PERSONAL_SILVER:
          return 'Personal Sponsor 25'

        case BENEFIT_PERSONAL_GOLD:
          return 'Personal Sponsor 60'

        case BENEFIT_PROTRAINING_BRONZE:
          return 'ProTraining 10'

        case BENEFIT_PROTRAINING_SILVER:
          return 'ProTraining 25'

        case BENEFIT_PROTRAINING_GOLD:
          return 'ProTraining 60'

        case WON_MATCHES:
          return this.$t('grandPrize.duelsWon')

        case 'won_matches':
          return this.$t('grandPrize.duelsWon')

        case EVENT_POINTS:
          return this.$t('event.eventPoints')

        case 'event_points_multiplier':
          return this.$t('event.eventPointsMultiplier')

        case 'activity_coins':
          return this.$t('boardGames.activity_coins')

        case 'dice_7_12':
          return this.$t('boardGames.dice_7_12')

        case 'double_reward':
          return this.$t('boardGames.double_reward')

        case 'move_one_forward':
          return this.$t('boardGames.move_one_forward')

        case 'glacial_dice_of_burning':
          return this.$t('boardGames.glacial_dice_of_burning')

        case 'armor_of_ice_and_fire':
          return this.$t('boardGames.armor_of_ice_and_fire')

        case 'medals':
          return this.$t('event.medals')

        case 'masters_playoff_points':
          return this.$t('event.mastersPoints')

        case 'gp-basic':
        case 'gp-bronze':
        case 'gp-silver':
        case 'gp-gold':
        case 'gp-diamond':
          return this.$replaceUntranslatablePlaceholder(
            this.$t(GP_TOOLTIP_MAPPING[name]),
            '{grandprize}',
          )

        case 'star-full': {
          return this.$t('event.eventTrainingStars')
        }
        case 'multiplier_5': {
          return this.$t('premiumGroup.wsm_season_pass_1_content_multiplier_5_bonus')
        }
        case 'target': {
          return this.$t('mechanics.map_items')
        }

        case 'piggy_bank_universal_training_points':
        case 'piggy_bank_gems':
        case 'piggy_bank_money':
          return this.$t('piggyBank.piggyBank')

        case TOURNAMENT_POINTS:
          return this.$t('tournaments.tournamentPoints')
        case MULLIGANS:
          return this.$t('tournaments.mulligan')

        case 'gunpowder': {
          return this.$t('event.gunpowder')
        }

        case 'fortune-basket-token':
        case 'fortune_basket_token': {
          return this.$t('event.tokens')
        }

        case 'double-reward':
        case 'fortune_basket_double': {
          return this.$t('event.doubleReward')
        }

        case 'triple-reward':
        case 'fortune_basket_triple': {
          return this.$t('event.tripleReward')
        }

        case 'refresh': {
          return this.$t('event.refresh')
        }

        case 'fortune_basket_shuffle': {
          return this.$t('event.newPatch')
        }

        case 'league_points': {
          return this.$t('event.leaguePoints')
        }

        case AVATAR: {
          return this.$t('premiumGroup.avatar')
        }

        case AVATAR_BG: {
          return this.$t('premiumGroup.avatarBackground')
        }

        case TOURNAMENT_POINTS_DISCIPLINE_2: {
          return this.$replacePlaceholder(this.$t('tournaments.specialPoints'), '{partner}', 'IBU')
        }

        default:
          if (name.includes('benefit-')) {
            const number = name.substring(name.indexOf('-') + 1)
            return this.$t(`rewards.benefit_${number}`)
          }
          return this.$t('map.' + name).length === 0 ? name : this.$t('map.' + name)
      }
    },
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/components/icons/main-icons.scss';
@import '@/assets/styles/components/icons/benefits-icons.scss';

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
