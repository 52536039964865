<template>
  <header
    class="rankings-header relative w-full flex justify-end"
    :class="{ 'rankings-header--special': isSpecialTournament }"
  >
    <div
      v-if="isSpecialTournament"
      class="partnership-promo-link-box-wrapper flexing clickable-element cursor-pointer"
      @click="openPromoLink"
    >
      <div class="partnership-promo-link-box flexing">
        <div class="partnership-promo-link-box__logo" />
        <div class="partnership-promo-link-box__content flex justify-between">
          <span class="flexing text-26 text-texts-standard-default">
            {{
              $replacePlaceholder(
                $t('tournaments.rankingBanner'),
                '{partner}',
                $t('tournaments.ibuECup'),
              )
            }}
          </span>
          <app-main-icon :icon-size="38" icon-name="open-in-new-tab" hide-tooltip />
        </div>
      </div>
    </div>
    <app-select
      :options="periods"
      :default="defaultPeriod"
      class="selectbox-periods"
      :class="{ 'selectbox-periods--special -ml-2': isSpecialTournament }"
      @select="$emit('set-period', $event)"
    />
    <tippy theme="WSM" placement="left" max-width="35rem">
      <app-icon icon-name="info-70" />
      <template #content>
        <div class="tooltip-content text-texts-standard-default text-30">
          <p>
            {{ $replacePlaceholder($t('rankingsGroup.rankingRefreshTime'), '%s', '30') }}
          </p>
        </div>
      </template>
    </tippy>
  </header>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { SelectOption } from './RankingsMain.vue'

export default defineComponent({
  props: {
    periods: {
      type: Array as PropType<SelectOption[]>,
      default: () => [],
    },
    defaultPeriod: {
      type: String,
      default: '',
    },
    isSpecialTournament: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['set-period'],
  methods: {
    openPromoLink(): void {
      // TODO po dospecifikovani doplnit realnu URL
      window.open('https://www.biathlonworld.com/', '_blank')
    },
  },
})
</script>

<style lang="scss" scoped>
.rankings-header {
  height: 4.375rem;
  margin-bottom: 1.25rem;

  &--special {
    gap: 0.75rem;
  }

  .selectbox-periods {
    position: absolute;
    left: 50%;
    transform: translate(-50%) $skewX-value !important;

    &--special {
      position: relative;
      left: 0;
      transform: translate(0) $skewX-value !important;
    }
  }

  .partnership-promo-link-box {
    $pplb-shape: polygon(0% 0%, 99% 0%, calc(99% - 0.625rem) 100%, 0% 100%);

    &-wrapper {
      flex: 1 1 0;
      min-width: 51.375rem;
      height: 4.375rem;
      background: $color-important;
      clip-path: $pplb-shape;
    }

    min-width: calc(100% - 0.375rem);
    height: calc(100% - 0.375rem);
    // W3C: dynamic resizing of the gradient with background-image
    background-image: url('#{$path-images}tournaments/banner-bg-right-side.avif'),
      linear-gradient(to right, #297d72, #297d72);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    clip-path: $pplb-shape;
    padding-right: 1.625rem; // 1 + 0.625rem from the right side of the clip-path
    gap: 1.563rem;

    &__logo {
      width: 4.125rem;
      height: 4.125rem;
      background: #fff;
      background-image: url('#{$path-images}tournaments/logos/logo-discipline-2-120.avif');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &__content {
      flex: 1 1 0;

      span {
        line-height: 1.75rem;
      }
    }
  }
}
</style>
