import {
  metaPremiumPackOfferEndpoint,
  metaPremiumPacksEndpointType,
  REFRESHING_BUNDLE,
} from '@/globalVariables'
import type { Offer } from '@/interfaces/premium/Offer'
import type PremiumOfferApiResponse from '@/interfaces/responses/premium/PremiumOfferApiResponse'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import type { AxiosInstance } from 'axios'

interface OfferStoreIdObject {
  store_id?: string
}

export const getOfferData = async (
  offerId: string,
  type: string = 'offer',
  axios: AxiosInstance,
): Promise<void> => {
  let offerData

  if (offerId.includes(REFRESHING_BUNDLE)) {
    try {
      const refreshingData = await axios.get<{}, PremiumOfferApiResponse>(
        metaPremiumPackOfferEndpoint,
      )
      offerData = refreshingData.packs.filter((offer: Offer): boolean =>
        offer.store_id.includes(REFRESHING_BUNDLE),
      )
    } catch (error: unknown) {
      console.error(error)
    }
  } else {
    try {
      const offerDetailData = await axios.get<{}, PremiumOfferApiResponse>(
        `${metaPremiumPacksEndpointType}${type}?storeId=${offerId}`,
      )
      offerData = offerId.includes('gem_bonus') ? [offerDetailData] : offerDetailData.packs
    } catch (error: unknown) {
      console.error(error)
    }
  }

  if (offerData?.length) {
    const usePremiumState = usePremiumStore()
    usePremiumState.showOfferDetail({
      offerData: offerData,
    })
  }
}

export const isGamePass = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('game_pass') || false

export const isTournamentPass = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('tournament_pass') || false

export const isSpecialTournament = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('special_tournament') || false

export const isPiggyBank = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('piggy_bank') || false

export const isEventOffer = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('event_offer') ||
  offer?.store_id?.startsWith('wsm_winter_series_') ||
  offer?.store_id?.startsWith('ssm_summer_series_') ||
  false

export const isEventPass = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('event_pass') || offer?.store_id?.includes('board_pass') || false

export const isChampionPass = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('champion_pass') || false

export const isMasterPass = (offer: OfferStoreIdObject): boolean =>
  offer?.store_id?.includes('master_pass') || false

export const isBoardGamesEventPass = (offer: OfferStoreIdObject): boolean =>
  [
    'wsm_event_pass_7',
    'wsm_event_pass_9',
    'ssm_event_pass_8',
    'wsm_bg_champion_pass',
    'ssm_bg_champion_pass',
    'ssm_board_pass',
    'ssm_event_pass_9',
    'wsm_event_pass_10',
  ].includes(offer?.store_id)

export const isBoardGamesComboPass = (offer: OfferStoreIdObject): boolean =>
  ['wsm_event_pass_8', 'ssm_event_pass_7'].includes(offer?.store_id)

export const isBoardGamesEventOffer = (offer: OfferStoreIdObject): boolean =>
  ['wsm_event_offer_25', 'wsm_event_offer_26', 'wsm_event_offer_27', 'wsm_event_offer_28'].includes(
    offer?.store_id,
  ) ||
  /^wsm_board_offer_\d+$/.test(offer?.store_id) ||
  /^ssm_board_offer_\d+$/.test(offer?.store_id)
