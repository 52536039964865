<template>
  <div
    class="r-box relative cursor-pointer clickable-element text-24 m-auto"
    :style="setBackgroundImage"
    @click="setOffer"
  >
    <div v-if="isGemBonus" class="badge">
      <gems-bonus-badge
        :type="content.type"
        :bonus="content.bonus"
        size="small"
        :transparent="true"
      />
    </div>
    <div v-if="isLevelUpOffer" class="level-up relative uppercase">
      <p class="level-up-text">Level</p>
      <p class="level-up-number font-bold">
        {{ getLevelForOffer }}
      </p>
    </div>
    <div
      class="r-box-text w-full absolute text-texts-standard-default flex items-center justify-start uppercase"
    >
      <shrink-text :text="rightboxName" :font-size="24" />
    </div>
    <vue-countdown
      v-if="box.remaining_time && !isSpecialTournament"
      v-slot="{ days, hours, minutes, seconds }"
      class="r-box-time flexing text-texts-standard-default italic absolute bottom-0"
      :time="box.remaining_time * 1000"
      @end="$emit('reloadBoxes', { storeId: content.store_id })"
    >
      <app-icon icon-name="time" class="r-box-time-icon inline mr-2" />
      {{ box.remaining_time ? formatTime(days, hours, minutes, seconds) : '' }}
    </vue-countdown>
  </div>
</template>

<script lang="ts">
import GemsBonusBadge from '@/components/Premium/GemsPack/GemsBonusBadge.vue'
import ShrinkText from '../ShrinkText.vue'
import {
  LEGENDARY_CHAIN,
  LEVEL_BAGS,
  MECHANIC_EVENT,
  OFFER_STATES,
  REFRESHING_BUNDLE,
  WEEKLY_URLS,
  GAME_PASS,
  pathImages,
  TOURNAMENT_PASS,
  SPECIAL_TOURNAMENTS,
} from '@/globalVariables'
import { formatTime, isChampionPass, isTournamentPass } from '@/helpers'
import type Reward from '@/interfaces/Reward'
import { PassType } from '@/interfaces/events/Passes'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useUserStore } from '@/store/pinia/userStore'
import type { Passes } from '@/interfaces/User'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Price from '@/interfaces/Price'

interface Parameter {
  name: string
  value: number | string
}

interface RBoxBigOfferContent {
  store_id: string
  category: string
  offer_content: Reward[]
  price: Price
  remaining_buys: number
  remaining_time: number
  prerequisite?: string
  locked?: boolean
  parameters: Parameter[]
  stats?: Parameter[]
  // Gems bonus only.
  type?: string
  bonus?: number
  // Legendary chain only.
  content?: 'bonus' | string | Parameter
}

interface RBoxSmallOfferContent {
  store_id: string
  category: string
  offer_content: Reward[]
  price: Price
  remaining_buys: number
  remaining_time: number
  parameters: Parameter[]
}

type RBoxRefreshingBundleOfferContent = [
  RBoxSmallOfferContent,
  RBoxSmallOfferContent,
  RBoxSmallOfferContent,
]

interface RBox {
  type: string
  content: Nullable<RBoxBigOfferContent | RBoxRefreshingBundleOfferContent>
  remaining_time: Nullable<number>
}

export default defineComponent({
  name: 'RBoxComponent',
  components: {
    GemsBonusBadge,
    ShrinkText,
  },
  props: {
    box: {
      type: Object as PropType<Nullable<RBox>>,
      default: () => null,
    },
  },
  emits: ['reloadBoxes'],
  computed: {
    ...mapState(useUserStore, {
      passes: 'getAvailablePasses',
    }),
    gamePrefix(): string {
      if (this.$isSsm) return 'ssm'
      return 'wsm'
    },
    content(): RBoxBigOfferContent {
      return this.box.content as RBoxBigOfferContent
    },
    refreshingBundleContent(): RBoxRefreshingBundleOfferContent {
      return this.box.content as RBoxRefreshingBundleOfferContent
    },
    setBackgroundImage(): string {
      return `background-image: url(${pathImages}components/rightbox/rb-${this.imageName}.avif)`
    },
    isGemBonus(): boolean {
      return this.box.type.includes('gem_bonus')
    },
    isLevelUpOffer(): boolean {
      return this.box.type.includes('level_up_bundle')
    },
    isFreeRewards(): boolean {
      return this.box.type.includes('free_rewards')
    },
    isEventOffer(): boolean {
      return this.box.type === MECHANIC_EVENT
    },
    isWinterSeriesEventOffer(): boolean {
      return this.content.store_id.startsWith('wsm_winter_series_')
    },
    isSummerSeriesEventOffer(): boolean {
      return this.content.store_id.startsWith('ssm_summer_series_')
    },
    isWeekly(): boolean {
      return this.box.type.includes('weekly')
    },
    isWeeklyTab(): boolean {
      return this.box.type.includes('weekly_deals_tab')
    },
    isRefreshing(): boolean {
      return this.box.type.includes(REFRESHING_BUNDLE)
    },
    isChain(): boolean {
      return this.box.type.includes(LEGENDARY_CHAIN)
    },
    isBags(): boolean {
      return this.box.type.includes(LEVEL_BAGS)
    },
    isGamePass(): boolean {
      return this.box.type.includes(GAME_PASS)
    },
    isPiggyBank(): boolean {
      return this.box.type.includes('piggy_bank')
    },
    isTournamentOffer(): boolean {
      return (
        this.box.type.includes('tournaments_offer') &&
        !isTournamentPass({ store_id: this.content.store_id })
      )
    },
    isTournamentPass(): boolean {
      return (
        this.box.type.includes(TOURNAMENT_PASS) &&
        isTournamentPass({ store_id: this.content.store_id })
      )
    },
    isSpecialTournament(): boolean {
      return this.box.type.includes(SPECIAL_TOURNAMENTS)
    },
    getLevelForOffer(): string {
      return (
        this.content?.stats
          ?.find(({ name }: Parameter): boolean => name === 'level_bundle_level')
          ?.value.toString() ?? ''
      )
    },
    getAlternativeStoreId(): string {
      return (
        this.content?.stats
          ?.find(({ name }: Parameter): boolean => name === 'texts_images_key')
          ?.value.toString() ?? ''
      )
    },
    imageName(): string {
      if (this.box.type === 'season_pass') {
        const seasonPassData = this.passes.find(
          (pass: Passes): boolean => pass.store_id === this.content.store_id,
        )
        const postfixState =
          seasonPassData?.state === OFFER_STATES.active
            ? OFFER_STATES.active
            : OFFER_STATES.available
        return `${this.content.store_id}_${postfixState}`
      }
      if (this.isChain) {
        const typePostfix = 'halloween_' // zmenit na prazdny string ak chceme klasicky legendary chain a nie je stale poriesene backendovo
        return `${this.gamePrefix}_legendary_chain_offer_${typePostfix}${this.content.content}`
      }
      if (this.isBags) {
        return `${this.gamePrefix}_level_bags`
      }
      if (this.isGamePass) {
        return `${this.gamePrefix}_game_pass`
      }
      if (this.isPiggyBank) {
        return `${this.gamePrefix}_piggy_bank_${this.content.content.parameter_name.toLowerCase()}`
      }
      if (this.getAlternativeStoreId) {
        return this.getAlternativeStoreId
      }
      if (this.isFreeRewards) {
        return `${this.gamePrefix}_free_rewards`
      }
      if (this.isRefreshing) {
        return REFRESHING_BUNDLE
      }
      if (this.isWeeklyTab) {
        return 'weekly'
      }
      if (this.isEventOffer || this.isSpecialTournament) {
        if (this.isWinterSeriesEventOffer) return 'wsm_winter_series'
        if (this.isSummerSeriesEventOffer) return 'ssm_summer_series'

        return (
          this.content?.parameters
            ?.find(({ name }: Parameter): boolean => name === 'image_name')
            ?.value.toString() ?? ''
        )
      }
      if (this.isLevelUpOffer) {
        return `${this.gamePrefix}_level_up_bundle`
      }

      return this.content.store_id
    },
    rightboxName(): string {
      if (this.isFreeRewards) {
        return this.$t('premiumGroup.wsm_free_rewards')
      }
      if (this.isChain) {
        return this.$t('premiumGroup.wsm_chain_of_legends')
      }
      if (this.isBags) {
        return this.$t('premiumGroup.level_up_bags')
      }
      if (this.isGamePass) {
        return this.$t('gamePass.gamePass')
      }
      if (this.isPiggyBank) {
        return this.$t('piggyBank.piggyBank')
      }
      if (this.isWeekly) {
        return this.$t('premiumGroup.weekly')
      }
      if (this.getAlternativeStoreId) {
        return this.$t(`premiumGroup.${this.getAlternativeStoreId}`)
      }
      if (this.isRefreshing) {
        return this.$t('premiumGroup.wsm_refreshing_bundle_1')
      }
      if (this.isEventOffer && this.isWinterSeriesEventOffer) {
        return this.$t('premiumGroup.wsm_winter_series_rightbox')
      }
      if (this.isEventOffer && this.isSummerSeriesEventOffer) {
        return this.$t('premiumGroup.ssm_summer_series_rightbox')
      }
      if (this.isTournamentPass) {
        return this.$t('tournaments.tournamentPass')
      }
      if (this.isSpecialTournament) {
        return this.$t('tournaments.ibuECup')
      }

      return this.$t(`premiumGroup.${this.content.store_id}`)
    },
  },
  methods: {
    formatTime,
    ...mapActions(usePremiumStore, {
      showOfferDetail: 'showOfferDetail',
    }),
    async setOffer(): Promise<void> {
      if (this.isEventOffer) {
        if (this.isWinterSeriesEventOffer || this.isSummerSeriesEventOffer) {
          this.$router.push({
            name: this.$getWebView('PremiumEventOffers'),
          })
          return
        }
        if (isChampionPass(this.box?.content)) {
          this.$router.push({
            name: this.$getWebView('PremiumEventPasses'),
            query: { pass: PassType.Champion },
          })
          return
        }
      }
      if (Object.keys(WEEKLY_URLS).includes(this.box.type)) {
        this.$router.push({
          name: this.$getWebView(WEEKLY_URLS[this.box.type]),
        })
        return
      }
      if (this.isChain) {
        this.$router.push({
          name: this.$getWebView('PremiumChain'),
        })
        return
      }
      if (this.isBags) {
        this.$router.push({
          name: this.$getWebView('PremiumLevelBagsPacks'),
        })
        return
      }
      if (this.isGamePass) {
        this.$router.push({
          name: this.$getWebView('PremiumGamePassPacks'),
        })
        return
      }
      if (this.isPiggyBank) {
        this.$router.push({
          name: this.$getWebView('PremiumPiggyBank'),
        })
        return
      }
      if (this.box.type.includes(REFRESHING_BUNDLE)) {
        const dataForRefreshing = this.refreshingBundleContent.filter(
          (offer: RBoxSmallOfferContent): boolean => offer.store_id.includes(REFRESHING_BUNDLE),
        )
        this.showOfferDetail({ offerData: dataForRefreshing })
        return
      }
      // get offer data
      if (this.content) {
        // save offer data to store
        this.showOfferDetail({ offerData: this.content })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.r-box {
  width: 15rem;
  height: 11.5625rem;
  pointer-events: all;
  @include background(null);

  &-text {
    height: 1.8rem;
    padding-left: 0.9rem;
    bottom: 2rem;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }

  &-time {
    height: 1.9rem;
    width: 15rem;

    &-icon {
      width: 1em !important;
      height: 1em !important;
    }
  }

  & .level-up {
    top: 1rem;
    left: -0.25rem;

    &-text {
      margin-left: 0.75rem;
    }

    &-number {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  animation: box-animation 2s 2s 1 ease-in;

  @keyframes box-animation {
    25% {
      transform: rotate(4deg) scale(1.05);
    }

    50% {
      transform: rotate(-4deg) scale(1.05);
    }

    75% {
      transform: rotate(4deg) scale(1.05);
    }

    100% {
      transform: rotate(0deg) scale(1);
    }
  }

  .text-20 {
    font-size: 1.15rem;
  }

  .badge {
    position: absolute;
    top: 0.4rem;
  }
}
</style>
