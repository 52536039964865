<template>
  <div v-if="loaded" class="fortune-basket flex flex-col w-full h-full">
    <div class="fortune-basket__header flexing">
      <info-text :text="$te('pumpkinPatchCTA')" />

      <div class="menu-info-box flex justify-between items-center">
        <tippy theme="WSM1" placement="bottom" max-width="42.563rem">
          <template #content>
            <p class="p-6">{{ $te('tokenInfo') }}</p>
          </template>
          <app-icon icon-name="info-50" class="revert-skew click-revert" />
        </tippy>
        <div class="flexing">
          <div
            class="menu-info-box-text mr-3 text-texts-standard-default text-36 font-bold revert-skew"
          >
            <span>{{ $filters.$formatNumber(getTokenCount) }}</span>
          </div>
          <app-main-icon
            :icon-size="56"
            :icon-name="getTokenIcon"
            class="menu-info-box-icon revert-skew"
          />
        </div>
      </div>
      <app-button
        v-if="!eventCollectionActive"
        class="fortune-basket__add-button ml-6"
        btn-type="secondary"
        @click="showTokenPopup = true"
      >
        <span class="text-60 font-bold revert-skew">+</span>
      </app-button>
      <popup-window
        v-if="showTokenPopup"
        popup-type="info"
        :popup-title="$te('tokens')"
        @close="showTokenPopup = false"
      >
        <div class="token-shop-popup flex flex-col">
          <p class="token-shop-cta text-texts-standard-default text-36 font-bold flexing">
            {{ $te('tokenShop') }}
          </p>
          <div class="token-shop-box-wrapper flexing">
            <div
              v-for="(box, index) in tokensForGems"
              :key="box.tokens"
              class="token-shop-box flex flex-col"
            >
              <div class="token-shop-box-header" />
              <div class="token-shop-box-value flexing text-50 font-bold">{{ box.tokens }}x</div>
              <app-button
                class="token-shop-box-button"
                btn-type="credit"
                @click="handleTokenPurchase(index + 1)"
              >
                <div class="flexing">
                  <app-main-icon :icon-size="56" icon-name="gems" />
                  <span class="font-bold">{{ box.count }}</span>
                </div>
              </app-button>
            </div>
          </div>
        </div>
      </popup-window>
      <popup-window
        v-if="showDailyReset && showDailyPopup"
        :popup-title="$te('newDay')"
        popup-type="info"
        @close="showDailyPopup = false"
      >
        <div class="tier-shop-popup flex flex-col">
          <div class="tier-shop-popup-content flex flex-col">
            <div class="tier-shop-popup-header flexing mb-2.5">
              <info-text :text="$te('gunpowderAvailable')" />
              <div class="flexing">
                <shrink-text
                  :text="$te('timeToReset')"
                  :font-size="32"
                  text-class-list="text-texts-standard-default"
                  :animated-overflow="true"
                />
                <app-timer
                  class="grid-resets-timer ml-6"
                  :time="nextReset"
                  @countdown-action="finishOperation"
                />
              </div>
            </div>
            <div class="tier-shop-popup-subtext flexing text-44 font-bold uppercase">
              <span class="pt-1.5">{{ $te('dailyPassRewards') }}</span>
            </div>
            <div v-if="dailyEventPassBonuses" class="tier-shop-popup-boxes flexing flex-1">
              <div
                v-for="(offer, index) in dailyEventPassBonuses"
                :key="index"
                class="tier-box"
                :class="[
                  `tier-box__offer-${offer.tier}`,
                  { 'tier-box--activated': offer.activated },
                  { 'tier-box--purchased': offer.purchased },
                ]"
              >
                <div class="tier-box__header">
                  <div
                    class="flexing gap-1.5 tier-box-header-text text-36 text-texts-standard-default text-center font-bold"
                  >
                    <span class="text-texts-standard-important">{{ $te('eventpass') }}</span>
                    {{ $te('tier') }} {{ offer.tier }}
                  </div>
                  <div class="tier-box-header-reward flexing gap-2.5">
                    <div class="relative inline-flex">
                      <app-main-icon
                        :icon-size="120"
                        :icon-name="offer.tier < 2 ? 'double-reward' : 'triple-reward'"
                        class="multiplier-toggle-icon"
                      />
                      <div
                        class="basket-benefit-number-box basket-benefit-number-box--left text-30 font-bold text-texts-standard-default flexing"
                      >
                        {{ `${offer.content.bonuses.value}x` }}
                      </div>
                    </div>

                    <div class="relative inline-flex">
                      <app-main-icon
                        :icon-size="120"
                        icon-name="refresh"
                        class="multiplier-toggle-icon"
                      />
                      <div
                        class="basket-benefit-number-box basket-benefit-number-box--right text-30 font-bold text-texts-standard-default flexing"
                      >
                        {{ `${offer.content.bonuses.value}x` }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tier-box__footer flexing">
                  <app-button
                    v-if="!offer.purchased"
                    btn-type="primary"
                    :btn-text="$te('buyButton')"
                    class="tier-box__button"
                    @click="
                      $router.push({
                        name: $getWebView('PremiumEventPasses'),
                        query: { pass: PassType.Event, tier: offer.tier },
                      })
                    "
                  />
                  <div
                    v-if="offer.activated"
                    class="tier-box__activated-text flexing text-38 font-bold text-texts-standard-important"
                  >
                    {{ $te('activated') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tier-shop-popup-footer flexing">
            <app-button
              btn-id="tier-shop-popup-button"
              btn-type="secondary"
              :btn-text="$te('continue')"
              @click="collect"
            />
          </div>
        </div>
      </popup-window>
    </div>
    <div class="fortune-basket__main flex flex-auto items-center justify-between">
      <div class="fortune-basket__main-left">
        <div class="fortune-basket__main-leftbox flex flex-col">
          <div class="fortune-basket__main-leftbox-header flexing">
            <shrink-text
              :text="$te('timeToDailyReset')"
              :font-size="32"
              text-class-list="text-texts-standard-default"
              :animated-overflow="true"
            />
            <app-timer
              class="grid-resets-timer"
              :time="nextReset"
              @countdown-action="finishOperation"
            />
          </div>
          <div class="fortune-basket__main-leftbox-main flexing flex-col">
            <div class="leftbox-main-header flexing">
              <div class="leftbox-main-header-text">
                <shrink-text
                  :text="$te('dailyGunpowder')"
                  :font-size="30"
                  text-class-list="text-texts-standard-default uppercase"
                  container-class-list="flexing"
                />
              </div>
              <tippy theme="WSM1" placement="right" max-width="75rem">
                <template #content>
                  <div class="info-box-tooltip text-left p-6 gap-6 flex flex-col">
                    <p class="text-texts-standard-default text-30">
                      {{ $te('gunPowderInfo1') }}
                    </p>
                    <p class="text-texts-standard-default text-30">
                      {{
                        $replacePlaceholder($te('gunPowderInfo2'), '{2to4}', gunpowderDailyCount)
                      }}
                    </p>
                    <p class="text-texts-standard-default text-30">
                      {{ $te('gunPowderInfo3') }}
                    </p>
                  </div>
                </template>
                <app-icon class="leftbox-main-header-info" icon-name="info-50" />
              </tippy>
            </div>
            <div class="flexing gap-6">
              <div v-for="index in gunpowderDailyCount" :key="index" class="relative">
                <app-main-icon
                  :icon-size="56"
                  icon-name="gunpowder"
                  class="gunpowder-icon"
                  :class="{ 'opacity-50': index <= gunpowderDailyCount - gunpowderAvailableCount }"
                />
                <app-icon
                  v-if="index <= gunpowderDailyCount - gunpowderAvailableCount"
                  icon-name="done-md"
                  class="absolute box-icon box-icon--center"
                />
              </div>
            </div>
            <div class="leftbox-reward-multiplier-toggle flexing">
              <app-main-icon
                :icon-size="56"
                :icon-name="getMultiplierIcon === 2 ? 'double-reward' : 'triple-reward'"
                class="multiplier-toggle-icon"
              />
              <span class="multiplier-toggle-text text-30">{{
                $te(getMultiplierIcon === 2 ? 'doubleReward' : 'tripleReward')
              }}</span>
              <app-button
                btn-id="leftbox-reward-multiplier-toggle-btn"
                class="multiplier-toggle-btn"
                btn-type="primary"
                :btn-text="getMultiplierText"
                btn-size="sm"
                :disabled="!getMultiplierCount"
                @click="toggleMultiplierState"
              />
            </div>
            <app-multi-button
              btn-id="new-grid-refresh-btn"
              btn-type="primary"
              :btn-text="$te('newPatch')"
              :btn-count="1546"
              :disabled="!getShuffleCount"
              btn-size="md"
              :loading="false"
              @click="showShufflePopup = true"
            >
              <template #rightPart>
                <div class="flexing gap-2">
                  <span class="text-texts-standard-default">
                    <!-- TODO BE exaktna hodnota -->
                    {{ 1 }}
                  </span>
                  <app-main-icon :icon-size="56" icon-name="refresh" />
                </div>
              </template>
            </app-multi-button>
            <popup-window
              v-if="showShufflePopup"
              popup-type="info"
              :popup-title="$te('newPatch')"
              @close="showShufflePopup = false"
            >
              <div class="reset-grid-popup flexing flex-col">
                <p class="text-36 mt-6 text-texts-standard-default">{{ $te('newPatchConfirm') }}</p>
                <div>
                  <p class="text-36 mt-6 text-texts-standard-default">{{ $te('commonPrice') }}</p>
                  <div
                    class="number-supplement text-texts-standard-default text-44 font-medium gap-2 flexing"
                  >
                    <span :class="{ 'revert-skew': $isWsm }">
                      {{ 1 + 'x' }}
                    </span>
                    <app-main-icon :icon-size="56" icon-name="refresh" />
                  </div>
                </div>
                <app-button
                  btn-id="new-grid-refresh-btn"
                  btn-type="primary"
                  :btn-text="$te('confirm')"
                  btn-size="md"
                  @click="$debounce(handleShuffle)"
                />
              </div>
            </popup-window>
            <div class="leftbox-main-footer flexing gap-12">
              <div class="leftbox-main-footer__info-box flexing">
                <div class="menu-info-box-text mr-3 text-texts-standard-default text-36 font-bold">
                  <span>{{ $filters.$formatNumber(getMultiplierCount) }}</span>
                </div>
                <app-main-icon
                  :icon-size="56"
                  :icon-name="getMultiplierIcon === 2 ? 'double-reward' : 'triple-reward'"
                  class="multiplier-toggle-icon"
                />
              </div>
              <div class="leftbox-main-footer__info-box flexing">
                <div class="menu-info-box-text mr-3 text-texts-standard-default text-36 font-bold">
                  <span>{{ $filters.$formatNumber(getShuffleCount) }}</span>
                </div>
                <app-main-icon :icon-size="56" icon-name="refresh" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fortune-basket__main-middle flexing flex-col flex-auto gap-11">
        <div
          class="fortune-basket-middle-cta mt-12 flexing text-texts-standard-default text-30 font-bold"
        >
          <div v-html="getBonusText" />
        </div>
        <div
          ref="fortuneBasketRowWrapper"
          class="fortune-basket-row-wrapper flexing flex-col gap-6 flex-1"
        >
          <fortune-basket-row
            v-for="(chunk, index) in chunkedRewards"
            :key="index"
            :chunk-rewards="chunk"
          />
        </div>
      </div>
      <div class="fortune-basket__main-right flexing">
        <div class="fortune-basket__main-reward flex flex-col">
          <div
            class="fortune-basket__main-reward-header flexing text-texts-standard-default text-32 font-bold"
          >
            {{ $te('bestReward') }}
          </div>
          <div class="fortune-basket__main-reward-info flex flex-col justify-between">
            <div class="fortune-basket__main-reward-info-box flex justify-end py-3 px-3.5">
              <tippy theme="WSM1" placement="left" max-width="75rem">
                <template #content>
                  <div class="reward-info-box-tooltip flex flex-col">
                    <p
                      class="heading-ribbon flexing text-texts-standard-important text-36 font-bold uppercase"
                    >
                      {{ $te('targetRewards') }}
                    </p>
                    <div class="flex flex-wrap items-center justify-center gap-2">
                      <reward-box
                        v-for="(reward, index) in tooltipRewards"
                        :key="index"
                        :reward-icon="56"
                        :reward-icon-name="getRewardIcon(reward)"
                        :reward-number="reward.value"
                        :is-claimed="reward.claimed"
                      />
                    </div>
                    <p class="text-texts-standard-default mt-8 mb-6 text-30">
                      {{ $te('equalDropRate') }}
                    </p>
                  </div>
                </template>
                <app-icon icon-name="info-50" />
              </tippy>
            </div>
            <div class="flexing flex-col" :class="{ 'opacity-50': !isBestRewardFound }">
              <app-main-icon
                class="fortune-basket__main-reward-info-icon"
                :icon-name="getBestRewardIcon"
                :icon-size="184"
              />
              <span
                class="fortune-basket__main-reward-info-value text-texts-standard-default text-40 font-bold"
                :class="{ 'fortune-basket__main-reward-info-value--active': isBestRewardFound }"
              >
                {{ getBestRewardValue }}
              </span>
            </div>
            <div class="fortune-basket__main-reward-info-footer">
              <p class="text-texts-standard-default pt-6 text-32 leading-7">
                {{ $te(isBestRewardFound ? 'found' : 'notFound') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <component-loading v-else :is-loading="true" height="100%" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import InfoText from '@/components/InfoText.vue'
import ShrinkText from '@/components/ShrinkText.vue'
import FortuneBasketRow from '@/components/Events/CarnivalStandComponents/FortuneBasket/FortuneBasketRow.vue'
import { PassType } from '@/interfaces/events/Passes'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import { useFortuneBasketStore } from '@/store/pinia/events/fortuneBasketStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'

import type {
  Reward,
  SpecialItem,
} from '@/interfaces/responses/events/fortune-basket/FortuneBasketStateApiResponse'

interface ComponentData {
  loaded: boolean
  showTokenPopup: boolean
  showDailyPopup: boolean
  showShufflePopup: boolean
  PassType: typeof PassType
}

export default defineComponent({
  name: 'FortuneBasketMain',
  components: {
    InfoText,
    ShrinkText,
    FortuneBasketRow,
    PopupWindow,
  },
  emits: ['finishOperation'],
  data(): ComponentData {
    return {
      loaded: false,
      showTokenPopup: false,
      showDailyPopup: true, // must be true
      showShufflePopup: false,
      PassType,
    }
  },
  computed: {
    ...mapState(useFortuneBasketStore, {
      specialItems: 'getSpecialItems',
      bestReward: 'getBestReward',
      nextReset: 'getNextReset',
      rewards: 'getRewards',
      tooltipRewards: 'getTooltipRewards',
      multiplierState: 'getMultiplierState',
      tokensForGems: 'getTokensForGems',
      showDailyReset: 'getShowDailyReset',
      dailyEventPassBonuses: 'getDailyEventPassBonuses',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
    chunkedRewards(): Reward[][] {
      // separovatelnost pre 3 riadky
      const chunkSize = 5
      const rewardsLength = this.rewards?.length
      const numberOfChunks = Math.ceil(rewardsLength / chunkSize)
      const chunkedRewards = Array.from(
        { length: numberOfChunks },
        (_: undefined, index: number): Reward[] =>
          this.rewards?.slice(index * chunkSize, (index + 1) * chunkSize),
      )
      return chunkedRewards
    },
    getTokens(): SpecialItem {
      return this.specialItems?.find(
        (item: SpecialItem): boolean => item?.type === 'fortune_basket_token',
      )
    },
    getTokenCount(): number {
      return this.getTokens?.value ?? 0
    },
    getTokenIcon(): string {
      return this.getTokens?.icon ?? ''
    },
    getGunpowder(): SpecialItem {
      return this.specialItems?.find((item: SpecialItem): boolean => item?.type === 'gunpowder')
    },
    gunpowderDailyCount(): number {
      return this.getGunpowder?.limit_daily ?? 0
    },
    gunpowderAvailableCount(): number {
      // TODO BE preklep v response, no comment :/
      return this.getGunpowder?.avaible ?? 0
    },
    getMultiplier(): SpecialItem {
      return this.specialItems?.find(
        (item: SpecialItem): boolean => item?.type === 'fortune_basket_multiplier',
      )
    },
    getMultiplierCount(): number {
      return this.getMultiplier?.value ?? 0
    },
    getMultiplierIcon(): number {
      return this.getMultiplier?.multiple ?? 0
    },
    getMultiplierText(): string {
      return this.multiplierState === true ? this.$te('on') : this.$te('off')
    },
    getShuffle(): SpecialItem {
      return this.specialItems?.find(
        (item: SpecialItem): boolean => item?.type === 'fortune_basket_shuffle',
      )
    },
    getShuffleCount(): number {
      return this.getShuffle?.value ?? 0
    },
    getShuffleIcon(): string {
      return this.getShuffle?.icon ?? ''
    },
    getBestRewardIcon(): string {
      return this.bestReward?.type ?? ''
    },
    getBestRewardValue(): number {
      return this.bestReward?.value ?? 0
    },
    isBestRewardFound(): boolean {
      return this.bestReward?.claimed ?? false
    },
    getBonusText(): string {
      let bonusText = this.$te('getRewardsForTokens')
      bonusText = bonusText.replace(/{icon}/g, '<div class="ico-token"></div>')

      return bonusText
    },
  },
  watch: {
    getMultiplierCount(newCount: number): void {
      if (!newCount) {
        // Pokial nemame zdroje ale ostal zapnuty multiplikator vypneme ho
        if (this.multiplierState) this.toggleMultiplierState()
      }
    },
  },
  async created(): Promise<void> {
    await Promise.all([this.loadConfig(), this.loadState()])
    this.loaded = true
  },
  methods: {
    ...mapActions(useFortuneBasketStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
      toggleMultiplierState: 'toggleMultiplierState',
      shuffle: 'shuffle',
      buyTokens: 'buyTokens',
      collect: 'collect',
    }),
    async finishOperation(): Promise<void> {
      this.loaded = false
      await Promise.all([this.loadConfig(), this.loadState()])
      this.loaded = true
    },
    getRewardIcon(reward: Reward): string {
      if (reward.type === 'fortune_basket_multiplier') {
        return `fortune_basket_${reward.multiple === 2 ? 'double' : 'triple'}`
      }
      return reward.type
    },
    async handleTokenPurchase(index: number): Promise<void> {
      await this.buyTokens(index)
      this.showTokenPopup = false
    },
    async handleShuffle(): Promise<void> {
      await this.shuffle()
      this.showShufflePopup = false

      // Shuffle animation
      this.$refs.fortuneBasketRowWrapper.classList.add('fortune-basket-row-wrapper--animated')
      setTimeout((): void => {
        this.$refs.fortuneBasketRowWrapper.classList.remove('fortune-basket-row-wrapper--animated')
      }, 1600)
    },
  },
})
</script>

<style lang="scss" scoped>
.fortune-basket {
  position: relative;
  width: 98%;
  margin: 0 auto;

  &__header {
    position: relative;
    width: 100%;
    height: 5.813rem;
    margin: 0.25rem 0 0;
    padding-left: 2rem;
    background: linear-gradient(
      to right,
      rgba(38, 48, 77, 0),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 0)
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(
        to right,
        rgba(162, 209, 255, 0),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 0)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .menu-info-box {
      min-width: 16.875rem;
      height: 4.375rem;
      transform: $skewX-value;
      border-radius: 0.125rem;
      padding: 0.5rem;

      @if $isWsm {
        background-color: rgb(15, 50, 81);
        border: 0.125rem solid #f4d91e;
        box-shadow:
          inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
          inset -0.019625rem -0.1865rem 2.5rem 0 rgba(6, 34, 55, 0.48);
      }

      @if $isSsm {
        background-color: #363e5d;
        border-style: solid;
        border-width: 0.125rem;
        border-image-source: linear-gradient(to right, #f6c717, #fff284);
        border-image-slice: 1;
        box-shadow:
          inset -3px 0px 40px 0 rgba(6, 34, 55, 0.48),
          inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
      }
    }
  }

  &__add-button {
    width: 4.375rem;
    height: 4.375rem;
    min-width: 4.375rem;
  }

  &__main {
    &-leftbox {
      width: 31.188rem;
      height: 42.875rem;
      background: url($path-events + 'autumn-fair/fortune-basket/left-box-bg.avif') no-repeat center;
      background-size: contain;

      &-header {
        width: 100%;
        height: 5.5rem;
        border: solid 0.125rem #ff1717;
        background: rgb(195, 24, 24, 0.6);
        gap: 1.25rem;

        .text-container {
          flex: 1 1 auto;
          min-width: 0;
        }

        .timer-component {
          min-width: 14.5rem;

          &.icon-time {
            width: 2rem;
            height: 2rem;
            margin-right: 0;
          }
        }
      }

      &-main {
        width: 100%;
        flex: 1 1 auto;
        justify-content: space-between;

        .leftbox-main-header {
          position: relative;
          width: calc(100% - 2.25rem);
          height: 3.125rem;
          margin: 1.125rem auto 0.5rem;

          &-text {
            width: 100%;
            padding: 0 5rem;
          }

          &-info {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .gunpowder-icon {
          filter: drop-shadow(0 0 0.125rem #fff);
        }

        .leftbox-reward-multiplier-toggle {
          position: relative;
          margin: 2.25rem auto 3rem;
          width: 28.25rem;
          height: 9.625rem;
          background: url($path-events + 'autumn-fair/fortune-basket/left-box-toggle-bg.avif')
            no-repeat center;
          background-size: contain;

          .multiplier-toggle-icon {
            position: absolute;
            top: -1.5rem;
          }

          .multiplier-toggle-text {
            color: #fec742;
          }

          .multiplier-toggle-btn {
            position: absolute;
            width: 12.5rem;
            height: 4.375rem;
            bottom: -2.125rem;
          }
        }

        .leftbox-main-footer {
          width: 100%;
          height: 7.25rem;
          margin-top: 1.75rem;

          &__info-box {
            width: 11.25rem;
            height: 4rem;
            box-shadow:
              inset -3px 0px 40px 0 rgba(6, 34, 55, 0.48),
              inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
            border: solid 2px #4c648f;
            background-color: #363e5d;
          }
        }
      }
    }

    &-middle {
      .fortune-basket-middle-cta {
        position: relative;
        width: 32.375rem;
        height: 3.25rem;
        background-image: linear-gradient(
          to right,
          rgba(14, 116, 105, 0),
          rgba(14, 116, 105, 1),
          rgba(14, 116, 105, 1),
          rgba(14, 116, 105, 0)
        );

        &::before,
        &::after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 0.125rem;
          background-image: linear-gradient(
            to right,
            rgba(14, 231, 204, 0.01),
            rgba(14, 231, 204, 1),
            rgba(14, 231, 204, 0.01)
          );
        }

        &::before {
          top: 0;
        }

        &::after {
          bottom: 0;
        }

        &:deep(.ico-token) {
          position: relative;
          display: inline-block;
          width: 2rem;
          height: 1.813rem;
          top: 0.175rem;
          left: -0.5rem;
          margin-left: 0.5rem;
          background: url($path-events + 'autumn-fair/icons/ico-token-120.avif') center no-repeat;
          background-size: contain;
        }
      }
    }

    &-reward {
      width: 22.438rem;
      height: 36.75rem;
      background: url($path-events + 'autumn-fair/fortune-basket/box-reward-bg.avif') no-repeat
        center;
      background-size: contain;

      &-header {
        position: relative;
        height: 5rem;
      }

      &-info {
        flex: 1 1 auto;

        &-icon {
          // .icon-energy_drink-184:not(.icon-header):not(.no-margin)
          margin: 0 auto !important;
        }

        &-value {
          &--active {
            text-shadow: 0 0 1rem rgb(255 255 255 / 70%);
          }
        }

        &-footer {
          margin-bottom: 4rem;
        }
      }
    }
  }
}

.reward-info-box-tooltip {
  padding: 2rem;

  .heading-ribbon {
    position: relative;
    width: 62.5rem;
    height: 4.625rem;
    margin: 0 auto 1.5rem;
    padding-left: 2rem;
    background: linear-gradient(
      to right,
      rgba(38, 48, 77, 0),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 0)
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(
        to right,
        rgba(162, 209, 255, 0),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 0)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  .reward-box {
    width: 5.625rem;
    height: 6.875rem;
    margin: 0;
  }
}

.box-icon {
  &--center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.token-shop {
  &-popup {
    width: 90.625rem;
    height: 44.75rem;
    background: url($path-events + 'autumn-fair/fortune-basket/token-shop-popup-bg.avif') no-repeat
      center;
    background-size: contain;
  }

  &-cta {
    position: relative;
    width: 100%;
    height: 5.625rem;
    margin: 3.125rem auto;
    padding-left: 2rem;
    background: linear-gradient(
      to right,
      rgba(38, 48, 77, 0),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 1),
      rgba(38, 48, 77, 0)
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(
        to right,
        rgba(162, 209, 255, 0),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 1),
        rgba(162, 209, 255, 0)
      );
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &-box {
    width: 24.25rem;
    height: 30.5rem;
    background: url($path-events + 'autumn-fair/fortune-basket/token-shop-box-bg.avif') no-repeat
      center;
    background-size: contain;
    padding: 1.75rem 2rem 2.25rem;

    &-wrapper {
      gap: 2.875rem;
    }

    &-header {
      width: 100%;
      height: 12.875rem;
      margin-bottom: 1.625rem;
      background: url($path-events + 'autumn-fair/fortune-basket/tokens-img.avif') no-repeat center;
      background-size: 16.625rem 9.938rem;
    }

    &-value {
      width: 100%;
      height: 4rem;
      margin: 1rem auto 1.75rem;
      background: linear-gradient(
        to right,
        rgba(44, 13, 3, 0),
        rgba(44, 13, 3, 0.4),
        rgba(44, 13, 3, 0.8),
        rgba(44, 13, 3, 0.8),
        rgba(44, 13, 3, 0.4),
        rgba(44, 13, 3, 0)
      );
      color: #fec742;
    }

    &-button {
      width: calc(100% - 1.25rem);
      margin: 0 auto;
    }
  }
}

.tier-shop {
  &-popup {
    width: 90.625rem;
    min-height: 44.75rem;
    background: url($path-events + 'autumn-fair/fortune-basket/new-basket-screen-bg.avif') no-repeat
      center;
    background-size: contain;

    &-content {
      padding: 0.75rem 2rem 2.25rem;
      flex: 1 1 auto;
    }

    &-footer {
      background: url($path-events + 'autumn-fair/teaser/teaser-footer-bg.avif') no-repeat center;
      background-size: cover;
      width: 100%;
      height: 6.938rem;
    }

    &-header {
      position: relative;
      width: 100%;
      height: 5.813rem;
      padding-left: 2rem;
      background: linear-gradient(
        to right,
        rgba(38, 48, 77, 0),
        rgba(38, 48, 77, 0.8),
        rgba(38, 48, 77, 0.8),
        rgba(38, 48, 77, 0)
      );

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background: linear-gradient(
          to right,
          rgba(162, 209, 255, 0),
          rgba(162, 209, 255, 1),
          rgba(162, 209, 255, 1),
          rgba(162, 209, 255, 0)
        );
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &-subtext {
      position: relative;
      width: 83.125rem;
      height: 5rem;
      background-image: linear-gradient(
        to right,
        rgba(14, 116, 105, 0),
        rgba(173, 111, 5, 0.8) 20%,
        rgba(158, 100, 1, 1) 45%,
        rgba(158, 100, 1, 1) 55%,
        rgba(173, 111, 5, 0.8) 80%,
        rgba(14, 116, 105, 0)
      );

      span {
        background-image: linear-gradient(to top, #fcd994 30%, #f1f4ff 60%);
        background-clip: text;
        text-shadow: none;
        color: transparent;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-image: linear-gradient(
          to right,
          rgba(255, 238, 120, 0.01),
          rgba(255, 238, 120, 1),
          rgba(255, 238, 120, 0.01)
        );
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &-boxes {
      margin-top: 1.25rem;
      gap: 3.375rem;

      .tier-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 23.563rem;
        height: 23.563rem;
        padding: 2.125rem 2.4rem 2.375rem;

        &__activated-text {
          position: relative;
          width: 100%;
          height: 4rem;
          background-image: linear-gradient(
            to right,
            rgba(14, 116, 105, 0),
            rgba(14, 116, 105, 1),
            rgba(14, 116, 105, 1),
            rgba(14, 116, 105, 0)
          );

          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 0.125rem;
            background-image: linear-gradient(
              to right,
              rgba(14, 231, 204, 0.01),
              rgba(14, 231, 204, 1),
              rgba(14, 231, 204, 0.01)
            );
          }

          &::before {
            top: 0;
          }

          &::after {
            bottom: 0;
          }
        }

        &__offer {
          @for $i from 1 through 3 {
            &-#{$i} {
              background: url($path-events + 'autumn-fair/fortune-basket/basket-tier-box-#{$i}.avif')
                no-repeat
                center;
              background-size: contain;
            }
          }
        }

        &--activated {
          background: url($path-events + 'autumn-fair/fortune-basket/basket-tier-box--active.avif')
            no-repeat center;
          background-size: contain;
        }

        &__header {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 12.25rem;

          .tier-box-header-text {
            width: 100%;
            height: 3.625rem;
          }

          .tier-box-header-reward {
            flex: 1 1 auto;

            .basket-benefit-number-box {
              position: absolute;

              background: url($path-events + 'autumn-fair/fortune-basket/basket-benefit-number-box-bg.avif')
                no-repeat center;
              background-size: contain;
              width: 3.5rem;
              height: 3.5rem;

              &--left {
                bottom: -0.5rem;
                left: -0.5rem;
              }

              &--right {
                bottom: -0.5rem;
                right: -0.5rem;
              }
            }
          }
        }

        &__footer {
          width: 100%;
          height: 5.25rem;
          padding: 0.5rem;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

.reset-grid-popup {
  width: 66.25rem;
  padding: 0.875rem 1.875rem;
  gap: 4.375rem;

  .number-supplement {
    width: 20rem;
    height: 5.25rem;
    transform: $skewX-value;

    @if $isWsm {
      background-color: rgb(37, 89, 126);
      box-shadow:
        inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
        inset -0.019625rem -0.1865rem 0.25rem 0 rgba(125, 190, 239, 0.48);
      text-shadow: 0.0401875rem 0.047875rem 0.058125rem rgba(0, 0, 0, 0.71);
    }

    @if $isSsm {
      background-image: linear-gradient(to right, transparent, #636790, #636790, transparent);
      text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);
    }
  }

  .btn {
    width: 100%;
  }
}

:deep() {
  .fortune-basket-row-wrapper {
    position: relative;
    width: 100%;
    // For web/mobile consistent absolute positioning animation
    min-height: 32.875rem;

    &--animated {
      .fortune-basket-row {
        position: absolute;
        height: 5rem;
        // Half of the row height to maintain position when using absolute positioning for animation
        top: calc(50% + 2.5rem);
        left: 50%;
        transform: translate(-50%, -50%);
        animation: shrinkToCenter 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:nth-of-type(1) {
          margin-top: -10rem;
        }

        &:nth-of-type(3) {
          margin-top: 10rem;
        }

        .fortune-basket-row__targets {
          position: absolute;
          width: 100%;
          min-width: 0;

          .target-item {
            position: absolute;
            animation: shrinkToCenter 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:nth-child(1) {
              margin-left: -50rem;
            }

            &:nth-child(2) {
              margin-left: -25rem;
            }

            &:nth-child(3) {
              margin: 0;
            }

            &:nth-child(4) {
              margin-right: -25rem;
            }

            &:nth-child(5) {
              margin-right: -50rem;
            }
          }
        }
      }
    }
  }
}

@keyframes shrinkToCenter {
  0% {
  }
  50% {
    margin: 0;
  }
  100% {
  }
}
</style>
