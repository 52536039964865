<template>
  <section class="rankings-view w-full flex flex-col flex-grow safe-area">
    <main v-if="!isLoading" class="flex flex-col flex-grow">
      <div
        class="rankings-main flex flex-row flex-grow justify-center items-center px-8 py-4 space-x-8"
      >
        <div
          v-if="!formattedRankings?.length"
          class="w-full h-full flex flex-col items-center justify-start"
        >
          <rankings-header :periods="periods" :default-period="period" @set-period="setPeriod" />
          <div class="w-full flex flex-col items-center justify-center flex-grow">
            <p class="text-36 text-white">{{ $t('tournaments.rankingUnavailable') }}</p>
          </div>
        </div>
        <template v-else>
          <div
            class="ranking-box-position-wrapper left-side"
            :class="{
              'ranking-box-position-wrapper--special-discipline-2': isTournamentDiscipline2,
            }"
          >
            <ranking-box-position
              v-if="formattedRankings.length"
              :ranking-position="formattedRankings?.slice(0, 3)"
              :ranking-type="rankingType"
            />
          </div>
          <div class="right-side flex-grow">
            <rankings-header
              :periods="periods"
              :default-period="period"
              :is-special-tournament="isTournamentDiscipline2 && false"
              @set-period="setPeriod"
            />
            <ranking-table-head :ranking-type="rankingType" :hidden-columns="hiddenColumns" />
            <app-scrollbar
              class="rankings-scrollbar"
              width="100%"
              height="35.8rem"
              scroll="y"
              slide="y"
            >
              <ranking-row-table
                :rows="formattedRankings"
                :table="rankingType"
                :hidden-columns="hiddenColumns"
                width="100%"
              />
            </app-scrollbar>
            <ranking-player
              v-if="rankings?.player"
              class="flex-shrink-0"
              :player-data="rankings.player"
              :ranking-type="rankingType"
              :hidden-columns="hiddenColumns"
            />
          </div>
        </template>
      </div>
    </main>
    <component-loading v-else :is-loading="true" height="100%" />
  </section>
</template>

<script lang="ts">
import { MECHANIC_TOURNAMENTS_DISCIPLINE_2, pathImages } from '@/globalVariables'
import RankingsHeader from '@/components/Tournaments/Rankings/RankingsHeader.vue'
import RankingBoxPosition from '@/components/Rankings/RankingBoxPosition.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import { useTournamentsRankingsStore } from '@/store/pinia/tournaments/useTournamentsRankingsStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { RankingType, type RankingUser } from '@/interfaces/RankingsInterfaces'
import { SeasonType } from '@/interfaces/Seasons'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'

export interface SelectOption {
  value: string
  name: string
}

interface ComponentData {
  pathImages: typeof pathImages
  RankingType: typeof RankingType
  period: string
  isLoading: boolean
}

export default defineComponent({
  components: {
    RankingsHeader,
    RankingBoxPosition,
    RankingTableHead,
    RankingRowTable,
    RankingPlayer,
  },
  data(): ComponentData {
    return {
      pathImages,
      RankingType,
      period: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useTournamentsRankingsStore, ['rankings']),
    ...mapState(useResponseTaskStore, ['hasMechanic']),
    ...mapState(useMainSeasonStore, {
      seasonStats: 'getSeasonStats',
    }),
    rankingType(): RankingType {
      switch (this.$route.name) {
        default:
        case this.$getWebView('TournamentsRankingsPoints'):
          return RankingType.TournamentPoints
        case this.$getWebView('TournamentsRankingsTitles'):
          return RankingType.TournamentTitles
        case this.$getWebView('TournamentsRankingsDiscipline2'):
          return RankingType.TournamentPointsDiscipline2
      }
    },
    type(): string {
      if (this.rankingType === RankingType.TournamentPointsDiscipline2) return 'tournament_biathlon'
      return this.rankingType.replace(/^tournaments?/, '').toLowerCase()
    },
    currentSeason(): string {
      return `${this.seasonStats.seasonMonth}/${this.seasonStats.seasonYear}`
    },
    previousSeason(): string {
      let year = +this.seasonStats.seasonYear
      let month = +this.seasonStats.seasonMonth

      if (month === 1) {
        month = 12
        year--
      } else {
        month--
      }

      return `${month.toString().padStart(2, '0')}/${year}`
    },
    periods(): SelectOption[] {
      return [
        !this.isTournamentDiscipline2 && {
          value: 'today',
          name: this.$t('tournaments.today'),
        },
        !this.isTournamentDiscipline2 && {
          value: 'yesterday',
          name: this.$t('tournaments.yesterday'),
        },
        {
          value: this.currentSeason,
          name: this.$t('tournaments.currentSeason'),
        },
        {
          value: this.previousSeason,
          name: this.$t('tournaments.previousSeason'),
        },
        !this.isTournamentDiscipline2 && {
          value: 'all',
          name: this.$t('tournaments.overall'),
        },
      ].filter(Boolean)
    },
    formattedRankings(): RankingUser[] {
      if (!this.rankings) return []

      const keys = Object.keys(this.rankings)
      if (!keys.length) return []

      return keys.reduce((array: RankingUser[], userId: string): RankingUser[] => {
        if (userId === 'player') return array

        array.push({
          id: userId,
          name: this.rankings[userId].username,
          points: this.rankings[userId].RANKING_POINTS,
          level: this.rankings[userId].LEVEL,
          clubId: this.rankings[userId].club_id,
          clubLogoId: this.rankings[userId].club_logo_id,
          clubLogoBgId: this.rankings[userId].club_background_logo_id,
          eventBadges: this.rankings[userId].event_badges ?? null,
          ...this.rankings[userId],
        })

        return array
      }, [])
    },
    hiddenColumns(): string[] {
      return this.isTournamentDiscipline2 ? ['club'] : []
    },
    isTournamentRouteDiscipline2(): boolean {
      return this.$route.name === this.$getWebView('TournamentsRankingsDiscipline2')
    },
    isTournamentDiscipline2(): boolean {
      return (
        this.$isWsm &&
        this.hasMechanic(MECHANIC_TOURNAMENTS_DISCIPLINE_2) &&
        this.isTournamentRouteDiscipline2
      )
    },
    watcher(): string {
      return [this.rankingType, this.period].join('|')
    },
  },
  watch: {
    $route(): void {
      this.setDefaultPeriod()
    },
    async watcher(): Promise<void> {
      this.isLoading = true
      await this.loadState(
        {
          type: this.type,
          period: this.period,
        },
        true,
      )
      this.isLoading = false
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await Promise.all([
      this.loadState({ type: this.type, period: this.period }, true),
      this.loadSeasonStats(SeasonType.Current),
    ])
    this.isLoading = false

    this.setDefaultPeriod()
  },
  methods: {
    ...mapActions(useTournamentsRankingsStore, ['loadState']),
    ...mapActions(useMainSeasonStore, ['loadSeasonStats']),
    setDefaultPeriod(): void {
      this.period = this.periods.some(
        ({ value }: SelectOption): boolean => value === this.$route.query?.period,
      )
        ? (this.$route.query?.period as string)
        : this.currentSeason
    },
    setPeriod(value: string): void {
      this.period = value
    },
  },
})
</script>

<style lang="scss" scoped>
.rankings-view {
  .rankings-main {
    .ranking-box-position-wrapper.left-side {
      width: 33.125rem;

      :deep(.position-box) {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-top: 2rem;
        }

        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            background-image: url('#{$path-images}tournaments/rankings/box-#{$i}-bg.avif') !important;

            .position-box-badge {
              background-image: url('#{$path-images}tournaments/rankings/number-#{$i}.avif') !important;
            }

            .position-box-cup {
              width: 10.188rem;
              height: 13.688rem;
              background-image: url('#{$path-images}tournaments/rankings/cup-#{$i}.avif') !important;
              background-position: center;
              background-size: contain;
            }
          }
        }
      }

      &.ranking-box-position-wrapper--special-discipline {
        &-2 {
          :deep() {
            .position-box {
              @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                  .position-box-cup {
                    background-image: url('#{$path-images}tournaments/rankings/special/discipline-2/cup-#{$i}.avif') !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .right-side {
      :deep(.player-position-position.is-rank-1) {
        @if $isWsm {
          background-image: linear-gradient(to top, #ffef84, #f6c717);
        }
        @if $isSsm {
          background-image: linear-gradient(to top, #daab36, #ffdf91);
        }
      }

      :deep(.player-position-position.is-rank-2) {
        @if $isWsm {
          background-image: linear-gradient(to top, #a4a4a4, #fff);
        }
        @if $isSsm {
          background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
        }
      }

      :deep(.player-position-position.is-rank-3) {
        @if $isWsm {
          background-image: linear-gradient(to top, #e77447, #ffb99e);
        }
        @if $isSsm {
          background-image: linear-gradient(to top, #f58d57, #ffb28a);
        }
      }
    }
  }
}
</style>
